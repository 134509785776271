import { cast } from "mobx-state-tree";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { IProjectBaseModel, MinisiteConfigModel } from "./projectModel";
import { updateProject } from "../../graphql/mutations";
import { PlayerIntegrationData } from "../../view/IntegrationPage/SiteIntegration/DataIntegration/LandingPageIntegration/LandingPageIntegration";
import {
  NEW_REST_API,
  NEW_REST_API__V2__PROJECT_ALIAS_AVAILABLE,
} from "../../consts";
import { generateClient, get } from "aws-amplify/api";

const client = generateClient();

let clearStateTimeout: NodeJS.Timeout;
export const saveMiniSiteConfigActions = (self: IProjectBaseModel) => ({
  async saveMiniSiteConfig(config: PlayerIntegrationData) {
    clearTimeout(clearStateTimeout);
    this.changeSaveMiniSiteConfigStatus(AsyncOpState.Saving);
    try {
      await get({
        apiName: NEW_REST_API,
        path: NEW_REST_API__V2__PROJECT_ALIAS_AVAILABLE(self.id, config.alias),
      }).response;
    } catch (e) {
      this.changeSaveMiniSiteConfigStatus(AsyncOpState.Error);
      clearStateTimeout = setTimeout(() => {
        this.changeSaveMiniSiteConfigStatus(AsyncOpState.Changed);
      }, 3000);
      throw new Error("URL alias is not available");
    }

    const { title, description, thumbUrl, alias, scenes, preCreate } = config;
    try {
      await client.graphql({
        query: updateProject,
        variables: {
          input: {
            id: self.id,
            minisiteConfigs: [
              {
                scenes,
                description,
                thumbUrl,
                title,
                preCreate,
              },
            ],
            aliasId: alias,
          },
        },
      });
      this.changeSaveMiniSiteConfigStatus(AsyncOpState.Changed);

      this.replaceMiniSiteConfig(config);
    } catch (e) {
      this.changeSaveMiniSiteConfigStatus(AsyncOpState.Error);
      clearStateTimeout = setTimeout(() => {
        this.changeSaveMiniSiteConfigStatus(AsyncOpState.Changed);
      }, 3000);
    }
  },
  replaceMiniSiteConfig(config: PlayerIntegrationData) {
    self.minisiteConfig = cast(config);
    self.aliasId = config.alias;
  },
  changeSaveMiniSiteConfigStatus(newStatus: AsyncOpState) {
    self.saveMiniSiteConfigStatus = newStatus;
  },
});
