import { Select, Input, Popover, Space } from "antd";
import * as React from "react";
import { DynamicForm } from "@blings/dynamic-form";
import csv from "csvtojson";
import { downloadData } from "aws-amplify/storage";
import _ from "lodash";
import { IProjectModel } from "../../stores/project/projectModel";
import { IuploadDataModel } from "../../stores/uploadData";
import {
  filterObjectBySchema,
  jsonSchemaGetExamples,
} from "../../helpers/jsonSchema.helpers";
import { MSTContext } from "../../stores/Root";
import { DemoSdkPlayer } from "./DemoSDKPlayer";
import LoadingSpinner from "../../assets/Ellipse 7.png";
import "./DemoPage.scss";
import { cleanEmptyStringInValue } from "../../helpers/object.helpers";
import useDebounce from "../../helpers/DebounceHook";
import { BlingsBtn } from "../../components/antd-extensions/blings-btn.component";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { observer } from "mobx-react-lite";
import { OpenLinkIcon } from "../../assets/Icons";
import { useNavigate, useParams } from "react-router-dom";
import { PATHS, PROJECT_PATHS, toPath } from "../../PATHS";
import BlingsPlatformTheme from "../../config/antdTheme";

type Props = {
  project: IProjectModel;
  uploadData: IuploadDataModel;
  isLoading: boolean;
};
const { useState, useEffect, useRef } = React;

const { Option, OptGroup } = Select;

// https://stackoverflow.com/questions/19098797/fastest-way-to-flatten-un-flatten-nested-json-objects
function flattenKeys(data: any) {
  const result = {} as any;
  function recurse(cur: any, prop: any) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      const l = cur.length;
      for (let i = 0; i < l; i++) recurse(cur[i], prop + "[" + i + "]");
      if (l === 0) result[prop] = [];
    } else {
      let isEmpty = true;
      for (const p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + "." + p : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, "");
  return Object.keys(result);
}

const DemoPage = observer(({ project, isLoading }: Props) => {
  const [sourceRecordsFromCsv, setSourceRecordsFromCsv] =
    useState<any>(undefined);
  const [lastChange, setLastChange] = useState<any>(null);
  const debouncedValue = useDebounce(lastChange, 1400); // Get the debounced value

  const [sourceRecordsFromManualDp, setSourceRecordsFromManualDp] =
    useState<any>(undefined);
  const [selectedDataID, setSelectedDataId] = useState<string | undefined>(
    undefined
  );
  const [dpInput, setDpInput] = useState<string>("");
  const dataExamples = jsonSchemaGetExamples(
    JSON.parse(project.stateJsonSchemaStr || "{}")
  );
  const [data, setData] = useState<any>(
    dataExamples ? cleanEmptyStringInValue(dataExamples) : undefined
  );
  const [savedData, setSavedData] = useState<any>(undefined);
  const [isLoadingDp, setIsLoadingDp] = useState(true);

  const [recordChanged, setChangedFromRecordData] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const fetchSourceRecord = async (fileName: string) => {
      const res = await downloadData(
        {
          key: fileName,
        }
        //fileName, { download: true })
      ).result;
      const text = await res.body.text();
      csv()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        .fromString(text)
        .then((lines) => {
          const srs = {} as any;
          lines.forEach((line) => {
            const { id, ...rest } = line;
            srs[id] = rest;
          });
          setSourceRecordsFromCsv(srs);
        });
    };
    const fetchDpRecords = async (currentId: string) => {
      try {
        await project.loadExistingDPs();
        if (project.id === currentId && project.createdDPList.length) {
          const srs = {} as any;
          project.createdDPList.forEach((dp) => {
            srs[dp.dataId] = dp.record;
          });
          setSourceRecordsFromManualDp(srs);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const getData = async (fileName: string | null) => {
      setIsLoadingDp(true);
      if (fileName) {
        await fetchSourceRecord(fileName);
      }
      const currentId = project.id;
      await fetchDpRecords(currentId);
      setIsLoadingDp(false);
    };
    getData(project.fileName);
  }, [project, project.fileName, project.createNewDPStatus]);

  useEffect(() => {
    submit.current();
  }, [debouncedValue]);

  const submit = useRef<() => void>(() => {
    return;
  });
  const schema = JSON.parse(project.stateJsonSchemaStr || "{}");

  const fillDataFromRecord = (dataId: string) => {
    const record =
      sourceRecordsFromManualDp && sourceRecordsFromManualDp[dataId]
        ? sourceRecordsFromManualDp[dataId]
        : sourceRecordsFromCsv[dataId];
    const paths = flattenKeys(record);
    paths.forEach((path) => {
      const schemePath = path.split(".").join(".properties.");
      const type = _.get(schema.properties, `${schemePath}.type`);
      if (!type) return;
      const strValue = _.get(record, path);
      let value = strValue;
      switch (type) {
        case "integer":
        case "number":
          value = Number(strValue);
          break;
        case "boolean":
          value = strValue === true || strValue === "true";
          break;
        default:
          break;
      }
      _.set(record, path, value);
    });
    setLastChange(cleanEmptyStringInValue(record));
    setData(cleanEmptyStringInValue(record));
    setSavedData(cleanEmptyStringInValue(record));
    setChangedFromRecordData(false);
  };
  const {
    liveControlStore: { getAssetsUploadStatus, uploadAssetToProject },
  } = React.useContext(MSTContext);
  const showLeftSide = schema && !!Object.keys(schema?.properties || {}).length;
  const showDemoSdk =
    project.videoPartNames && project.videoPartNames.length !== 0;

  const renderButton = () => {
    const buttonElement = (
      <BlingsBtn
        opState={project.createNewDPStatus}
        className="dp-submit"
        htmlType={"submit"}
        btnTexts={{
          [AsyncOpState.Error]: "Error Creating Data",
          [AsyncOpState.Saving]: "Creating",
          [AsyncOpState.Untouched]: "Create",
          [AsyncOpState.Changed]:
            Object.keys(sourceRecordsFromCsv || {}).includes(dpInput) ||
              Object.keys(sourceRecordsFromManualDp || {}).includes(dpInput)
              ? "Update"
              : "Create",
          [AsyncOpState.Success]: "DONE",
        }}
        disabled={
          !dpInput ||
          (_.isEqual(data, savedData) &&
            (Object.keys(sourceRecordsFromCsv || {}).includes(dpInput) ||
              Object.keys(sourceRecordsFromManualDp || {}).includes(
                dpInput
              ))) ||
          Object.keys(sourceRecordsFromCsv || {}).includes(dpInput)
        }
        onClick={async () => {
          try {
            const final_data = { ...data };
            const filteredFormData = filterObjectBySchema(final_data, schema);
            await project.createDataPoint(filteredFormData, dpInput);
            setSelectedDataId(dpInput);
          } catch (e: any) {
            console.error("err", e);
          }
        }}
      />
    );

    if (Object.keys(sourceRecordsFromCsv || {}).includes(dpInput)) {
      return (
        <Popover
          className="tooltip-1"
          placement="rightTop"
          overlayInnerStyle={{ width: "14rem" }}
          content={
            <div style={{ width: "10rem" }}>
              This data point is not editable because it was uploaded from a CSV
              file. To modify it, please update the original CSV and re-upload.
            </div>
          }
        >
          <div>{buttonElement}</div>
        </Popover>
      );
    }
    return buttonElement;
  };
  if (!showDemoSdk || isLoading) {
    return (
      <div className={"DemoPage"}>
        <div className="text">
          Create a video using Blings extension,
          <br />
          and see a demo here
        </div>
      </div>
    );
  }
  return (
    <div className={"DemoPage project-tab-padding"}>
      {showLeftSide && (
        <div className={"left"}>
          {(project.allowCreateDataPoint || project.allowDataConnect) && (
            <div style={{ height: "32px", margin: "1rem 0" }}>
              {
                // Add the spin animation to the data points while it's loading
                !isLoadingDp ? (
                  <div>
                    <span>Apply data from source:</span>
                    <Select
                      showSearch
                      value={selectedDataID}
                      style={{ marginLeft: "10px", width: "200px" }}
                      onChange={(dataId: string) => {
                        setSelectedDataId(dataId);
                        fillDataFromRecord(dataId);
                        setDpInput(dataId);
                      }}
                    >
                      {sourceRecordsFromManualDp ? (
                        <OptGroup label="Data points">
                          {Object.keys(sourceRecordsFromManualDp).map((k) => (
                            <Option value={k} key={k}>
                              {k}
                            </Option>
                          ))}
                        </OptGroup>
                      ) : null}
                      {sourceRecordsFromCsv ? (
                        <OptGroup label="CSV records">
                          {Object.keys(sourceRecordsFromCsv).map((k) => (
                            <Option value={k} key={k}>
                              {k}
                            </Option>
                          ))}
                        </OptGroup>
                      ) : null}
                    </Select>
                  </div>
                ) : (
                  <div className="spinner" style={{ marginTop: "0" }}>
                    <img
                      src={LoadingSpinner}
                      className="Rotate"
                      alt="Loading Icon"
                    />
                  </div>
                )
              }
            </div>
          )}
          <div className="schema-view">
            <DynamicForm
              themeConfig={BlingsPlatformTheme}
              schema={schema}
              formData={data}
              editable={false}
              readable={false}
              onChange={(formData: any) => {
                setLastChange(cleanEmptyStringInValue(formData));
              }}
              saveData={(d) => {
                setData(cleanEmptyStringInValue(d));
                setChangedFromRecordData(true);
              }}
              submit={submit}
              uploadAssetToProject={uploadAssetToProject as any} // TODO: FIX THIS
              getAssetsUploadStatus={getAssetsUploadStatus}
            />
          </div>
          <div
            className="edit-schema-external"
            onClick={() => {
              navigate(
                toPath(
                  PATHS.project,
                  params?.id || "",
                  PROJECT_PATHS.settings,
                  "dataSchema"
                )
              );
            }}
          >
            Edit data schema structure <OpenLinkIcon width={25} />
          </div>
          <div className="dp-view">
            <Space.Compact>
              <div className="dp-field">
                <div className="pre-input">Data Point</div>
                <Input
                  value={dpInput}
                  onChange={(e) => setDpInput(e.target.value)}
                  placeholder="Create or Update Data Point"
                  className="dp-input"
                />
                {renderButton()}
              </div>
            </Space.Compact>
          </div>
        </div>
      )}
      <div className={"right"}>
        {showDemoSdk && (
          <DemoSdkPlayer
            selectedDataID={selectedDataID}
            project={project}
            data={data}
            recordChanged={recordChanged}
            frameIndicator={true}
            renderMp4={true}
          />
        )}
      </div>
    </div>
  );
});

export default DemoPage;
