import { getRealENV } from "../../../../../config";
import { JSONSchema6 } from "json-schema";
import { flattenSchema } from "../../../../../helpers/jsonSchema.helpers";
import { BlingsContainer } from "../../../../../components/BlingsContainer/BlingsContainer";
import "./IntegrationLink.scss";
import { Button } from "antd";
type Tags = {
  openingTag: string;
  closingTag: string;
};
type Props = {
  projectAliasId: string;
  projectAccountDomain: string;
  accountAliasId: string;
  schema?: JSONSchema6;
  tags?: Tags;
};

const env = getRealENV();
export const IntegrationLink = (props: Props) => {
  const { projectAliasId, accountAliasId, schema, tags, projectAccountDomain } =
    props;

  const params = schema
    ? Object.keys(flattenSchema(schema))
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${
              tags?.openingTag || "{{"
            }${encodeURIComponent(key.toUpperCase())}${
              tags?.closingTag || "}}"
            }`
        )
        .join("&")
    : "blingsId={{CSV_ID}}";
  const paramsMap = schema ? flattenSchema(schema) : { blingsId: "CSV_ID" };
  const paramsHtml = Object.keys(paramsMap).map((key, i) => {
    return (
      <span key={key}>
        {i !== 0 ? "&" : ""}
        {encodeURIComponent(key)}=
        <span className="token">{`${
          tags?.openingTag || "{{"
        }${encodeURIComponent(key.toUpperCase())}${
          tags?.closingTag || "}}"
        }`}</span>
      </span>
    );
  });
  const link = `https://${accountAliasId}.${projectAccountDomain}/${
    env === "master" ? "" : `${env}/`
  }${projectAliasId}?${params}`;
  const linkHtml = (
    <span>
      {`https://${accountAliasId}.${projectAccountDomain}/${
        env === "master" ? "" : `${env}/`
      }${projectAliasId}?`}
      {paramsHtml}
    </span>
  );
  return (
    <BlingsContainer className="IntegrationLink">
      <div>
        Copy the integration link below and replace the tokens as needed
      </div>
      <div>
        <div className="link-container">
          <div className="link">{linkHtml}</div>
        </div>
        <div className="copy-extra-information">
          <div>
            <span style={{ fontWeight: "700" }}>Important: </span>Save your
            landing page settings before copying this code
          </div>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(link);
            }}
          >
            Copy
          </Button>
        </div>
      </div>
    </BlingsContainer>
  );
};
