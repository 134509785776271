export function getAssetLayerPairs(jsonFile: any) {
  // Goes through all the assets in the scene.
  // If an asset contains a scene (ie. some layers), then append an array [name, layerNames[]]
  const assetPairs = jsonFile.assets
    .map((a: any) =>
      a.layers
        ? [a.nm || a.id, a.layers.map((l: any) => ({ uid: l?.uid, nm: l.nm }))]
        : []
    )
    .filter((x: any) => x.length);
  const mainPairs = jsonFile.layers.map((l: any) => ({
    uid: l?.uid,
    nm: l.nm,
  }));
  const assetLayerPairs: any = {};
  for (const p of assetPairs) {
    assetLayerPairs[p[0]] = p[1];
  }
  return [assetLayerPairs, mainPairs];
}
