import MuterSingleton from "../utils/mute";
import { useState } from "react";
import "./GlobalMuteButton.scss";
import { VolumeUp, Mute } from "../icons-and-animations/icons";
import { Tooltip } from 'antd';

export const GlobalMuteButton = () => {
  const [muteState, setMuteState] = useState(
    MuterSingleton.instance.isMuting()
  );

  return (
    <div className="mute-container">
      <div className="mute-wrapper">
        <Tooltip placement="bottom" title={muteState ? "Global Unmute" : "Global Mute"} >
          <span className="btn-wrapper"
            onClick={() => {
              MuterSingleton.instance.toggleMute();
              setMuteState(MuterSingleton.instance.isMuting());
            }}>
            {muteState ? <Mute /> : <VolumeUp />}
          </span>
        </Tooltip>
      </div>
    </div>
  );
}

export default GlobalMuteButton;