export function downloadFile(
  data: BlobPart,
  filename: string,
  mimeType: string
) {
  const file = new Blob([data], { type: mimeType });
  const a = document.createElement("a"),
    url = URL.createObjectURL(file);
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
}

export function generateCSV(
  headers: string[],
  data: (string | number | boolean)[][],
  separator = ","
) {
  const csv = [headers, ...data]
    .map((row) => {
      row = row.map((item) =>
        typeof item === "string" && item.indexOf(separator) !== -1
          ? `"${item}"`
          : item
      );
      return row.join(separator);
    })
    .join("\n");
  return csv;
}
