import { useEffect, useState } from "react";
import { IProjectModel } from "../../stores/project/projectModel";
import { getProjectFormData } from "../../utils/projectTools";
import { DatePicker, Table, Tooltip } from "antd";
import dayjs from "dayjs";
import { BlingsAnchorButton } from "../../components/BlingsAnchorButton/BlingsAnchorButton";
import {
  NEW_REST_API,
  NEW_REST_API__V2__DOWNLOAD_FORM_DATA,
} from "../../consts";
import { get } from "aws-amplify/api";
import "./FormData.scss";

type Props = {
  project: IProjectModel;
};

const DEFAULT_RANGE = 30 * 24 * 60 * 60 * 1000;
const TABLE_PAGE_SIZE = 25;
const REQUEST_PAGE_SIZE = 100;
type Record = {
  id: string;
  sessionId: string;
  userId: string;
  date: string;
  data: string;
  key: number;
};
export const FormDataPage = ({ project }: Props) => {
  const { id, account } = project;
  const [formData, setFormData] = useState<Array<Record> | null>(null);
  const [paginationState, setPaginationState] = useState<{
    nextToken: string | null;
    reachedEnd: boolean;
  }>({
    nextToken: null,
    reachedEnd: false,
  });

  const [fromDate, setFromDate] = useState(Date.now() - DEFAULT_RANGE);
  const [toDate, setToDate] = useState(Date.now());
  const [dataKeys, setDataKeys] = useState(new Set<string>());
  useEffect(() => {
    (() => {
      setFormData(null);
      setPaginationState({
        nextToken: null,
        reachedEnd: false,
      });
    })();
  }, [fromDate, toDate]);
  useEffect(() => {
    if (
      paginationState.nextToken === null &&
      paginationState.reachedEnd === false
    )
      fetchNextPage();
  }, [paginationState]);

  async function fetchNextPage() {
    let itemsCount = 0;
    let nextToken: string | null | undefined = paginationState.nextToken;
    let reachedEnd = paginationState.reachedEnd;
    const formDataItemsCount = (formData && formData.length) || 0;
    let resultItems: any = [];
    let columnsKeys: Array<string> = [];
    while (itemsCount < TABLE_PAGE_SIZE && !reachedEnd) {
      const {
        items,
        nextToken: nextTokenFromRequest,
      }: {
        items: ({
          __typename: "FormData";
          id: string;
          accountOwner: string;
          projectFormDataId: string;
          data: string;
          sessionId: string;
          userId: string;
          createdAt: string;
          updatedAt: string;
        } | null)[];
        nextToken: string | null;
      } = await getProjectFormData(
        id,
        new Date(fromDate),
        new Date(toDate),
        nextToken,
        REQUEST_PAGE_SIZE
      );

      nextToken = nextTokenFromRequest;
      reachedEnd = !nextToken;

      resultItems = [
        ...resultItems,
        ...items.map((item, i) => {
          if (!item) return null;
          const data = JSON.parse(item.data);
          const stringifiedValues: { [key: string]: string } = Object.keys(
            data
          ).reduce((acc: { [key: string]: string }, key: string) => {
            const value = data[key];
            // Directly check if value is an object and convert accordingly
            acc[key] =
              typeof value === "object" && value !== null
                ? JSON.stringify(value)
                : String(value);
            return acc;
          }, {});
          columnsKeys = [...columnsKeys, ...Object.keys(data)];
          return {
            id: item.id,
            sessionId: item.sessionId,
            userId: item.userId,
            date: new Date(item.createdAt).toLocaleString(),
            dateRaw: item.createdAt,
            ...stringifiedValues,
            key: formDataItemsCount + itemsCount + i,
          };
        }),
      ];
      itemsCount += items?.length || 0;
    }

    setPaginationState({
      nextToken,
      reachedEnd,
    });
    setDataKeys(new Set([...Array.from(dataKeys), ...columnsKeys]));
    setFormData(
      [...(formData || []), ...resultItems].sort(
        (a, b) => Date.parse(b.dateRaw) - Date.parse(a.dateRaw)
      )
    );
  }
  const columns = [
    {
      title: "Timestamp",
      dataIndex: "date",
      key: "date",
      render: (text: string, record: Record, index: number) => (
        <Tooltip
          title={
            <div>
              <span>{`Session ID: ${record.sessionId}`}</span>
              <br />
              <span>{`User ID: ${record.userId}`}</span>
            </div>
          }
        >
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Data",
      children: Array.from(dataKeys).map((key) => ({
        title: key.charAt(0).toUpperCase() + key.slice(1),
        dataIndex: key,
        key,
      })),
    },
  ];
  const downloadAsCSV = async () => {
    const from = new Date(fromDate).toISOString();
    const to = new Date(toDate).toISOString();
    const response = await get({
      apiName: NEW_REST_API,
      path: `${NEW_REST_API__V2__DOWNLOAD_FORM_DATA(
        project.id
      )}?from=${from}&to=${to}`,
    }).response;
    const filename = `${project.id}-${from}-${to}.csv`;
    const blob = await response.body.blob(); //new Blob([response.data], { type: "text/csv" });
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("target", "_blank");
    tempLink.setAttribute("download", filename);
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  };
  return (
    <div className="FormData project-tab-padding">
      <div className="top">
        <DatePicker.RangePicker
          className="date-picker"
          defaultValue={[dayjs(fromDate), dayjs(toDate)]}
          onChange={(values) => {
            if (!values) return;
            const to = (values[1] && values[1].unix() * 1000) || Date.now();
            const from =
              (values[0] && values[0].unix() * 1000) || to - DEFAULT_RANGE;
            setToDate(to);
            setFromDate(from);
          }}
          showTime={{ format: "HH:mm" }}
          format={(value) => value.format("MMM DD, YYYY HH:mm")}
        />
        {formData && (
          <BlingsAnchorButton onClick={downloadAsCSV}>
            Download as CSV
          </BlingsAnchorButton>
        )}
      </div>
      {formData && (
        <Table
          dataSource={formData}
          columns={columns}
          pagination={{
            showSizeChanger: false,
            pageSize: TABLE_PAGE_SIZE,
            onChange: async (page, pageSize) => {
              const lastIndexInPagePlus1 = page * pageSize;
              if (
                lastIndexInPagePlus1 >= formData.length &&
                !paginationState.reachedEnd
              ) {
                await fetchNextPage();
              }
            },
          }}
        />
      )}
    </div>
  );
};
