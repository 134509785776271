export function cleanEmptyStringInValue(object: any) {
  Object.keys(object).forEach((key) => {
    if (object[key] === "") {
      delete object[key];
      return;
    }
    if (typeof object[key] === "object") {
      cleanEmptyStringInValue(object[key]);
    }
  });
  return object;
}
// @ts-ignore
type Obj = Record<
  string | number | symbol,
  Obj | string | number | boolean | null
>;
export function flattenObject(
  obj: Obj,
  pre = "",
  result: Obj = {},
  from: null | "object" | "array" = null
) {
  const keys = Object.keys(obj);
  keys.forEach((key) => {
    const prependKey = !from
      ? key
      : from === "array"
      ? "[" + key + "]"
      : "." + key;
    if (Array.isArray(obj[key])) {
      flattenObject(obj[key], `${pre}${prependKey}`, result, "array");
    } else if (typeof obj[key] === "object") {
      flattenObject(obj[key], `${pre}${prependKey}`, result, "object");
    } else {
      result[`${pre}${prependKey}`] = obj[key];
    }
  });
  return result;
}
