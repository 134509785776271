import {
  LandingPageIntegration,
  LandingPageIntegrationState,
} from "./LandingPageIntegration/LandingPageIntegration";
import { Select, Collapse } from "antd";
import { useState } from "react";
import { useMst } from "../../../../stores/Root";
import { JSONSchema6 } from "json-schema";
import { IntegrationLink } from "./IntegrationLink/IntegrationLink";
import { IProjectModel } from "../../../../stores/project/projectModel";
import "./CrmIntegration.scss";
export const crms = [
  {
    name: "Any Crm",
    img: "https://storage.blings.io/projects/eb50961e-3775-4c85-b7eb-0b7fd8fa55ce/assets/image/1690106507958_8020972.png",
    instructions: `1. Copy the link below and go to your CRM platform.
2. Within your crm, create a template or an email campaign. 
3. Within the email editor, select the HTML module and insert it into the template. 
4. Within the HTML block, add the Blings code copied from step one.
5. Update all other variables (in pink) to align with your CRM. 
For example: Replace “{{NAME}}” with “{{contact.firstname}}”`,
    video:
      "https://player.vimeo.com/external/371817283.sd.mp4?s=56639e00db07ad3f26d837314e3da531bad01b1b&profile_id=164&oauth2_token_id=57447761",
    tags: {
      openingTag: "{{",
      closingTag: "}}",
    },
  },
  {
    name: "Hubspot",
    img: "https://storage.blings.io/projects/40ed4e19-fa45-494b-ad7f-72720fe352ef/assets/image/1695813468236_2560px-HubSpot_Logo.svg.png",
    instructions: `1. Copy the link below and go to your Hubspot account.
2. Within HubSpot, from the main Marketing menu select Email and either create a new email or select an existing one to edit.
3. Within the email editor select the + More option, select the HTML module and drag it into the template.  
4. Within the HTML block, add the Blings code copied from step one.
5. Update all other variables (in pink) to align with your CRM. 
For example: Replace “{{NAME}}” with “{{contact.firstname}}”`,
    video:
      "https://player.vimeo.com/external/371817283.sd.mp4?s=56639e00db07ad3f26d837314e3da531bad01b1b&profile_id=164&oauth2_token_id=57447761",
    tags: {
      openingTag: "{{",
      closingTag: "}}",
    },
  },
  {
    name: "Salesforce",
    img: "https://storage.blings.io/projects/40ed4e19-fa45-494b-ad7f-72720fe352ef/assets/image/1695813498529_Salesforce.com_logo.svg.png",
    instructions: `1. Copy the link below and go to your Salesforce / Pardot account.
2. Within Salesforce or Pardot create a new email message and select 'Source' button to toggle to the code view.
3. Then paste in the copied Blings code from step one.
4. Update all other variables (in pink) to align with your CRM. 
For example: Replace “{{{NAME}}}” with “{{{Recipient.FirstName}}}”
    `,
    video:
      "https://player.vimeo.com/external/371817283.sd.mp4?s=56639e00db07ad3f26d837314e3da531bad01b1b&profile_id=164&oauth2_token_id=57447761",
    tags: {
      openingTag: "{{{",
      closingTag: "}}}",
    },
  },
  {
    name: "Mailchimp",
    img: "https://storage.blings.io/projects/40ed4e19-fa45-494b-ad7f-72720fe352ef/assets/image/1695813525273_Mailchimp-logo.png",
    instructions: `1. Copy the link below and go to your Mailchimp account.
2. Within MailChimp create a new email campaign, choose your template and select 'Add HTML'.
3. Then paste in the Blings code copied from step one. 
4. Update all other variables (in pink) to align with your CRM. 
For example: Replace “{{NAME}}” with “*|FNAME|*”
    `,
    video:
      "https://player.vimeo.com/external/371817283.sd.mp4?s=56639e00db07ad3f26d837314e3da531bad01b1b&profile_id=164&oauth2_token_id=57447761",
    tags: {
      openingTag: "*|",
      closingTag: "|*",
    },
  },
  {
    name: "Marketo",
    img: "https://storage.blings.io/projects/40ed4e19-fa45-494b-ad7f-72720fe352ef/assets/image/1695813553156_1690095266361_Marketo-Logo.png",
    instructions: `1. Copy the link below and go to your Marketo account.
2. Within Marketo create a new email message and select 'View Source'.
3. Then paste in the copied Blings code from step one.
4. You can now switch back by clicking 'Hide Source' and you will now see your template.
5. It will show with default data in the preview, but these will be updated for each prospect as the emails are sent out.
6. Update all other variables (in pink) to align with your CRM. 
For example: Replace “{{NAME}}” with “{{lead.First Name}}”
    `,
    video:
      "https://player.vimeo.com/external/371817283.sd.mp4?s=56639e00db07ad3f26d837314e3da531bad01b1b&profile_id=164&oauth2_token_id=57447761",
    tags: {
      openingTag: "{{",
      closingTag: "}}",
    },
  },
  {
    name: "Salesloft",
    img: "https://storage.blings.io/projects/40ed4e19-fa45-494b-ad7f-72720fe352ef/assets/image/1695813580163_1690095471743_sl-logo.webp",
    instructions: `1. Copy the link below and go to your Salesloft account.
2. Within SalesLoft create/edit a campaign, within the email editor click the </> button to view source and paste in the image code copied from step one. 
3. You can now click the </> button again to go back to the preview mode.
4. Update all other variables (in pink) to align with your CRM. 
For example: Replace “{{NAME}}” with “{{first_name}}”
NOTE: The ability to edit HTML in the editor window must be enabled by a team admin. If you do not see the editor 
option in your editor window, reach out to your team admin to verify Edit HTML is turned on in the team Email Settings.
    `,
    video:
      "https://player.vimeo.com/external/371817283.sd.mp4?s=56639e00db07ad3f26d837314e3da531bad01b1b&profile_id=164&oauth2_token_id=57447761",
    tags: {
      openingTag: "{{",
      closingTag: "}}",
    },
  },
];
type Props = {
  project: IProjectModel;
  state: LandingPageIntegrationState;
  setState: React.Dispatch<React.SetStateAction<LandingPageIntegrationState>>;
  savedState: LandingPageIntegrationState;
};
export const CrmIntegration = (props: Props) => {
  const { project, state, setState, savedState } = props;

  return (
    <div className="CrmIntegration">
      <LandingPageIntegration
        project={project}
        state={state}
        setState={setState}
        savedState={savedState}
      />

      <DataIntegration />
    </div>
  );
};

const DataIntegration = () => {
  const state: {
    accountAliasId: string;
    schema: JSONSchema6;
    projectAliasId: string;
    projectAccountDomain: string;
  } = useMst((store) => ({
    accountAliasId: store.projectsStore.selectedProject?.account?.aliasId || "",
    schema: store.projectsStore.selectedProject?.stateJsonSchema,
    projectAliasId: store.projectsStore.selectedProject?.aliasId || "",
    projectAccountDomain:
      store?.projectsStore?.selectedProject?.projectAccountDomain || "",
  }));
  const { accountAliasId, schema, projectAliasId, projectAccountDomain } =
    state;
  const [selectedCrmIndex, setSelectedCrmIndex] = useState(0);
  const selectedCrm = crms[selectedCrmIndex];
  return (
    <div className="DataIntegration">
      <div>
        <h1 className="page-subtitle">2. Data Integration Instructions</h1>
        <div>
          <div>
            <Select
              size={"large"}
              className="crm-select"
              onChange={(e) => setSelectedCrmIndex(e)}
              value={selectedCrmIndex}
              placeholder={"Select Crm"}
              options={crms.map((crm, i) => ({
                value: i,
                label: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "250px",
                      alignItems: "center",
                    }}
                  >
                    {crm.name}
                    <img
                      alt={"Crm logo"}
                      src={crm.img}
                      height="26px"
                      width="auto"
                    />
                  </div>
                ),
              }))}
            />
            <pre style={{ fontFamily: "Inter", lineHeight: "28px" }}>
              {selectedCrm.instructions}
            </pre>
          </div>
        </div>
        <IntegrationLink
          accountAliasId={accountAliasId}
          projectAliasId={projectAliasId}
          schema={schema}
          tags={selectedCrm.tags}
          projectAccountDomain={projectAccountDomain}
        />
      </div>
      {/* <div className="video-container">
      <video autoPlay src={selectedCrm.video}></video>
    </div> */}
    </div>
  );
};
