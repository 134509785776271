import { PerEventsProps } from "../Analytics";
import { Table } from "antd";
export const TopCtas = ({
  data,
  events,
}: {
  data?: {
    sessionsClickedAnyCta: {
      value: number;
    };
    ctaClicks: {
      buckets: Array<{
        key: string;
        doc_count: number;
        uniqueSessionClicks: { value: number };
      }>;
    };
  };
  events?: PerEventsProps;
}) => {
  if (!data) return null;
  const ctaClicks = data.ctaClicks.buckets;
  const totalSessions = events?.find((e) => e.key === "ready")?.doc_count || 0;
  const totalPlays =
    events?.find((e) => e.key === "first-play")?.doc_count || 0;
  const ctas: { [key: string]: number } = {};
  if (ctaClicks.length) {
    ctaClicks.forEach((cta) => {
      ctas[cta.key] = cta.uniqueSessionClicks.value;
    });
  }
  const processedData = Object.entries(ctas)
    .map((cta) => ({ key: cta[0], doc_count: cta[1] }))
    .filter((cta) => cta.key !== "no-cta");
  const totalCtas = processedData.reduce(
    (prev, curr) => prev + curr.doc_count,
    0
  );
  return processedData.length ? (
    <div className="analytics-view top-cta" style={{ height: "100%" }}>
      <div className="view-title">
        <h2>Conversion</h2>
      </div>
      <div className="content">
        <table width={"100%"}>
          <tbody>
            <tr>
              <td style={{ verticalAlign: "top" }}>
                <h3>Converting Sessions: {data.sessionsClickedAnyCta.value}</h3>
              </td>
              <td
                style={{
                  textAlign: "end",
                  paddingRight: "1rem",
                  display: "flex",
                  flexDirection: "row-reverse",
                  paddingBottom: ".5rem",
                }}
              >
                <div className="out-of-group">
                  <span className="percentage">
                    {(
                      (data.sessionsClickedAnyCta.value / totalPlays) *
                      100
                    ).toFixed(1)}
                    %
                  </span>
                  <span className="out-of">Out of Play</span>
                </div>
              </td>
            </tr>
            <tr style={{ borderTop: ".5px solid black" }}>
              <td>
                <h3>Top CTAs</h3>
              </td>
              {/* <td style={{ visibility: "hidden" }}></td> */}
              <td style={{ textAlign: "end", paddingRight: "1rem" }}>
                <h4>Total {totalCtas.toLocaleString("en-US")}</h4>
              </td>
            </tr>
          </tbody>
        </table>

        <Table
          pagination={false}
          style={{ overflow: "hidden" }}
          scroll={{ y: "180px" }}
          dataSource={processedData
            .sort((a, b) => b.doc_count - a.doc_count)
            .map((d) => ({
              ...d,
              doc_count: `${d.doc_count}`,
            }))}
          columns={[
            {
              title: "Name",
              key: "key",
              dataIndex: "key",
            },
            {
              title: "Clicks",
              key: "doc_count",
              dataIndex: "doc_count",
            },
          ]}
        />
      </div>
    </div>
  ) : null;
};
