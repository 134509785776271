import { SearchOutlined } from "@ant-design/icons";
import { Input, List } from "antd";
import { useEffect, useRef } from "react";
import "./BlingsList.scss";
type Props<T> = {
  options: Array<T>;
  setResults?: React.Dispatch<React.SetStateAction<Array<any>>>;
  setCurrentInput: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  currentInput: string;
  handleSelect: (key: string | number) => void;
  className?: string;
  popoverOpen?: boolean;
};

const BlingsList = <T extends { label: string; key: string | number }>({
  setResults,
  options,
  currentInput,
  setCurrentInput,
  placeholder,
  handleSelect,
  className,
  popoverOpen,
}: Props<T>) => {
  const inputReference = useRef<any>(null);

  useEffect(() => {
    if (popoverOpen) {
      setTimeout(() => {
        inputReference?.current?.focus();
      }, 100);
    }
  }, [popoverOpen]);
  return (
    <>
      <Input
        ref={inputReference}
        className={"Search-Input"}
        id="popover-search"
        allowClear
        value={currentInput}
        onChange={(e) => setCurrentInput(e.target.value)}
        variant="borderless"
        placeholder={placeholder}
        prefix={<SearchOutlined className={"SearchIcon"} />}
        {...(setResults && {
          onPressEnter: (e: any) =>
            setResults(
              options.filter((p) =>
                p.label
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase().trim())
              )
            ),
        })}
      />
      <List
        dataSource={[...options]
          .filter(
            (
              p // Filter the search
            ) => {
              const name = p.label;
              return name
                .toLowerCase()
                .trim()
                .includes(currentInput.toLowerCase().trim());
            }
          )
          .sort((a, b) =>
            // Make project order alphabetical
            a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase() ? -1 : 1
          )}
        bordered={false}
        {...(className && { className })}
        renderItem={(item: T) => (
          <div
            onClick={() => {
              handleSelect(item.key);
              setCurrentInput("");
            }}
            className="ProjectItem"
          >
            {item.label}
          </div>
        )}
      />
    </>
  );
};

export default BlingsList;
