import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IProjectModel } from "../../stores/project/projectModel";
import { useMst } from "../../stores/Root";
import { IuploadDataModel } from "../../stores/uploadData";
import DemoPlayer from "./DemoPlayer";
import "./EditVideo.scss";
import EditView from "./EditView";
import SceneView from "./SceneView";
import SearchLayers from "./SearchLayers";
import { LayersAndModsPerScene } from "../../types/EditVideoTypes";
import { PATHS, PROJECT_PATHS, toPath } from "../../PATHS";
type Props = {
  project: IProjectModel;
  uploadData: IuploadDataModel;
  scene: string;
  currentSearch: string;
  setCurrentSearch: (searchString: string) => void;
  setSearchView: (searchView: boolean) => void;
};

const EditVideo = ({
  project,
  uploadData,
  scene,
  setCurrentSearch,
  setSearchView,
}: Props) => {
  const [allLayersAndMods, setAllLayersAndMods] = useState<
    LayersAndModsPerScene[]
  >([]);
  // Current active panels
  const [activePanels, setActivePanels] = useState<string[]>([]); // Panels currently open
  const history = useNavigate();

  /**
   * This method activates and deactivates the active panels and redirects to the correct scene
   * If we are opening a panel which is not in the current scene
   * (ex. if we are opening a panel from a specific scene in search mode)
   * Then we should redirect to that scene
   * @param keys
   * @returns (keys) => void. This method activates and deactivates the active panels
   */
  const handlePanelCollapse = (keys: string[] | string) => {
    if (!keys) setActivePanels([]);
    else {
      typeof keys === "string"
        ? activePanels.includes(keys)
          ? setActivePanels([])
          : setActivePanels([keys])
        : setActivePanels(keys);
    }
    //Get scene name from current panel
    let sceneName;
    try {
      //@ts-ignore
      sceneName = JSON.parse(keys)?.sceneName;
    } catch (e) {
      sceneName = "";
    }
    // Redirect to the scene from the active panel if it is not already
    if (sceneName && sceneName !== scene) {
      history(
        toPath(PATHS.project, project.id, PROJECT_PATHS.editVideo, sceneName)
      );
    }
  };

  const hasVideoParts = !!project.workspaceVideoParts.length;
  // Current input of the search box
  /**
   * If we are going to search view, redirect to the editview url without selecting a specific scene
   * Reset the search bar and update the currentSearch state to show results for that search
   * @param searchString
   */
  const filterLayers = (searchString: string) => {
    if (searchString !== "") {
      history(toPath(PATHS.project, project.id, PROJECT_PATHS.editVideo)); //Unselect scenes and player
      setCurrentInput(""); //Reset the search bar
      setSearchView(true);
      setCurrentSearch(searchString.toLowerCase().trim()); //Set search state to the new string
    }
  };
  const [currentInput, setCurrentInput] = useState("");
  if (!hasVideoParts)
    return (
      <div className="Main" style={{ alignItems: "center", marginTop: "30vh" }}>
        Create a video using Blings extension, and see a demo here
      </div>
    );
  return (
    <div className={"Main"}>
      <div className="edit-video-header">
        <div className="Left-Side">
          <div className={"SubHeader"}>Edit Video</div>
          <div className={"sub-SubHeader"}>
            Edit text and buttons throughout the video scenes
          </div>
        </div>
        <SearchLayers
          projectId={project.id}
          scene={scene}
          layersAndMods={allLayersAndMods}
          handlePanelCollapse={handlePanelCollapse}
          filterLayers={filterLayers}
          currentInput={currentInput}
          setCurrentInput={setCurrentInput}
        />
      </div>
      <div className={"Container"}>
        <div className={"Scenes"}>
          <SceneView project={project} selectedScene={scene} />
        </div>
        <div className={"Edit"}>
          <EditView
            project={project}
            selectedScene={scene}
            handlePanelCollapse={handlePanelCollapse}
            activePanels={activePanels}
            setAllLayersAndMods={setAllLayersAndMods}
            allLayersAndMods={allLayersAndMods}
          />
        </div>
        <div className={"Demo"}>
          <div className={"TopBar"}></div>
          <DemoPlayer scene={scene} project={project} uploadData={uploadData} />
        </div>
      </div>
    </div>
  );
};
const EditVideoWrapper = observer(() => {
  const { id, scene } = useParams<{ id: string; scene: string }>();
  const [state, setState] = React.useState<any>();

  const storeMethod = useMst((store) => {
    return {
      setSelectedProjectById: store.projectsStore.setSelectedProjectById,
      isLoading: store.projectsStore.projectsIsLoading,
      project: store.projectsStore.selectedProject,
      setSearchView: store.editVideoStore.setSearchView,
      searchView: store.editVideoStore.searchView,
      currentSearch: store.editVideoStore.currentSearch,
      setCurrentSearch: store.editVideoStore.setCurrentSearch,
    };
  });
  React.useEffect(() => {
    const {
      setSelectedProjectById,
      project,
      setSearchView,
      setCurrentSearch,
      searchView,
      currentSearch,
    } = storeMethod;
    setState({
      scene,
      project,
      setCurrentSearch,
      setSearchView,
      currentSearch,
    });
    // On refresh, set default page to search all
    if (!scene && !searchView) {
      // If not in a current search state and no scene is selected
      setSearchView(true);
      setCurrentSearch("");
    }
    const load = async (id: string) => await setSelectedProjectById(id);
    id && load(id);
  }, [id, scene, storeMethod.project?.workspaceVideoParts?.length]);

  if (!state?.project) {
    return null;
  }

  return <EditVideo {...state} />;
});

export default EditVideoWrapper;
