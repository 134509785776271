import * as React from "react";
import { IProjectModel } from "../../stores/project/projectModel";
import { useNavigate } from "react-router-dom";
import { PATHS, toPath } from "../../PATHS";
import BlingsList from "./BlingsList";
import "./ProjectSearch.scss";
type Props = {
  projects: IProjectModel[];
  popoverOpen: boolean;
  setPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ProjectSearch = ({
  projects,
  setPopoverOpen,
  popoverOpen,
}: Props) => {
  const [searchInput, setSearchInput] = React.useState<string>("");

  const history = useNavigate();
  const pjOptions = projects.map((p) => ({ label: p.title, key: p.id }));
  return (
    <>
      <BlingsList
        className="ProjectSearch"
        popoverOpen={popoverOpen}
        options={pjOptions.sort((a, b) =>
          // Make project order alphabetical
          a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase() ? -1 : 1
        )}
        setCurrentInput={setSearchInput}
        currentInput={searchInput}
        placeholder="Select a project"
        handleSelect={(key) => {
          console.log(key);
          setPopoverOpen(false);
          history(toPath(PATHS.project, key as string));
        }}
      />
    </>
  );
};
