import {
  NavLink,
  Outlet,
  useNavigate,
  useLocation,
  useParams,
  Link,
} from "react-router-dom";
import { toPath, PATHS, PROJECT_PATHS, camelCaseToRegular } from "../../PATHS";
import "./NavBar.scss";
import { IProjectModel } from "../../stores/project/projectModel";
import { observer } from "mobx-react-lite";
import { rootStore, useMst } from "../../stores/Root";
import NotificationBellWrapper from "../../components/notification/notificationBell";
import { Button, Layout, Dropdown, Popover, Select, Menu, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { AsyncOpState } from "../../types/enums/async-op-states";
import staticText from "../../utils/staticText";
import { BlingsBtn } from "../../components/antd-extensions/blings-btn.component";
import { dateFormatter } from "../../helpers/timeAgo.helpers";
import { getENV } from "../../config";
import useDebounce from "../../helpers/DebounceHook";
import { BlingsButton } from "../../components/BlingsButton/BlingsButton";
import {
  ArrowDown,
  BlingsLogo,
  SettingsIcon,
  TimeIcon,
} from "../../assets/Icons";
import { ProjectSearch } from "../ProjectsList/ProjectSearch";
import PlayerManager from "../../utils/playerManager";
import { TextButton } from "../../components/TextButton";
type Props = {
  project: IProjectModel | undefined;
  projects: IProjectModel[];
  isLoading: boolean;
  operation: string | undefined;
  isPsychedelic: boolean;
  userEmail: string;
  setSearchView: (searchView: boolean) => void;
  setCurrentSearch: (searchString: string) => void;
  projectCurrentlyLoading: boolean;
  hasUnsavedChanges?: boolean;
};
export const EXAMPLE_PLAYER_LINK = "https://assets.blings.io/player/index.html";

const NavBarWrapper = observer(() => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  // Get operation from url
  const operation = location.pathname.split("/")?.[3];
  const state = useMst((store) => {
    return {
      project: id ? store.projectsStore.selectedProject : undefined,
      hasUnsavedChanges: store.projectsStore.selectedProject?.hasUnsavedChanges,
      isLoading: store.projectsStore.projectIdCurrentlyLoading,
      operation,
      projects: store.projectsStore.projects,
      setSearchView: store.editVideoStore.setSearchView,
      setCurrentSearch: store.editVideoStore.setCurrentSearch,
      isPsychedelic: store.uiStore.isPsychedelic,
      projectCurrentlyLoading: store.projectsStore.projectIdCurrentlyLoading,
      userEmail: store.accountStore.userAttributes?.email,
    };
  });
  return <NavBar {...state} />;
});

const NavBar = ({
  project,
  projects: allProjects,
  operation,
  isPsychedelic,
  projectCurrentlyLoading,
  userEmail,
  hasUnsavedChanges,
}: Props) => {
  const history = useNavigate();

  const [hiddenNavItems, setHiddenNavItems] = useState<any>([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverLiveVersionOpen, setPopoverLiveVersionOpen] = useState(false);

  const navbarRef = useRef<HTMLDivElement>(null);
  const navItemsRef = useRef<HTMLDivElement>(null);

  const [navbarWidth, setNavbarWidth] = useState(0);
  const debouncedNavbarWidth = useDebounce(navbarWidth, 200); // 200ms debounce

  const widthStepsToItemsHide: { [key: number]: number } = {
    1200: 1,
    1100: 2,
    1000: 3,
    940: 4,
    800: 5,
    700: 6,
  };
  useEffect(() => {
    const handleResize = () => {
      if (navbarRef.current) {
        setNavbarWidth(navbarRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    setTimeout(() => {
      handleResize(); // Initial call
    }, 2000);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [projectCurrentlyLoading]); // This effect should only run once on mount/unmount

  useEffect(() => {
    const navItemsChildren = Array.from(
      navItemsRef?.current?.children || []
    ).filter((item: any) => item.id !== "extraOptions");
    const tempHiddenNavItems: any[] = [];
    const tempVisibleNavItems: any[] = [];

    if (navItemsChildren) {
      let itemsToHide = 0;

      // Determine how many items to hide based on the width step.
      for (const width of Object.keys(widthStepsToItemsHide)) {
        if (debouncedNavbarWidth < Number(width)) {
          itemsToHide = widthStepsToItemsHide[Number(width)];
          break;
        }
      }

      Array.from(navItemsChildren).forEach((item, index) => {
        if (index >= navItemsChildren.length - itemsToHide) {
          tempHiddenNavItems.push(item);
        } else {
          tempVisibleNavItems.push(item);
        }
      });

      setHiddenNavItems(tempHiddenNavItems);

      // Handle display for nav items
      tempVisibleNavItems.forEach((item) => (item.style.display = "block"));
      tempHiddenNavItems.forEach((item) => (item.style.display = "none"));
    }
  }, [debouncedNavbarWidth, navItemsRef, popoverOpen]);

  const overLayLogo = (
    <div
      style={{
        background: "white",
        border: "1px solid #C5C5C5",
        padding: "1rem",
        alignItems: "left",
        display: "flex",
        flexDirection: "column",
        borderRadius: "10px",
        gap: "0.7rem",
      }}
    >
      {/* <Link to={toPath(PATHS.create_new_project)}>
        <p>
          <PlusSquareOutlined /> New Project
        </p>
      </Link> */}
      <Link to={toPath(PATHS.data)}>
        <TextButton
          underline={false}
          // outline
          onClick={() => {
            console.log("Data Uploads");
          }}
          text={"Data Uploads"}
        />
      </Link>
      <Link to={toPath(PATHS.settings)}>
        <TextButton
          // outline
          underline={false}
          onClick={() => {
            console.log("Account Settings");
          }}
          text={"Account Settings"}
        />
      </Link>
      <TextButton
        underline={false}
        // outline
        onClick={() => rootStore.accountStore.signOut(history)}
        text={"Log Out"}
      />
    </div>
  );
  const [scenes, setScenes] = useState<string[]>([]);
  const [publishButtonState, setPublishButtonState] = useState<AsyncOpState>(
    AsyncOpState.Changed
  );
  const publishButtonTexts = {
    [AsyncOpState.Changed]: "Publish",
    [AsyncOpState.Saving]: "Publishing",
    [AsyncOpState.Success]: "Published",
    [AsyncOpState.Error]: "Error",
  };
  const lastPublishedAt = project?.publishedAt || project?.updatedAt || "";
  /**
   *  Check if project has nav item to render in the navbar
   * @param navItem
   * @returns
   */
  const projectHasNavItem = (navItem: string) => {
    switch (navItem) {
      case PROJECT_PATHS.customAnalytics:
        return !!project?.analyticsReportUrl;
      case PROJECT_PATHS.analytics:
        return project?.analyticsEnabled && !project.analyticsReportUrl;
      case PROJECT_PATHS.liveControl:
        let hasControl = false;
        try {
          if (project?.settingsJsonSchemaStr) {
            const control = JSON.parse(project.settingsJsonSchemaStr);
            if (control && control.properties) {
              hasControl = JSON.stringify(control.properties) !== "{}";
            }
          }
        } catch (e) {
          console.error(e);
        }
        return !!hasControl;
      case PROJECT_PATHS.formData:
        if (!project) return false;
        return project.hasFormData;
      default:
        return true;
    }
  };
  function openLiveVersion() {
    let url = `${EXAMPLE_PLAYER_LINK}?env=${getENV()}&p=${project?.id || ""}`;
    if (scenes) {
      url += `&scenes=${encodeURIComponent(scenes.join())}`;
    }
    const publishedExampleData = project?.stateJsonSchemaStr
      ? JSON.parse(project.stateJsonSchemaStr).examples[0]
      : null;
    if (publishedExampleData) {
      url += `&data=${encodeURIComponent(
        JSON.stringify(publishedExampleData)
      )}`;
    }

    const currentVersion = PlayerManager.get().GetCurrentPlayerVersion();
    // Do nothing if the player has the latest version
    if (currentVersion === "latest") {
    }
    // If there is no player version, use the one that the project has specified
    else if (currentVersion === "WARN_NO_PLAYER_VERSION") {
      console.warn("No player version specified, using project version");
      console.warn("Project version: ", project?.playerVersionToUse);
      url += `&v=${(project?.playerVersionToUse as string).replaceAll(
        ".",
        "-"
      )}`;
    }
    // If there is a player version, use that
    else {
      url += `&v=${currentVersion}`;
    }

    window.open(url, "_blank");
  }

  const save = async () => {
    try {
      setPublishButtonState(AsyncOpState.Saving);
      await project?.publishProject();
      setPublishButtonState(AsyncOpState.Changed);
    } catch (e) {
      setPublishButtonState(AsyncOpState.Error);
    }
    // after 3 seconds, reset
    setTimeout(() => {
      setPublishButtonState(AsyncOpState.Changed);
    }, 3000);
  };

  return (
    <>
      <Layout>
        <Layout.Header className={"Main Header"}>
          <div className={"NavBar"} ref={navbarRef}>
            <div className="left">
              <Link to={"/"} className="LogoLink">
                <div
                  style={{
                    color: isPsychedelic ? "orange" : "#FF154D",
                    display: "flex",
                  }}
                >
                  <BlingsLogo />
                </div>
              </Link>
              {project && !projectCurrentlyLoading ? (
                <>
                  <Popover
                    mouseEnterDelay={0}
                    placement="bottomRight"
                    overlayClassName="overlayClass"
                    content={
                      <ProjectSearch
                        projects={allProjects}
                        setPopoverOpen={setPopoverOpen}
                        popoverOpen={popoverOpen}
                      />
                    }
                    trigger="click"
                    open={popoverOpen}
                    onOpenChange={setPopoverOpen}
                  >
                    <div className="ProjectTitle" title={project.title}>
                      <span className="project-title-text">
                        {project.title}
                      </span>
                      <div
                        style={{ color: "#A6A8B1" }}
                        className={
                          popoverOpen ? "arrow-down selected" : "arrow-down"
                        }
                      >
                        <ArrowDown />
                      </div>
                    </div>
                  </Popover>
                  <div className="menu-items" ref={navItemsRef}>
                    {Object.entries(PROJECT_PATHS).map(
                      (navItem) =>
                        projectHasNavItem(navItem[1]) && (
                          <NavLink
                            key={toPath(navItem[1])}
                            id={camelCaseToRegular(navItem[0])}
                            to={toPath(PATHS.project, project.id, navItem[1])}
                            className={({ isActive }) =>
                              [
                                "NavItem",
                                isActive
                                  ? // ||
                                    // (operation === "edit-video" &&
                                    //   navItem[1] === "edit-video")
                                    "Selected"
                                  : "",
                              ].join(" ")
                            }
                          >
                            {camelCaseToRegular(navItem[0])}
                          </NavLink>
                        )
                    )}
                    {hiddenNavItems.length ? (
                      <Dropdown
                        overlay={
                          <Menu>
                            {hiddenNavItems.map((item: any) => (
                              <Menu.Item key={item.id}>
                                <NavLink to={item.getAttribute("href")}>
                                  {item.textContent}
                                </NavLink>
                              </Menu.Item>
                            ))}
                          </Menu>
                        }
                      >
                        <div
                          id="extraOptions"
                          style={{ fontSize: "20px", cursor: "pointer" }}
                        >
                          ...
                        </div>
                      </Dropdown>
                    ) : null}
                  </div>
                </>
              ) : (
                <div></div>
              )}
            </div>
            <div className="RightNav">
              {project ? (
                <>
                  <div
                    id="ExtraInfo-long"
                    title={new Date(lastPublishedAt).toString()}
                  >
                    {staticText.header.VERSION(project.isLiveVersion)}{" "}
                    {staticText.header.LAST_EDIT}
                    {lastPublishedAt
                      ? dateFormatter(new Date(lastPublishedAt))
                      : "done"}{" "}
                    {/* {staticText.header.BY_USER("User")} */}
                  </div>
                  <div id="ExtraInfo-short">
                    <Tooltip
                      placement="bottomLeft"
                      title={new Date(lastPublishedAt).toString()}
                    >
                      <div style={{ display: "flex" }}>
                        <TimeIcon />
                      </div>
                    </Tooltip>
                  </div>

                  <div id="PreviewButton" className="previewPublished-long">
                    <Popover
                      overlayClassName="view-live-version-popover"
                      placement="bottomRight"
                      title="Add scenes"
                      open={popoverLiveVersionOpen}
                      onOpenChange={setPopoverLiveVersionOpen}
                      content={
                        <div>
                          <Select
                            id="view-live-select"
                            mode="multiple"
                            onChange={setScenes}
                            value={scenes}
                            suffixIcon={null}
                            className="view-live-version-select"
                          >
                            {project?.videoPartNames?.map((vp) => (
                              <Select.Option key={vp} value={vp}>
                                {vp}
                              </Select.Option>
                            ))}
                          </Select>
                          <Button type="primary" onClick={openLiveVersion}>
                            Go
                          </Button>
                        </div>
                      }
                      trigger="click"
                    >
                      <div className="previewPublished-long">
                        <BlingsButton
                          className="LiveVersion ButtonEditVideo"
                          outline
                          onClick={() => {
                            console.log("Open popover");
                          }}
                        >
                          <>
                            {staticText.header.LIVE_VERSION}{" "}
                            <div
                              className={
                                popoverLiveVersionOpen
                                  ? "arrow-down selected"
                                  : "arrow-down"
                              }
                            >
                              <ArrowDown />
                            </div>
                          </>
                        </BlingsButton>
                      </div>
                      <div className="previewPublished-short ">
                        <BlingsButton
                          className="LiveVersion ButtonEditVideo"
                          outline
                          onClick={() => {
                            console.log("Open popover");
                          }}
                        >
                          <>
                            {staticText.header.LIVE_VERSION_SHORT}{" "}
                            <div
                              className={
                                popoverLiveVersionOpen
                                  ? "arrow-down selected"
                                  : "arrow-down"
                              }
                            >
                              <ArrowDown />
                            </div>
                          </>
                        </BlingsButton>
                      </div>
                    </Popover>
                  </div>
                  {operation === "edit-video" && (
                    <div id="PublishButton">
                      <BlingsBtn
                        className={"publish ButtonEditVideo"}
                        opState={publishButtonState}
                        htmlType={"submit"}
                        btnTexts={publishButtonTexts}
                        onClick={save}
                        disabled={!hasUnsavedChanges}
                      />
                    </div>
                  )}
                  <div className="notificationIcon">
                    <NotificationBellWrapper />
                  </div>
                  <Dropdown overlay={overLayLogo} trigger={["click"]}>
                    <div className="settings">
                      <SettingsIcon />
                    </div>
                  </Dropdown>
                </>
              ) : (
                <>
                  <div className="user-email">{userEmail}</div>
                  <div className="notificationIcon">
                    <NotificationBellWrapper />
                  </div>
                  <Dropdown overlay={overLayLogo} trigger={["click"]}>
                    <div className="settings" id="account-dropdown">
                      <SettingsIcon />
                    </div>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
        </Layout.Header>
        <Layout.Content className={"Main-Content"}>
          <div className="inner-div">
            <Outlet />
          </div>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default NavBarWrapper;
