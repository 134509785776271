import "./TextButton.scss";

type Props = {
  onClick: () => void;
  text: string;
  className?: string;
  underline?: boolean;
};

export const TextButton = ({
  onClick,
  text,
  className,
  underline = true,
}: Props) => {
  return (
    <span
      onClick={onClick}
      className={[
        "TextButton",
        className ? className : "",
        underline ? "underline" : "",
      ].join(" ")}
    >
      {text}
    </span>
  );
};
