import React, { FunctionComponent } from "react";
import "./ResponsiveIframeContainer.scss";

interface Props {
  minW?: number;
  w: number;
  h: number;
}

const ResponsiveIframeContainer: FunctionComponent<
  React.HTMLAttributes<HTMLDivElement> & Props
> = ({ children, minW, w, h, ...rest }) => {
  return (
    <div
      {...rest}
      className={"responsive-inline-frame " + rest.className}
      style={{
        maxWidth: w,
        minWidth: minW,
      }}
    >
      <div
        className="responsive-inline-frame-inner"
        style={{ paddingTop: `${(h / w) * 100}%` }}
      >
        {children}
      </div>
    </div>
  );
};

export default ResponsiveIframeContainer;
