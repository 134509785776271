import {
  COMMON_PATHS,
  findLayersOrLayerAssets,
  getPath,
} from "@blings/blings-player";
import {
  IProjectModel,
  IVidPartModel,
  mainAssetKey,
} from "../stores/project/projectModel";
import {
  Additional,
  EditVideoInteractiveType,
  DynamicMod,
  AllLayersAndModsTypes,
  LayersAndModsPerScene,
  StaticTextLayer,
  EditVideoTextType,
  AllowedInteractiveTypes,
  EditVideoExposedToPlatformType,
  EditVideoTypes,
} from "../types/EditVideoTypes";
import {
  getLayersTitle,
  getOriginalNameOfAsset,
  shortenTextAEStyle,
} from "../view/EditVideo/utils";

const allowedTypes: AllowedInteractiveTypes[] = [
  "interactiveJSExpression",
  "interactiveGoto",
  "interactiveOpen",
  "interactiveConcat",
  "interactiveForm",
];
const getTextFromDataStr = (dataStr: any, settings: any) => {
  const defaultValue = dataStr?.defaultValue;
  const liveControlKey = dataStr?.liveControlKey;
  // If the settings object has nested object properties
  let currentNested: any = null; //Object until it becomes a string
  if (liveControlKey?.includes(".")) {
    const nestedProperties = liveControlKey.split(".");
    for (const currentProperty of nestedProperties) {
      currentNested = currentNested
        ? currentNested?.[currentProperty]
        : (currentNested = settings?.[currentProperty]); //If we are at the first level, get from settings, else go deep into object
    }
  }
  return (
    currentNested ||
    settings?.[liveControlKey] ||
    dataStr.value ||
    defaultValue ||
    ""
  );
};
/**
 * This method goes through the mods array and separates the dynamic and static mods
 * @param modsArr
 */
const separateMods = (
  modsArr: IVidPartModel["modsArr"],
  initialModsArr: IVidPartModel["modsArr"],
  settings: any
) => {
  const dynamicMods: DynamicMod[] = [];
  const initialDynamicMods: DynamicMod[] = [];
  const staticTextLayer: StaticTextLayer[] = [];
  const initialStaticTextLayer: StaticTextLayer[] = [];
  // Go through all the mods and separate them into dynamic and static mods
  modsArr?.forEach((mod) => {
    const dataStr = mod?.dataStr && JSON.parse(mod.dataStr);
    if (dataStr?.isDisabled) {
      return;
    }

    // If this mod is static text
    if (
      dataStr.type === "text" &&
      (dataStr?.value != null || dataStr?.experimentId != null)
    ) {
      // Get the mods from the state
      const textFromDataStr = getTextFromDataStr(dataStr, settings);
      staticTextLayer.push({
        layerName: dataStr.layerName,
        layerUid: dataStr?.layerUid,
        text: textFromDataStr,
        experimentId: dataStr?.experimentId,
        fontSize: dataStr?.fontSize,
        additionals: dataStr?.additionals,
      });
    } else {
      // If this mod is Dynamic
      const dynamicMod: DynamicMod = {
        dataStr,
        id: mod?.id.toString() || "",
        layers: [],
        name: mod?.name || "",
      };
      dynamicMod.layers = [dataStr.layerName];
      dataStr?.additionals?.forEach((additional: Additional) =>
        dynamicMod.layers.push(additional.layerName)
      );
      dynamicMods.push(dynamicMod);
    }
  });
  // Go through all the initial mods which are the latest ones saved in the db
  initialModsArr?.forEach((mod) => {
    const dataStr = mod?.dataStr && JSON.parse(mod.dataStr);
    // Get the initial mods that are the latest ones saved in the db
    if (dataStr?.isDisabled) {
      //skip this mod
      return;
    }
    if (dataStr.type === "text") {
      const textFromDataStr = getTextFromDataStr(dataStr, settings);

      initialStaticTextLayer.push({
        layerName: dataStr.layerName,
        text: textFromDataStr,
        fontSize: dataStr?.fontSize,
        layerUid: dataStr?.layerUid,
        experimentId: dataStr?.experimentId,
      });
    } else {
      initialDynamicMods.push({
        id: mod?.id.toString() || "",
        dataStr: dataStr,
        layers: [],
      });
    }
  });
  return {
    staticTextLayer,
    initialStaticTextLayer,
    dynamicMods,
    initialDynamicMods,
  };
};

// Gets all text layers from the currentScene
export const getProcessedLayers = (
  currentScene: IVidPartModel | undefined,
  project: IProjectModel
) => {
  const allStaticTextLayers: EditVideoTextType[] = []; // Text layers without connectors, or with origin='platform...'
  const allDynamicTextLayers: EditVideoTextType[] = [];
  const allInteractiveLayers: EditVideoInteractiveType[] = [];
  const allExposedToPlatformConnectors: EditVideoExposedToPlatformType[] = [];
  if (!currentScene) {
    return {
      allDynamicTextLayers,
      allStaticTextLayers,
      allInteractiveLayers,
      allExposedToPlatformConnectors,
    };
  }
  // If this scene has existing mods, separate them into dynamic and static layers
  const {
    staticTextLayer,
    initialStaticTextLayer,
    dynamicMods,
    initialDynamicMods,
  } = separateMods(
    currentScene.modsArr,
    currentScene.initialModsArr,
    project?.settings
  );
  if (currentScene?.assetLayerPairs) {
    // Goes through all the layers in the scene and checks if they are text layers
    Array.from(currentScene.assetLayerPairs).forEach((assetLayerPair: any) => {
      assetLayerPair?.[1]?.forEach(
        (currentLayer: { uid: number | undefined; nm: string }) => {
          const assetId: string | undefined =
            assetLayerPair[0] === mainAssetKey ? undefined : assetLayerPair[0];
          const layers = findLayersOrLayerAssets({
            assetId,
            layerName: currentLayer.nm,
            jsonVid: currentScene?.jsonData,
            layerUid: currentLayer.uid,
          });
          if (!layers?.length) return;
          const layer = layers[0]; // Function now gets an array of layers with only one element
          const text: string = getPath(layer, COMMON_PATHS.TEXT_IN_LAYER); // If the layer has text
          const fontSize = getPath(layer, COMMON_PATHS.FONTSIZE_IN_TEXT_LAYER);
          const data = {
            layerData: layer,
            text,
            fontSize,
            asset: assetId,
            belongToCta: [] as { ctaName: string; id: string }[],
          };
          // If textLayer belongs to Cta
          dynamicMods.forEach((mod) => {
            if (
              allowedTypes.includes(mod.dataStr?.type) &&
              mod.layers.includes(currentLayer.nm)
            ) {
              const cta = { ctaName: mod.dataStr?.ctaName || "", id: mod.id };
              data.belongToCta.push(cta);
            }
          });
          if (text) {
            // If this layer is not connected to a dynamic connector
            if (
              !dynamicMods.some(
                (mod) =>
                  mod.layers.includes(currentLayer.nm) &&
                  (mod.dataStr?.type === "text" ||
                    mod.dataStr.type === "interactiveInput")
              )
            ) {
              const isAdditional = staticTextLayer.some((layer) => {
                return layer.additionals?.some((a) =>
                  a?.layerUid && currentLayer?.uid
                    ? a.layerUid === currentLayer?.uid
                    : a.layerName === currentLayer.nm
                );
              });
              if (!isAdditional) {
                const connectedText = staticTextLayer.find((a) =>
                  a?.layerUid && currentLayer?.uid
                    ? a.layerUid === currentLayer?.uid
                    : a.layerName === currentLayer.nm
                );
                const initialConnectedText = initialStaticTextLayer.find((a) =>
                  a?.layerUid && currentLayer?.uid
                    ? a.layerUid === currentLayer?.uid
                    : a.layerName === currentLayer.nm
                );
                connectedText || initialConnectedText
                  ? allStaticTextLayers.push({
                    ...data,
                    fontSize: connectedText?.fontSize || initialConnectedText?.fontSize || data.fontSize,
                    initialFontSize: initialConnectedText?.fontSize || data.fontSize,
                      additionals: connectedText?.additionals,
                      experimentId: connectedText?.experimentId,
                    ...(initialConnectedText && {
                        initialText: initialConnectedText.text.toString(),
                      }),
                      currentText:
                        connectedText?.text != null
                          ? connectedText.text.toString()
                          : initialConnectedText?.text != null
                          ? initialConnectedText.text.toString()
                          : "", //If connector has been deleted, get the initial one
                    })
                  : allStaticTextLayers.push(data);
              }
            } else {
              allDynamicTextLayers.push(data);
            }
          }
        }
      );
    });
    const mapping: Record<string, string> = {};
    allStaticTextLayers.forEach((staticText) => {
      staticText.belongToCta?.forEach((ctaObj) => {
        mapping[ctaObj.id] =
          staticText.initialText == null
            ? staticText.text
            : staticText.initialText;
      });
    });

    allDynamicTextLayers.forEach((dynamicText) => {
      dynamicText.belongToCta?.forEach((ctaObj) => {
        mapping[ctaObj.id] =
          dynamicText.initialText == null
            ? dynamicText.text
            : dynamicText.initialText;
      });
    });
    // Get all cta names
    dynamicMods.forEach((mod) => {
      if (allowedTypes.includes(mod.dataStr.type)) {
        let hasNoData = false;
        if (
          mod.dataStr?.expression == null &&
          mod.dataStr?.dataKey == null &&
          mod.dataStr?.liveControlKey == null &&
          mod.dataStr?.value == null
        ) {
          hasNoData = true;
        }
        allInteractiveLayers.push({
          id: mod.id,
          value: mod.dataStr?.value || (hasNoData ? "" : undefined),
          ctaName: mod.dataStr?.ctaName || "",
          initialValue: initialDynamicMods?.find(
            (initialMod) => initialMod.id === mod.id
          )?.dataStr?.value,
          initialCtaName: initialDynamicMods?.find(
            (initialMod) => initialMod.id === mod.id
          )?.dataStr?.ctaName,
          liveControlKey: mod.dataStr?.liveControlKey,
          perVideoKey: mod.dataStr?.dataKey,
          expression: mod.dataStr?.expression,
          type: mod.dataStr.type,
          text: mapping[mod.id],
          experimentId: mod.dataStr?.experimentId,
        });
      }
      if (mod.dataStr?.exposeToPlatform || mod.dataStr.type === "countdown") {
        allExposedToPlatformConnectors.push({
          id: mod.id,
          value: mod.dataStr?.value,
          localTime: mod.dataStr?.localTime,
          connectorName: mod.name || "",
          initialValue: initialDynamicMods?.find(
            (initialMod) => initialMod.id === mod.id
          )?.dataStr?.value,
          type: mod.dataStr.type,
          modPanelText:
            mod.dataStr.type === "asset"
              ? shortenTextAEStyle(
                  getOriginalNameOfAsset(mod.dataStr?.imageName, project.id)
                )
              : !mod.dataStr?.isLayers
              ? ""
              : getLayersTitle(mod.dataStr),
          experimentId: mod.dataStr?.experimentId,
        });
      }
    });
  }
  return {
    allStaticTextLayers,
    allDynamicTextLayers,
    allInteractiveLayers,
    allExposedToPlatformConnectors,
  };
};

function filterTextLayers(
  layers: EditVideoTypes[],
  filter: string
): EditVideoTextType[] {
  return layers
    .filter((layer): layer is EditVideoTextType => "text" in layer)
    .filter(
      (layer) =>
        layer.layerData.nm?.toLowerCase().trim().includes(filter) ||
        layer?.currentText?.toLowerCase().trim().includes(filter) ||
        layer.text?.toLowerCase().trim().includes(filter)
    )
    .sort((a, b) => {
      const textA = a.text?.toLowerCase().trim() || "";
      const textB = b.text?.toLowerCase().trim() || "";
      if (textA < textB) return -1;
      if (textA > textB) return 1;
      return 0;
    });
}

function filterInteractiveLayers(
  layers: EditVideoTypes[],
  filter: string
): EditVideoInteractiveType[] {
  return layers
    .filter((layer): layer is EditVideoInteractiveType => "ctaName" in layer)
    .filter((layer) => layer.ctaName?.toLowerCase().trim().includes(filter));
}
function filterExposedToPlatformConnectors(
  layers: EditVideoTypes[],
  filter: string
): EditVideoExposedToPlatformType[] {
  return layers
    .filter(
      (mod): mod is EditVideoExposedToPlatformType => "connectorName" in mod
    )
    .filter((mod) => mod.connectorName?.toLowerCase().trim().includes(filter))
    .sort((a, b) => a.connectorName.localeCompare(b.connectorName));
}

export const filterLayersFromSceneHelper = (
  layersAndMods: LayersAndModsPerScene[],
  sceneName: string,
  filter: string
): AllLayersAndModsTypes => {
  const res: AllLayersAndModsTypes = {
    allDynamicTextLayers: [],
    allStaticTextLayers: [],
    allInteractiveMods: [],
    allExposedToPlatformConnectors: [],
  };

  const targetScene = layersAndMods.find(
    (layerOrMod) => layerOrMod.scene === sceneName
  );
  if (!targetScene) return res;

  for (const type of Object.keys(targetScene).filter(
    (key) => key !== "scene"
  ) as (keyof AllLayersAndModsTypes)[]) {
    const layers = targetScene[type];
    let filtered: any[] = []; // using any[] for the general case, can be made more specific

    switch (type) {
      case "allInteractiveMods":
        filtered = filterInteractiveLayers(layers, filter);
        break;
      case "allExposedToPlatformConnectors":
        filtered = filterExposedToPlatformConnectors(layers, filter);
        break;
      default:
        filtered = filterTextLayers(layers, filter);
    }
    if (filtered.length) res[type] = filtered;
  }

  return res;
};
