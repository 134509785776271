import { types, Instance } from "mobx-state-tree";
import config from "../aws-exports";
import config2 from "../aws-conf";
// import API from "@aws-amplify/api";
// import PubSub from "@aws-amplify/pubsub";
// import Amplify from "aws-amplify";
// import AWS from 'aws-sdk'
// import AWS from 'aws-amplify/node_modules/aws-sdk';

const {
  aws_content_delivery_bucket,
  aws_content_delivery_bucket_region,
  aws_content_delivery_url,
  ...baseWithoutContentDelivery
} = config;

const amplifyConfWithoutContentDelivery = {
  ...baseWithoutContentDelivery,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
};
const amplifyConf = amplifyConfWithoutContentDelivery;

export const configModel = types.model("configStore", {
  amplify: types.model({
    aws_project_region: types.string,
    aws_cognito_identity_pool_id: types.string,
    aws_cognito_region: types.string,
    aws_user_pools_id: types.string,
    aws_user_pools_web_client_id: types.string,
    oauth: types.model({}),
    aws_appsync_graphqlEndpoint: types.string,
    aws_appsync_region: types.string,
    aws_appsync_authenticationType: types.string,
    aws_cloud_logic_custom: types.array(
      types.model({
        name: types.string,
        endpoint: types.string,
        region: types.string,
      })
    ),
    aws_user_files_s3_bucket: types.string,
    aws_user_files_s3_bucket_region: types.string,
  }),
});

export default configModel.create({ amplify: amplifyConf });
export type IconfigModel = Instance<typeof configModel>;
