import React from "react";

interface HeatmeterProps {
  value: number; // Value should be between 0 and 1
  dir?: "left" | "right"; // Direction of the gradient
  style?: React.CSSProperties;
}

export default function HeatMeter({ value, dir, style }: HeatmeterProps) {
  // Ensure value is clamped between 0 and 1
  const clampedValue = Math.max(0, Math.min(value, 1)) * 100;

  // Style for the heatmeter bar
  const barStyle: React.CSSProperties = {
    width: "100%",
    height: "20px",
    backgroundImage: `linear-gradient(to ${
      dir ? dir : "right"
    }, #AB7AE0 ${clampedValue}%, #E1E2E9 ${clampedValue}%)`,
    opacity: 1,
    borderRadius: "25px",
    position: "relative",
    overflow: "hidden", // Ensure the rounded end does not overflow
  };

  const finalStyle = {
    ...barStyle,
    ...style,
  };

  // Style for the blue rounded end
  const blueEndStyle: React.CSSProperties = {
    position: "absolute",
    height: "100%",
    width: "20px", // Adjusted width to match the bar's border radius
    backgroundColor: "#AB7AE0",
    borderRadius: "25px",
    left:
      dir === "left"
        ? `calc(${100 - clampedValue}% - 10px)`
        : `calc(${clampedValue}% - 10px)`,
  };

  return (
    <div className="heatmap" style={finalStyle}>
      {clampedValue > 0 && clampedValue < 100 && (
        <div style={blueEndStyle}></div>
      )}
    </div>
  );
}
