import { UploadFile } from "antd";

export async function convertFileToBase64(
  file: File | UploadFile<any>,
  withMetadata = true
): Promise<string> {
  const reader = new FileReader();
  reader.readAsDataURL(file as File);
  const base64 = await new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
  if (withMetadata) return base64 as string;
  return (base64 as string).split(",")[1];
}
