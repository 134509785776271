import { BlingsBtn } from "../../components/antd-extensions/blings-btn.component";
import { AsyncOpState } from "../../types/enums/async-op-states";

type SaveEditVideoComponentProps = {
  disabled: boolean;
  onclick: () => void;
  saveModsStatus: AsyncOpState;
};

const SaveEditVideoComponent = ({
  disabled,
  onclick,
  saveModsStatus,
}: SaveEditVideoComponentProps) => {
  const saveButtonTexts = {
    [AsyncOpState.Success]: "Save",
  };
  return (
    <BlingsBtn
      opState={saveModsStatus}
      htmlType={"submit"}
      btnTexts={saveButtonTexts}
      onClick={onclick}
      disabled={disabled}
    />
  );
};

export default SaveEditVideoComponent;
