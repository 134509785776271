import { Chart } from "react-chartjs-2";
import { Chart as ChartJs } from "chart.js";
import { SessionsPerCountryProps } from "../Analytics";
import * as ChartGeo from "chartjs-chart-geo";
import countryCodeToData from "../../../utils/countryCodeToData";
// @ts-ignore
import world from "world-atlas/countries-50m";
import { Table } from "antd";

const countries = ChartGeo.topojson.feature(
  world,
  world.objects.countries
  // @ts-ignore
).features;
ChartJs.register(
  ChartGeo.BubbleMapController,
  ChartGeo.ProjectionScale,
  ChartGeo.SizeScale,
  ChartGeo.GeoFeature
);
export const SessionsPerCountry = ({
  data,
}: {
  data?: SessionsPerCountryProps;
}) => {
  const totalSessions =
    data?.reduce((sum, curr) => sum + curr.doc_count, 0) || 1;
  // map the original data to include a percentage field
  const enhancedData = data?.map((item) => ({
    ...item,
    percentage: ((item.doc_count / totalSessions) * 100).toFixed(2),
  }));
  const columns = [
    {
      title: "Country",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Sessions",
      dataIndex: "doc_count",
      key: "doc_count",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
      render: (_: any, record: any) => {
        return `${record.percentage as number}%`;
      },
    },
  ];
  return data && enhancedData ? (
    <div className="map">
      <h2>Views per country</h2>
      <div className="country-view">
        <div className="tableView">
          <Table
            columns={columns}
            dataSource={enhancedData}
            scroll={{ y: 500 }}
          />
        </div>
        <div className="mapView">
          <Chart
            type="bubbleMap"
            options={{
              showOutline: true,
              showGraticule: false,
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                xy: {
                  projection: "equalEarth",
                },
              },
            }}
            data={{
              labels: data.map((d) => countryCodeToData[d.key].country),
              datasets: [
                {
                  label: "Countries",
                  outline: countries,
                  showOutline: true,
                  backgroundColor: ["rgba(255, 89, 122, 0.6)"],
                  data: data.map((d) => ({
                    feature: countryCodeToData[d.key].country,
                    latitude: countryCodeToData[d.key].latitude,
                    longitude: countryCodeToData[d.key].longitude,
                    value: d.doc_count,
                  })),
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  ) : null;
};
