import { types } from "mobx-state-tree";
import { AsyncOpState } from "../../types/enums/async-op-states";

const asyncOpStateEnumType = types.enumeration([
  AsyncOpState.Untouched,
  AsyncOpState.Changed,
  AsyncOpState.Saving,
  AsyncOpState.Success,
  AsyncOpState.Error,
]);

export const INITIAL_ASYNC_OP_STATE = AsyncOpState.Changed; // todo: changes to untouched after fixing settings form UI behaviour
export const asyncOpStateType = types.optional(
  asyncOpStateEnumType,
  INITIAL_ASYNC_OP_STATE
);
