import { IProjectModel } from "../../stores/project/projectModel";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { PATHS, PROJECT_PATHS, toPath } from "../../PATHS";
import { useEffect, useRef, useState } from "react";
import { useMst } from "../../stores/Root";
import "./SceneView.scss";
import { Menu, MenuProps } from "antd";
type Props = {
  project: IProjectModel;
  selectedScene: string | undefined;
};
type MenuItem = Required<MenuProps>["items"][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const SceneView = observer(({ project, selectedScene }: Props) => {
  const history = useNavigate();

  const { setSearchView, setCurrentSearch, searchView, currentSearch } = useMst(
    (store) => {
      const { editVideoStore } = store;
      return {
        setSearchView: editVideoStore.setSearchView,
        setCurrentSearch: editVideoStore.setCurrentSearch,
        searchView: editVideoStore.searchView,
        currentSearch: editVideoStore.currentSearch,
      };
    }
  );
  const [videoPartNames, setVideoPartNames] = useState<string[] | undefined>(
    []
  );
  const onClick: MenuProps["onClick"] = (e) => {
    setSearchView(false);
    history(toPath(PATHS.project, project.id, PROJECT_PATHS.editVideo, e.key));
  };

  const handleOnAllScenes = () => {
    setSearchView(true);
    setCurrentSearch("");
    history(toPath(PATHS.project, project.id, PROJECT_PATHS.editVideo));
  };
  useEffect(() => {
    const currentVideoParts = project.workspaceVideoParts?.map((vp) => vp.name);
    setVideoPartNames(currentVideoParts);
  }, [project.workspaceVideoParts.length, selectedScene, project]);

  const items: MenuItem[] = [
    getItem(
      "All Scenes",
      "sub1",
      undefined,
      videoPartNames?.map((vp) => getItem(vp, vp))
    ),
  ];
  return (
    <div
      className={[
        "scene-view",
        currentSearch === "" && searchView ? "all-scenes" : "",
      ].join(" ")}
    >
      <Menu
        mode="inline"
        openKeys={["sub1"]}
        onClick={onClick}
        onOpenChange={handleOnAllScenes}
        expandIcon={() => <div></div>}
        selectedKeys={[selectedScene || ""]}
        items={items}
      />
    </div>
  );
});
export default SceneView;
