import { BarTooltipProps } from "@nivo/bar";
import { PointTooltipProps } from "@nivo/line";
import { PieTooltipProps } from "@nivo/pie";
import { EventType } from "./Funnel";
import { EventsPerInteractive } from "../Analytics";
export function TooltipBars({
  data,
}: BarTooltipProps<{
  x: string;
  y: number;
}>) {
  return (
    <div
      style={{
        backgroundColor: "var(--blings_teal_80)",
        color: "white",
        padding: ".5rem",
        borderRadius: "5px",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {new Date(data.x).toLocaleDateString(navigator.language, {
        month: "short",
        day: "numeric",
      })}
      : {data.y}
      <div
        style={{
          backgroundColor: "var(--blings_teal_80)",
          position: "absolute",
          top: "75%",
          transform: "rotate(45deg)",
          width: "1rem",
          height: "1rem",
        }}
      ></div>
    </div>
  );
}
export function TooltipLineNoFormat({ point }: PointTooltipProps) {
  return (
    <div
      style={{
        backgroundColor: "var(--blings_teal_80)",
        color: "white",
        padding: ".5rem",
        borderRadius: "5px",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <div style={{ backgroundColor: "white", width: "1rem", height: "1rem", border: "1px solid white", marginRight: "0.5rem" }}><div style={{ backgroundColor: point.color, height: "100%", width: "100%" }}></div></div> */}
      {point.data.xFormatted}: {point.data.y}
      <div
        style={{
          backgroundColor: "var(--blings_teal_80)",
          position: "absolute",
          top: "75%",
          transform: "rotate(45deg)",
          width: "1rem",
          height: "1rem",
        }}
      ></div>
    </div>
  );
}

export function TooltipPie({
  datum,
}: PieTooltipProps<{
  id: string;
  value: number;
  percentage: string;
}>) {
  return (
    <div
      style={{
        backgroundColor: "var(--blings_teal_80)",
        color: "white",
        padding: ".5rem",
        borderRadius: "5px",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "1rem",
          height: "1rem",
          border: "1px solid white",
          marginRight: "0.5rem",
        }}
      >
        <div
          style={{
            backgroundColor: datum.color,
            height: "100%",
            width: "100%",
          }}
        ></div>
      </div>
      {datum.data.id}: {`${datum.data.value} (${datum.data.percentage}%)`}
      <div
        style={{
          backgroundColor: "var(--blings_teal_80)",
          position: "absolute",
          top: "75%",
          transform: "rotate(45deg)",
          width: "1rem",
          height: "1rem",
        }}
      ></div>
    </div>
  );
}

export function TooltipFunnel({
  data,
}: BarTooltipProps<{
  key: string;
  events: number;
  type: EventType;
  percentage: number;
  ctaDetail?: string;
}>) {
  return (
    <div
      style={{
        backgroundColor: "var(--blings_teal_80)",
        color: "white",
        padding: ".5rem",
        borderRadius: "5px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 100,
      }}
    >
      {data.type === "interactive" ? (
        <table>
          <tbody>
            <tr>
              <td>
                <b>
                  Interactions: {data.key.substring(0, data.key.length - 13)}
                </b>
              </td>
            </tr>
            <tr>
              <td
                style={{ paddingBottom: "7px" }}
              >{`${data.events.toLocaleString()} sessions`}</td>
            </tr>
            {data.ctaDetail
              ? JSON.parse(data.ctaDetail).map((cta: EventsPerInteractive) => {
                  return (
                    <tr>
                      <td>{cta.key}</td>
                      <td>{cta.doc_count} clicks</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      ) : (
        <table>
          <tbody>
            <tr>
              <td>
                <b>Scene: {data.key}</b>
              </td>
            </tr>
            <tr>
              <td>{data.events.toLocaleString()} sessions</td>
            </tr>
          </tbody>
        </table>
      )}
      <div
        style={{
          backgroundColor: "var(--blings_teal_80)",
          position: "absolute",
          bottom: "-0.5rem",
          transform: "rotate(45deg)",
          width: "1rem",
          height: "1rem",
        }}
      ></div>
    </div>
  );
}

export function TooltipDynamicThumbnail({
  data,
}: BarTooltipProps<{
  x: string;
  y: number;
}>) {
  const date = new Date(data.x);
  const dateFormatted = `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()}`;
  return (
    <div
      style={{
        backgroundColor: "var(--blings_teal_80)",
        color: "white",
        padding: ".5rem",
        borderRadius: "5px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span>
        <b>{dateFormatted}: </b> <span>{data.y}</span>
      </span>

      <div
        style={{
          backgroundColor: "var(--blings_teal_80)",
          position: "absolute",
          bottom: "-0.5rem",
          transform: "rotate(45deg)",
          width: "1rem",
          height: "1rem",
        }}
      ></div>
    </div>
  );
}
