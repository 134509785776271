import { Card } from "antd";
import "./MediaContent.scss";
export const IMAGE = "image";
export const AUDIO = "audio";
export const VIDEO = "video";
const MediaContent = (props: any) => {
  const { src, format } = props;
  return (
    <Card className={format === AUDIO ? "AudioCard" : "MediaCard"}>
      {(format === IMAGE && (
        <img alt="preview" src={src} className={"Image"} />
      )) ||
        (format === VIDEO && (
          <video controls src={src} className={"Video"} />
        )) ||
        (format === AUDIO && <audio src={src} controls className={"Audio"} />)}
    </Card>
  );
};
export default MediaContent;
