import { applySnapshot, getSnapshot } from "mobx-state-tree";

export const resetStore = (self: any) => {
  let initialState: any;
  return {
    afterCreate() {
      initialState = getSnapshot(self);
    },
    resetStore() {
      applySnapshot(self, initialState);
    },
  };
};
