import { IJSONObject, JSONArray, JSONValue } from "@blings/blings-player";
// @ts-ignore
import GenerateSchema from "generate-schema";
import { JSONSchema6, JSONSchema7 } from "json-schema";
import { get } from "lodash";

/*  duplicate of: playground\src\helpers\jsonShema.helpers.ts  */

export const traverse = (
  obj: { [key: string]: any },
  cb: (item: any, path: Array<string>) => void,
  path: Array<string> = []
) => {
  for (const k in obj) {
    const p = [...path, k];
    if (obj[k] && obj[k].type === "object") {
      traverse(obj[k].properties, cb, p);
    } else {
      cb(obj[k], p);
    }
  }
};

export function jsonSchemaGetExamples(jsonSchema: any) {
  if (jsonSchema.examples && jsonSchema.examples.length) {
    return jsonSchema.examples[0];
  }

  if (jsonSchema.properties) {
    const examples: any = {};
    for (const key in jsonSchema.properties) {
      if (!jsonSchema.properties.hasOwnProperty(key)) {
        return;
      }
      if (
        jsonSchema.properties[key].examples &&
        jsonSchema.properties[key].examples.length
      ) {
        examples[key] = jsonSchema.properties[key].examples[0];
      } else if (jsonSchema.properties[key].default) {
        examples[key] = jsonSchema.properties[key].default;
      } else if (jsonSchema.properties[key].type) {
        switch (jsonSchema.properties[key].type) {
          case "string": {
            examples[key] = "string";
            break;
          }
          case "boolean": {
            examples[key] = true;
            break;
          }
          case "number": {
            examples[key] = 1;
            break;
          }
          case "array": {
            examples[key] = 1;
            break;
          }
          default: {
            examples[key] = "";
            break;
          }
        }
      } else {
        examples[key] = "";
      }
    }
    return examples;
  }

  return {};
}

export enum InternalSchemaTypes {
  TEXT = "text",
  COLOR = "color",
  LINK = "link",
  IMAGE = "image",
  VIDEO = "video",
  AUDIO = "audio",
  BOOLEAN = "boolean",
  NUMBER = "number",
}

const updatePropFormat = (prop: any) => {
  const example = prop.examples[0];
  if (typeof example === "object") {
    Object.values(prop.items.properties).forEach((v) => updatePropFormat(v));
    return;
  }
  const fileExt =
    typeof example === "string" && example.split(".").pop()?.toLowerCase();
  let format;

  if (fileExt && /(gif|jpe?g|tiff?|png|webp|bmp)$/.test(fileExt)) {
    format = InternalSchemaTypes.IMAGE;
  } else if (fileExt && /(mov|avi|wmv|flv|3gp|mp4|mpg)$/.test(fileExt)) {
    format = InternalSchemaTypes.VIDEO;
  } else if (fileExt && /(mp3|m4a|flac|wav|wma|aac)$/.test(fileExt)) {
    format = InternalSchemaTypes.AUDIO;
  } else if (example && /(^http|^www)/.test(example)) {
    format = InternalSchemaTypes.LINK;
  } else if (example && /^#(?:[0-9a-fA-F]{3,4}){1,2}$/.test(example)) {
    format = InternalSchemaTypes.COLOR;
  } else if (example && /^[0-9]*$/.test(example)) {
    format = InternalSchemaTypes.NUMBER;
  } else if (typeof example === "boolean") {
    format = InternalSchemaTypes.BOOLEAN;
  } else {
    format = InternalSchemaTypes.TEXT;
  }
  if (prop.type === "array") {
    prop.items.format = format;
  } else {
    prop.format = format;
  }
};

type Examples = { [key: string]: any }[];

const addExampleToProp = (props: any, examples: Examples) => {
  for (const k in props) {
    if (props[k] && props[k].type === "object") {
      props[k].examples = [examples[k as any] ? examples[k as any] : {}];
      addExampleToProp(props[k].properties, examples[k as any] as Examples);
    } else {
      props[k].examples = [examples[k as any] ? examples[k as any] : ""];
      if (props[k] && props[k].type === "array") {
        if (!props[k].items.type) {
          props[k].items.type = "string";
        } else if (props[k].items.type === "object") {
          addExampleToProp(
            props[k].items.properties,
            examples[k as any][0] as Examples
          );
        }
      }
    }
  }
};

export function createSchemaFromData(data: Record<string, unknown>) {
  const schema = GenerateSchema.json(data);
  schema.examples = [data];
  const { properties, examples } = schema;
  addExampleToProp(properties, examples[0]);
  traverse(properties, updatePropFormat);
  schema.$schema = "http://json-schema.org/draft-07/schema";
  return schema;
}

export function fillSchemaExamplesWithData(schema: JSONSchema6, formData: any) {
  setExamples(schema, formData);
  function setExamples(schema: any, formData: any) {
    schema.examples = [formData];
    if (schema.type === "object" && schema.properties) {
      Object.keys(schema.properties).forEach((key) => {
        if (!formData[key] && schema.properties[key].oneOf) {
          formData[key] = schema.properties[key].oneOf[0].const;
        }
        setExamples(schema.properties[key], formData[key]);
      });
    }
  }
}
export const emptySchema = {
  $schema: "http://json-schema.org/draft-07/schema",
  $id: "http://example.com/example.json",
  type: "object",
  title: "",
  description: "",
  default: {},
  examples: [],
  required: [],
  properties: {},
  additionalProperties: true,
};

// gets a json schema and flattens it to a list of properties
export function flattenSchema(schema: JSONSchema6) {
  const properties: { [key: string]: JSONValue } = {};
  traverse(schema.properties || {}, (prop, path) => {
    if (prop) {
      properties[path.join(".")] = schema.examples?.length
        ? get(schema.examples[0], path)
        : prop.default;
    }
  });
  return properties;
}

export function filterObjectBySchema(
  obj: IJSONObject,
  schema: JSONSchema7
): IJSONObject {
  const newObj: IJSONObject = {};
  let newArray: JSONArray = [];

  if (Array.isArray(obj)) {
    if (schema.items && typeof schema.items === "object") {
      newArray = obj.map((item: JSONValue) => {
        if (typeof item === "object" && item !== null) {
          return filterObjectBySchema(
            item as IJSONObject,
            schema.items as JSONSchema7
          );
        } else {
          return item;
        }
      });
    }
    return newArray as unknown as IJSONObject;
  } else {
    const schemaProperties = schema.properties;
    if (schemaProperties) {
      for (const key in obj) {
        if (
          obj[key] !== null &&
          typeof obj[key] === "object" &&
          schemaProperties.hasOwnProperty(key)
        ) {
          newObj[key] = filterObjectBySchema(
            obj[key] as IJSONObject,
            schemaProperties[key] as JSONSchema7
          );
        } else if (schemaProperties.hasOwnProperty(key)) {
          newObj[key] = obj[key];
        }
      }
    }
    return newObj;
  }
}
