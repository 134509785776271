/* eslint-disable react/jsx-no-comment-textnodes */

import { Button, Card } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { copyToClipboard } from "../../helpers/CopyToClipboard";
import { jsonSchemaGetExamples } from "../../helpers/jsonSchema.helpers";
import { IProjectModel } from "../../stores/project/projectModel";
import { getENV } from "../../config";
import { BlingsButton } from "../../components/BlingsButton/BlingsButton";
import { saveAs } from "file-saver";

type Props = {
  project: IProjectModel;
  sdkScript: string;
};

export const SDKInstructions = ({ project, sdkScript }: Props) => {
  const data = JSON.stringify(
    jsonSchemaGetExamples(JSON.parse(project.stateJsonSchemaStr || "{}")),
    null,
    4
  );
  const { playerSettings } = project;
  const scenes = !project.videoPartNames
    ? '""'
    : JSON.stringify(
        project.videoPartNames?.map((vp) => vp || ""),
        null,
        4
      );

  const generateDynamicHTML = () => {
    // Create your dynamic HTML content here
    const dynamicHTML =
      `<!DOCTYPE html><html><head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>${project.title}</title>
      <link rel="icon" type="image/x-icon" href="https://assets.blings.io/projects/demo/favicon.64c6aba1.ico" />
      <style>
          .demos-body {
              background: #000000;
          }
  
          .vid-holder-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
          }
  
          .vid-holder-inner-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
          }
  
          .BlingsPlayer {
              background: #00000000;
          }
      </style>
      ${sdkScript}
  </head>
    <body class="demos-body">
        <div class="vid-holder-wrapper">
            <div id="video-container"></div>
        </div>
    <script>
    var scenes = ${scenes}
    var data = ${data};
    var settings =   {container: document.getElementById("video-container"), 
            cinematic: true  ${
              playerSettings?.loadingImage
                ? `,
`
                : ""
            }
            ${
              playerSettings?.loadingImage
                ? `loadingImage: "${playerSettings.loadingImage}"`
                : ""
            }
            ${
              playerSettings?.loadingText
                ? `,
`
                : ""
            }
            ${
              playerSettings?.loadingText
                ? `loadingText: "${playerSettings.loadingText}"`
                : ""
            }
        }

    
    BlingsPlayer.create({
      project: { id: "${project.id}"` +
      (getENV() === "dev" ? `, env: "dev"` : ``) +
      ` },${data !== "{\n\t\n}" ? "\n    data," : ""}
      settings,
      scenes
    });
    </script>
    </body>
  </html>`;
    return dynamicHTML;
  };

  const handleDownload = () => {
    const dynamicHTML = generateDynamicHTML();
    const blob = new Blob([dynamicHTML], { type: "text/html" });
    saveAs(blob, `${project.title}.html`);
  };

  return (
    <>
      <Card
        type={"inner"}
        style={{
          background: "#d9d9d9",
          width: "75%",
          minWidth: "43em",
          overflow: "auto",
        }}
      >
        <pre
          className="language-jsx"
          style={{
            background: "#d9d9d9",
            marginBottom: "1%",
            marginTop: "1%",
          }}
        >
          <code>
            <p id="code2" style={{ fontSize: "0.9em" }}>
              {`var settings = {
  `}
              <span className={"code-comment"}>
                // Blings video will replace an HTML element on screen
              </span>
              {`
  container: document.getElementById("YOUR-HTML-ELEMENT"), 
  `}
              <span className={"code-comment"}>
                // This option creates a full-screen video over black background
              </span>
              {`
  cinematic: true`}
              {playerSettings?.loadingImage
                ? `,
  `
                : ""}
              {playerSettings?.loadingImage
                ? `loadingImage: "${playerSettings.loadingImage}"`
                : ""}
              {playerSettings?.loadingText
                ? `,
  `
                : ""}
              {playerSettings?.loadingText
                ? `loadingText: "${playerSettings.loadingText}"`
                : ""}
              {`
};

`}
              {data !== "{\n\t\n}" ? (
                <>
                  <span className={"code-comment"}>
                    {`// This is the data that will be used in the video. 
// You can gather the data from any resource or API in real time
// and replace the values listed below. 
// This data is never exposed to Blings.io!`}
                  </span>
                  {`\nvar data = ${data};\n\n`}
                </>
              ) : (
                ""
              )}
              <span className={"code-comment"}>
                // Define the scenes from which the video will be created
              </span>
              {`
var scenes = ${scenes}

`}
              <span className={"code-comment"}>
                // Finally create the video
              </span>
              {`
BlingsPlayer.create({
    project: { id: "${project.id}"` +
                (getENV() === "dev" ? `, env: "dev"` : ``) +
                ` },${data !== "{\n\t\n}" ? "\n    data," : ""}
    settings,
    scenes
});`}
            </p>
          </code>
        </pre>
        <Button
          type="primary"
          icon={<CopyOutlined />}
          style={{ float: "right" }}
          onClick={() => {
            copyToClipboard("code2");
          }}
        />
      </Card>
      <div style={{ marginTop: "1rem" }}>
        <BlingsButton
          onClick={() => {
            handleDownload();
          }}
        >
          <>Download HTML</>
        </BlingsButton>
      </div>
    </>
  );
};
