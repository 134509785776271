import { expand } from "@blings/blings-player";

const jsonDataMemo: { [key: string]: string } = {};

export async function getJsonDataFromUrl(jsonUrl: string) {
  if (jsonDataMemo[jsonUrl]) {
    return jsonDataMemo[jsonUrl];
  }

  const raw = await fetch(jsonUrl);
  const jvid = await raw.json();
  const jsonExpanded = expand(jvid);
  jsonDataMemo[jsonUrl] = jsonExpanded;

  return jsonExpanded;
}
