import {
  AudioIcon,
  CalendarIcon,
  ColorIcon,
  DynamicSceneIcon,
  ExpressionIcon,
  FormInteractiveIcon,
  GoToFrameIcon,
  ImageIcon,
  LinkIcon,
  MediaIcon,
} from "../../assets/Icons";
import {
  EditVideoExposedToPlatformType,
  EditVideoInteractiveType,
  EditVideoTextType,
  EditVideoTypes,
} from "../../types/EditVideoTypes";

export const renderIcon = (
  panel?: any,
  mod?: EditVideoInteractiveType | EditVideoExposedToPlatformType
) => {
  const layer = panel?.children?.props?.layer || mod;
  if (!layer || !("id" in layer)) {
    return <div></div>;
  } else {
    switch (layer.type) {
      case "interactiveOpen":
        return <LinkIcon />;
      case "interactiveJSExpression":
        return <ExpressionIcon />;
      case "interactiveConcat":
        return <DynamicSceneIcon />;
      case "interactiveGoto":
        return <GoToFrameIcon />;
      case "interactiveForm":
        return <FormInteractiveIcon />;
      case "themeColor":
        return <ColorIcon />; // Assuming GoToFrameIcon is correct for 'color'
      case "countdown":
        return <CalendarIcon />;
      case "asset":
        if (getUrlType(layer.value) === "audio") {
          return <AudioIcon />;
        } else if (getUrlType(layer.value) === "image") {
          return <ImageIcon />;
        } else {
          return <MediaIcon />;
        }
      default:
        return; // Equivalent to returning 'undefined'
    }
  }
};
export function isTextLayer(
  layerOrMod: EditVideoTypes
): layerOrMod is EditVideoTextType {
  return "layerData" in layerOrMod;
}
export function isInteractiveMod(
  layerOrMod: EditVideoTypes
): layerOrMod is EditVideoInteractiveType {
  return "ctaName" in layerOrMod;
}
export function isExposedToPlatformMod(
  layerOrMod: EditVideoTypes
): layerOrMod is EditVideoExposedToPlatformType {
  return "connectorName" in layerOrMod;
}
export const renderTypeName = (
  layer: EditVideoExposedToPlatformType | EditVideoInteractiveType
) => {
  const type = layer.type;
  switch (type) {
    case "interactiveOpen":
      return "Link";
    case "interactiveJSExpression":
      return "Js Expression";
    case "interactiveConcat":
      return "Dynamic Scenes";
    case "interactiveGoto":
      return "Jump to Frame";
    case "interactiveForm":
      return "Submit Form";
    case "themeColor":
      return "Color";
    case "countdown":
      return "Countdown";
    case "asset":
      if (getUrlType(layer.value) === "audio") {
        return "Audio";
      } else if (getUrlType(layer.value) === "image") {
        return "Image";
      } else if (getUrlType(layer.value) === "video") {
        return "Video";
      } else {
        return "Media";
      }
  }
};

type PanelKey = {
  type: "interactiveType" | "exposedToPlatformType" | "textType";
  sceneName?: string;
  id?: string;
  layerName?: string;
  asset?: string;
  layerUid?: number;
  belongToCta?: string;
  connectorName?: string;
};

// Method to convert the layer and scene data to a uniform key for every panel
export const panelKey = ({
  id,
  layerName,
  asset,
  sceneName,
  layerUid,
  type,
}: PanelKey) => {
  if (id) {
    return JSON.stringify({ type, id, sceneName }); // by id if its a mod
  } else {
    // unique by following params if its a layer
    return JSON.stringify({
      type,
      layerName,
      asset,
      sceneName,
      layerUid: layerUid,
    });
  }
};

export function shortenTextAEStyle(text: string, maxChars = 25) {
  const textTrimmed = text.trim();
  const textL = textTrimmed.length;
  if (textL < maxChars) {
    return textTrimmed;
  }
  const mid = " ... ";
  const midL = mid.length;
  const startL = Math.ceil((maxChars - midL) / 2);
  const endL = textL - Math.floor((maxChars - midL) / 2);
  return textTrimmed.slice(0, startL) + mid + textTrimmed.slice(endL);
}

export const getOriginalNameOfAsset = (fullName: string, pid: string) => {
  if (fullName.includes(`projects/${pid}/assets`)) {
    const fileName = fullName.split("/").pop(); // remove entire folder structure
    if (fileName) {
      const assetName = fileName.split("_").slice(1).join("_");
      if (assetName) return assetName;
    }
  }
  return fullName;
};

export const getLayersTitle = (datastr: any) => {
  if (datastr.additionals && datastr.additionals.length) {
    return `${datastr.layerName as string} (+${
      datastr.additionals.length as string
    })`;
  }
  return datastr?.layerName || "Color";
};

export function getUrlType(url: string | undefined) {
  try {
    if (!url) return undefined;
    new URL(url);

    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"];
    const audioExtensions = ["mp3", "wav", "ogg", "m4a"];
    const videoExtensions = ["mp4", "avi", "mov", "wmv", "flv", "webm"];

    const extension = url?.split(".")?.pop()?.split(/\#|\?/)[0] || "";

    if (imageExtensions.includes(extension)) {
      return "image";
    } else if (audioExtensions.includes(extension)) {
      return "audio";
    } else if (videoExtensions.includes(extension)) {
      return "video";
    } else {
      return undefined;
    }
  } catch (e) {
    // If URL parsing fails, it's not a valid URL
    return undefined;
  }
}
