import { types } from "mobx-state-tree";

export const editVideoModel = types
  .model({
    searchView: types.optional(types.boolean, false),
    currentSearch: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setSearchView(searchView: boolean) {
      self.searchView = searchView;
    },
    setCurrentSearch(searchString: string) {
      self.currentSearch = searchString;
    },
  }));
