import {
  CreateProjectInput,
  Project,
  ProjectVersion,
  UpdateProjectVersionInput,
  UpdateProjectInput,
  FormDataByDateQuery,
  DynamicThumbnail,
  ModelSortDirection,
  DynamicThumbnailInput,
  ExperimentOptimizationTechnique,
} from "../API";
import { generateClient } from "aws-amplify/api";
import {
  createProject,
  createProjectVersion,
  deleteProject,
  deleteProjectVersion,
  updateProject,
  updateProjectVersion,
} from "../graphql/mutations";
import { insertIntoGraphQlString } from "modifygraphqlstring";
import {
  getProject,
  getProjectVersion,
  formDataByDate,
} from "../graphql/queries";
import { ProjectBasicInfo } from "../stores/project/projectsModel";

// Graphql Client
const client = generateClient();

type ProjectMinimalInfo = {
  title: string;
  id: string;
  updatedAt: string;
  createdAt: string;
};
export async function createVersion(project: Project | ProjectVersion) {
  const input = {
    id: project.id,
    accountOwner: isProjectVersion(project)
      ? project.accountOwner
      : project.account.id,
    settings: project.settings,
    settingsJsonSchemaStr: project.settingsJsonSchemaStr,
    stateJsonSchemaStr: project.stateJsonSchemaStr,
    videoParts: project.videoParts?.map((vp) => {
      const { __typename, ...rest } = vp;
      return rest;
    }),
  };
  const {
    data: { createProjectVersion: projectVersion },
  } = (await client.graphql({
    query: createProjectVersion,
    variables: { input },
  })) as { data: { createProjectVersion: ProjectVersion } };
  return projectVersion;
}

export async function createPublished(cpi: CreateProjectInput) {
  const {
    data: { createProject: project },
  } = (await client.graphql({
    query: createProject,
    variables: { input: cpi },
  })) as { data: { createProject: Project } };
  return project;
}

export async function updateVersion(project: UpdateProjectVersionInput) {
  const input = {
    id: project.id,
    experiments: project.experiments,
    ...(project?.settings && { settings: project.settings }),
    ...(project?.settingsJsonSchemaStr && {
      settingsJsonSchemaStr: project.settingsJsonSchemaStr,
    }),
    ...(project?.stateJsonSchemaStr && {
      stateJsonSchemaStr: project.stateJsonSchemaStr,
    }),
    ...(project?.videoParts && { videoParts: project.videoParts }),
  };
  const modifiedUpdateProject = insertIntoGraphQlString(
    insertIntoGraphQlString(updateProjectVersion, {
      path: ["videoParts"],
      key: "modsArr",
      value: {
        id: true,
        dataStr: true,
        origin: true,
        name: true,
      },
    }),
    {
      path: ["videoParts"],
      key: "fonts",
      value: {
        family: true,
        style: true,
        url: true,
        weight: true,
      },
    }
  );
  return (await client.graphql({
    query: modifiedUpdateProject,
    variables: { input },
  })) as { data: ProjectVersion };
}

export const savePublished = async (cpi: UpdateProjectInput) => {
  await client.graphql({
    query: updateProject,
    variables: { input: cpi },
  });
};

export const deleteProjectVersionAndPublished = async (id: string) => {
  const deleteProjectVersionGraphQL = `mutation DeleteProjectVersion(
  $input: DeleteProjectVersionInput!
  $condition: ModelProjectVersionConditionInput
) {
  deleteProjectVersion(input: $input, condition: $condition) {
    id
  }
}
`;
  await client.graphql({
    query: deleteProjectVersionGraphQL,
    variables: { input: { id } },
  });
  await client.graphql({
    query: deleteProject,
    variables: { input: { id } },
  });
};
export async function publishProject(projectVersion: UpdateProjectInput) {
  const {
    id,
    settings,
    settingsJsonSchemaStr,
    stateJsonSchemaStr,
    playerVersionToUse,
    videoParts,
    experiments
  } = projectVersion;
  const input = {
    id,
    settings,
    experiments,
    playerVersionToUse,
    settingsJsonSchemaStr,
    stateJsonSchemaStr,
    videoParts,
  };

  const modifiedUpdateProject = insertIntoGraphQlString(
    insertIntoGraphQlString(updateProject, {
      path: ["videoParts"],
      key: "modsArr",
      value: {
        id: true,
        dataStr: true,
        origin: true,
        name: true,
      },
    }),
    {
      path: ["videoParts"],
      key: "fonts",
      value: {
        family: true,
        style: true,
        url: true,
        weight: true,
      },
    }
  ).replace("__typename", "");

  return (await client.graphql({
    query: modifiedUpdateProject,
    variables: { input },
  })) as {
    data: Project;
  };
}

export async function changeProjectExperimentOptimizationTechnique(projectId: string, experimentOptimizationTechnique: ExperimentOptimizationTechnique){
  const { data } = await client.graphql({
    query: updateProject,
    variables: {
      input: {
        id: projectId,
        experimentOptimizationTechnique
      },
    },
  });
  return data;
}

export async function getPublishedProject(id: string) {
  const modifiedGetProject = insertIntoGraphQlString(
    insertIntoGraphQlString(getProject, {
      path: ["videoParts"],
      key: "modsArr",
      value: {
        id: true,
        dataStr: true,
        origin: true,
        name: true,
      },
    }),
    {
      path: ["experiments"],
      key: "variants",
      value: {
        id: true,
        value: true,
        successFactors: {
          conversionSuccessData: true,
          engagementSuccessData: true,
          selectionCount: true,
          watchTimeSuccessData: true,
        },
      },
    }
  );
  const {
    data: { getProject: project },
  } = (await client.graphql({
    query: modifiedGetProject,
    variables: { id },
  })) as { data: { getProject: Project } };
  return project;
}

export async function getPublishedProjectBasicInfo(id: string) {
  const getProject = `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      title
      updatedAt
      createdAt
    }
  }
`;

  const {
    data: { getProject: project },
  } = (await client.graphql({
    query: getProject,
    variables: { id },
  })) as { data: { getProject: ProjectMinimalInfo } };

  return project;
}

export async function getLatestProjectVersion(id: string) {
  // TODO add logic for fetching latest version when we have multiple versions per project id
  const modifiedGetProjectVersion = insertIntoGraphQlString(
    insertIntoGraphQlString(getProjectVersion, {
      path: ["videoParts"],
      key: "modsArr",
      value: {
        id: true,
        dataStr: true,
        origin: true,
        name: true,
      },
    }),
    {
      path: ["experiments"],
      key: "variants",
      value: {
        id: true,
        value: true,
        successFactors: {
          conversionSuccessData: true,
          engagementSuccessData: true,
          selectionCount: true,
          watchTimeSuccessData: true,
        },
      },
    }
  );
  const {
    data: { getProjectVersion: projectVersion },
  } = (await client.graphql({
    query: modifiedGetProjectVersion,
    variables: { id },
  })) as { data: { getProjectVersion: ProjectVersion} };
  return projectVersion;
}
export async function hasFormData(projectId: string) {
  let nextToken = null;
  let itemsCount = 0;
  do {
    const { data } = (await client.graphql({
      query: formDataByDate,
      variables: { projectFormDataId: projectId, limit: 1, nextToken },
    })) as { data: FormDataByDateQuery };

    itemsCount = data.formDataByDate?.items?.length || 0;
    nextToken = data.formDataByDate?.nextToken;
  } while (itemsCount === 0 && nextToken);

  return itemsCount > 0 || false;
}
export async function getProjectFormData(
  projectId: string,
  fromDate?: Date,
  toDate?: Date,
  nextToken?: string | null,
  pageSize = 25
) {
  const fromDateString = fromDate?.toISOString() || null;
  const toDateString = toDate?.toISOString() || null;
  const { data: formDataListResponse } = (await client.graphql({
    query: formDataByDate,
    variables: {
      projectFormDataId: projectId,
      createdAt: {
        between: [fromDateString, toDateString],
      },
      limit: pageSize,
      nextToken: nextToken || null,
      sortDirection: ModelSortDirection.DESC,
    },
  })) as { data: FormDataByDateQuery };

  const { items, nextToken: nextTokenResponse } =
    formDataListResponse.formDataByDate || {};

  return {
    items: items || [],
    nextToken: nextTokenResponse || null,
  };
}
function isProjectVersion(
  project: Project | ProjectVersion
): project is ProjectVersion {
  return (project as ProjectVersion).accountOwner !== undefined;
}
export const saveDynamicThumbnails = async (
  projectId: string,
  dynamicThumbnails: Array<Omit<DynamicThumbnail, "__typename"> | null>
) => {
  const _dynamicThumbs = dynamicThumbnails as DynamicThumbnailInput[];
  await client.graphql({
    query: updateProject,
    variables: {
      input: {
        id: projectId,
        dynamicThumbnails: _dynamicThumbs,
      },
    },
  });
};
export const listProjectBasicInfo = async () => {
  const listProjectBasicInfoQuery = `query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        updatedAt
        projectAccountId
        createdAt
        thumbS3Url
      }
      nextToken
    }
  }`;
  let nextToken = null;
  const projects: ProjectBasicInfo[] = [];
  do {
    const projectsData = (await client.graphql({
      query: listProjectBasicInfoQuery,
      variables: { nextToken },
    })) as { data: any };
    nextToken = projectsData?.data?.listProjects?.nextToken;
    if (projectsData?.data?.listProjects?.items) {
      projects.push(...projectsData.data.listProjects.items);
    }
  } while (nextToken);
  return projects;
};

export const listProjectsBasicInfoByAccount = async (
  accountId: string,
  addProjects?: any
) => {
  const listProjectBasicInfoQuery = `query ListProjectsByAccount(
    $accountId: ID!
    $limit: Int
    $nextToken: String
  ) {
    byAccount(input: {projectAccountId: $accountId, nextToken: $nextToken, limit: $limit}) {
      items {
        id
        title
        description
        updatedAt
        projectAccountId
        createdAt
        thumbS3Url
      }
      nextToken
    }
  }`;
  let nextToken = null;
  const projects: ProjectBasicInfo[] = [];
  try {
    do {
      const projectsData = (await client.graphql({
        query: listProjectBasicInfoQuery,
        variables: { nextToken, accountId, limit: 1000 },
      })) as { data: any };
      nextToken = projectsData?.data?.byAccount?.nextToken;
      if (projectsData?.data?.byAccount?.items) {
        projects.push(
          ...projectsData.data.byAccount.items.filter((p: any) => !!p.id)
        );
        if (addProjects)
          addProjects(
            projectsData.data.byAccount.items.filter((p: any) => !!p.id)
          );
      }
    } while (nextToken);
    return projects;
  } catch (e) {
    console.log({
      query: listProjectBasicInfoQuery,
      variables: { nextToken, accountId },
    });
    console.error(e);
  }
};
