import {
  InteractivesPerScene,
  EventsPerInteractive,
  PerEventsProps,
  PlayerEvent,
} from "../Analytics";
import { ComputedDatum, ResponsiveBar } from "@nivo/bar";
import { theme } from "../../../theme";
import { TooltipFunnel } from "./Tooltips";
type Scene = {
  name: string;
  changes: number;
  interactive?: EventsPerInteractive[];
  sessionsClickedAnyInteractive?: number;
};
export type EventType = "player" | "scene" | "interactive";
const numberFormatter = new Intl.NumberFormat("en-US", {
  notation: "compact",
  compactDisplay: "short",
});
export const Funnel = ({
  data,
}: {
  data: {
    events?: PerEventsProps;
    interactives?: Array<InteractivesPerScene>;
  };
}) => {
  if (!data.events?.length) return null;
  const events = data.events.reduce(
    (previousValue, currentValue) => {
      previousValue[currentValue.key] = currentValue;
      return previousValue;
    },
    {} as {
      [k: string]: PlayerEvent;
    }
  );
  const scenes =
    events["scene-change"]?.sceneChange.buckets.reduce((prev, curr) => {
      prev[curr.key] = { name: curr.key, changes: curr.uniqueSessions.value };
      return prev;
    }, {} as { [s: string]: Scene }) || {};
  if (data.interactives?.length) {
    data.interactives.forEach((scene) => {
      scenes[scene.key] = scenes[scene.key] || {
        name: scene.key,
        count: 0,
        changes: 0,
      };
      scenes[scene.key].interactive = scene.interactiveClicks.buckets;
      scenes[scene.key].sessionsClickedAnyInteractive =
        scene.sessionsClickedAnyInteractive.value;
    });
  }
  const totalSessions = events["ready"]?.doc_count;
  const totalFirstPlay = events["first-play"]?.doc_count;
  //const totalPlays = events["first-play"]?.doc_count;
  const funnelData: Array<{
    key: string;
    events: number;
    type: EventType;
    percentage: number;
    ctaDetail?: string;
  }> = [
    /*     {
      key: "Player Loaded",
      events: events["ready"]?.doc_count,
      type: "player",
      percentage: parseFloat(
        ((events["ready"]?.doc_count * 100) / totalSessions).toFixed(2)
      ),
    }, */
    {
      key: "First Play",
      events: events["first-play"]?.doc_count || 0,
      type: "player",
      percentage: parseFloat(
        ((events["first-play"]?.doc_count * 100) / totalSessions).toFixed(1)
      ),
    },
  ];

  Object.values(scenes)
    .sort((a, b) => b.changes - a.changes)
    .forEach((scene) => {
      if (scene.name === "no-scene") return;
      const cappedValue = Math.min(scene.changes, totalFirstPlay);
      funnelData.push({
        key: scene.name,
        events: cappedValue || 0,
        type: "scene",
        percentage: parseFloat(
          ((cappedValue * 100) / totalSessions).toFixed(1)
        ),
      });
      if (scene.interactive) {
        const sessionsClickedAnyInteractive = Math.min(
          scene.sessionsClickedAnyInteractive || 0,
          totalSessions
        );
        const bar = {
          key: `${scene.name} interactives`,
          type: "interactive" as EventType,
          events: sessionsClickedAnyInteractive,
          percentage: parseFloat(
            ((sessionsClickedAnyInteractive * 100) / totalSessions).toFixed(1)
          ),
          ctaDetail: JSON.stringify(scene.interactive),
        };

        funnelData.push(bar);
      }
    });
  const colors: Record<EventType, string> = {
    player: theme.blings_teal_60,
    scene: theme.blings_teal,
    interactive: theme.primary,
  };

  return (
    <div className="analytics-view funnel" style={{ height: "100%" }}>
      <div className="view-title">
        <h2>Funnel</h2>
        <h2>
          Sessions{" "}
          <span>{events["ready"]?.doc_count.toLocaleString("en-US")}</span>
        </h2>
      </div>
      <div
        style={{ width: "100%", height: "350px" }}
        className="funnel-responsive-bar"
      >
        <ResponsiveBar
          data={funnelData}
          keys={["events"]}
          indexBy="key"
          layers={[
            "grid",
            "axes",
            "bars",
            "markers",
            "legends",
            "annotations",
            ({ bars }) => {
              return (
                <g>
                  {bars.map(({ width, height, x, data }) => {
                    const translateX = x + width / 2;
                    const translateY = -height - 40;
                    return (
                      <text
                        key={data.data.key}
                        style={{
                          position: "absolute",
                          transform: `translateX(${translateX}px) translateY(80%) translateY(${translateY}px)`,
                          fontSize: "10px",
                        }}
                        textAnchor="middle"
                        dominantBaseline="central"
                      >
                        {`${
                          data.data.percentage ? data.data.percentage + "%" : ""
                        }`}
                      </text>
                    );
                  })}
                </g>
              );
            },
          ]}
          colors={(
            d: ComputedDatum<{
              key: string;
              events: number;
              type: EventType;
              percentage: number;
              ctaDetail?: string;
            }>
          ) => colors[d.data.type]}
          theme={{
            background: "#F7F8FA",
            axis: { domain: { line: { stroke: "gray", strokeWidth: 1 } } },
            grid: { line: { stroke: theme.blings_teal_80, strokeWidth: 0.1 } },
          }}
          padding={0.5}
          axisLeft={{
            format: (l) => numberFormatter.format(l),
          }}
          axisBottom={{
            tickRotation: -25,
            format: (v) => {
              return v.length > 18 ? (
                <tspan>
                  {"..." + v.substr(-17)}
                  <title>{v}</title>
                </tspan>
              ) : (
                v
              );
            },
          }}
          margin={{
            left: 50,
            right: 30,
            top: 30,
            bottom: 70,
          }}
          tooltip={TooltipFunnel}
          valueFormat={() => ""}
          legends={[
            {
              dataFrom: "indexes",
              data: [
                {
                  id: "scenes",
                  label: "Scenes",
                  color: colors.scene,
                },
                {
                  id: "interactivity",
                  label: "Interactivity",
                  color: colors.interactive,
                },
              ],
              anchor: "top-right",
              direction: "column",
              itemHeight: 20,
              itemWidth: 80,
              translateY: 0,
            },
          ]}
        />
      </div>
    </div>
  );
};
