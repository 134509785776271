import "./BlingsAnchorButton.scss";

type Props = {
  href?: string;
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
  disabled?: boolean;
};
export const BlingsAnchorButton = (props: Props) => {
  const { href, children, className, onClick, disabled } = props;
  return (
    <a
      className={`BlingsAnchorButton ${className || ""} ${
        disabled ? "disabled" : ""
      }`}
      href={!disabled ? href : undefined}
      onClick={!disabled ? onClick : undefined}
      style={props.style}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};
