import { PlayerSettingsUI } from "../PlayerSettings/PlayerSettings";
import { UpdateProject } from "./UpdateProject";
import { UpdateProjectSchemas } from "./ProjectSchemas";
import { IProjectModel } from "../../stores/project/projectModel";
import ProjectDataSettings from "./ProjectDataSettings";
import { Tabs, Divider, Space } from "antd";
import {
  BlockOutlined,
  PlaySquareOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import "./ProjectSettings.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PATHS, PROJECT_PATHS, toPath } from "../../PATHS";
import { useEffect } from "react";

type Props = {
  project: IProjectModel;
};
export const ProjectSettings = ({ project }: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.endsWith(PROJECT_PATHS.settings)) {
      handleTabChange("projectSettings");
    }
  }, [location.pathname, navigate, params?.id]);

  const handleTabChange = (key: string) => {
    navigate(
      toPath(PATHS.project, params?.id || "", PROJECT_PATHS.settings, `${key}`)
    );
  };

  const items = [
    {
      label: (
        <span>
          <ProjectOutlined style={{ marginRight: "12px" }} />
          Project
        </span>
      ),
      key: "projectSettings",
      children: (
        <>
          <Divider />
          <Space direction="vertical" size="large">
            <UpdateProject project={project} />
            {project.allowDataConnect && <ProjectDataSettings />}
          </Space>
        </>
      ),
    },
    {
      label: (
        <span>
          <PlaySquareOutlined style={{ marginRight: "12px" }} />
          Player
        </span>
      ),
      key: "playerSettings",
      children: (
        <div>
          <Divider />
          <PlayerSettingsUI project={project} />
        </div>
      ),
    },
    {
      label: (
        <span>
          <BlockOutlined style={{ marginRight: "12px" }} />
          Schema
        </span>
      ),
      key: "dataSchema",
      children: (
        <>
          <Divider />
          <UpdateProjectSchemas project={project} />
        </>
      ),
    },
  ];

  return (
    <Space
      className="ProjectSettings project-tab-padding"
      direction="vertical"
      style={{ width: "100%" }}
    >
      <Tabs
        items={items}
        defaultActiveKey="projectSettings"
        activeKey={params.subSettings}
        onTabClick={handleTabChange}
      />
    </Space>
  );
};
