import React, { useState } from "react";
import { InputForm } from "../../components/InputForm";
import { Button, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { FormProps } from "antd/lib/form";
import { IBtnTexts } from "../../components/antd-extensions/blings-btn.component";
import { CopyIcon, OpenLinkIcon } from "../../assets/Icons";
import { toast } from "react-toastify";
import { BlingsInput } from "../../components/BlingsInput/BlingsInput";

interface ProjectFormProps<T> {
  onFinish: FormProps<T>["onFinish"];
  onChange?: FormProps<T>["onChange"];
  onDelete?: (id: string) => void;
  groups?: string[];
  isCreate: boolean;
  initialValues?: FormProps<T>["initialValues"];
  actionText: string;
  isAdmin?: boolean;
  btnTexts?: IBtnTexts;
  formRef?: React.MutableRefObject<HTMLFormElement | undefined>;
  btnInForm?: boolean;
}
export const ProjectCrudForm: <T>(
  p: ProjectFormProps<T>
) => React.ReactElement = ({
  actionText,
  initialValues,
  isCreate,
  groups,
  onFinish,
  onChange,
  onDelete,
  isAdmin,
  btnTexts,
  formRef,
  btnInForm,
}) => {
    const thumbnail = initialValues?.thumbS3Url;
    const uploadAssetToProject = initialValues?.uploadAssetsToProject;
    const initialValues1 = {
      ...initialValues,
      ...{ projectAccountId: initialValues?.projectAccountId },
      videoPartsStr: undefined,
    };
    if (initialValues?.videoPartNames) {
      initialValues1.videoPartsStr = initialValues?.videoPartNames.join(", ");
    }
    const [currentOpenField, setCurrentOpenField] = useState<number>(0);

    const items = [
      // isCreate
      //   ? { label: "ID", name: "id" }
      //   : { label: "ID", name: "id", value: initialValues?.id },
      { label: "Title", name: "title", required: true },
      { label: "Description", name: "description" /*required: true*/ },
      groups &&
      isAdmin && {
        label: "Account",
        name: "projectAccountId",
        required: true,
        options: groups,
      },
      isAdmin && {
        label: "Settings schema",
        name: "settingsJsonSchemaStr",
        textarea: true,
        explanation: "JSON SCHEMA for settings",
        isJson: true,
      },
      {
        label: "Thumbnail",
        name: "thumbS3Url",
        // textarea: true,
        explanation: "Set thumbnail that represents the project (url)",
        render: () => {
          return (
            <BlingsInput
              handleOnFocus={() => setCurrentOpenField(1)}
              value={thumbnail}
              // label="Image to show during loading"
              uploadAssetsToProject={uploadAssetToProject}
              onChange={(e) => {}}
              uploadMimeType="image/*"
            />
          )
        }
      },
      isAdmin && {
        label: "State Schema",
        name: "stateJsonSchemaStr",
        textarea: true,
        explanation: "JSON SCHEMA for dynamic data",
        isJson: true,
      },
      isAdmin && {
        label: "Allow Custom Data-Point Creation",
        name: "allowCreateDataPoint",
        boolean: true,
      },
      isAdmin && {
        label: "Allow upload csv files as DB",
        name: "allowDataConnect",
        boolean: true,
      },
      isAdmin && { label: "MPflyer URL", name: "jsonVidUrl" },
      isAdmin && {
        label: "Dynamic Changes",
        name: "jsonChanges",
        textarea: true,
        explanation: "experimental",
        isJson: true,
      },
      initialValues1?.videoPartsStr && {
        label: "Video Parts",
        name: "videoPartsStr",
        value: initialValues1?.videoPartsStr,
      },
      {
        label: "Landing Page",
        name: "customHtml",
        icons: [
          {
            jsx: () => (
              <div
                key="1"
                className={[
                  "Icon",
                  initialValues?.customHtml ? "enabled" : "disabled",
                ].join(" ")}
                {...(initialValues?.customHtml && {
                  onClick: () => window.open(initialValues?.customHtml, "_blank"),
                })}
              >
                <OpenLinkIcon />
              </div>
            ),
          },
          {
            jsx: () => (
              <div
                key="2"
                className={[
                  "Icon",
                  initialValues?.customHtml ? "enabled" : "disabled",
                ].join(" ")}
                {...(initialValues?.customHtml && {
                  onClick: () => {
                    navigator.clipboard.writeText(
                      initialValues?.customHtml || ""
                    );
                    toast(`Copied to clipboard`, {
                      position: "bottom-left",
                      autoClose: 2500,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  },
                })}
              >
                <CopyIcon />
              </div>
            ),
          },
        ],
      },
    ];
    return (
      <div style={{ marginRight: "2em" }}>
        <InputForm
          initialValues={initialValues1}
          items={items}
          action={actionText}
          onFinish={onFinish}
          formRef={formRef}
          btnTexts={btnTexts}
          onChange={onChange}
          btnInForm={btnInForm}
          extraBtnsRender={
            isCreate
              ? undefined
              : () => {
                return (
                  <Popconfirm
                    title="Are you sure to delete this project?"
                    onConfirm={() => {
                      onDelete?.(initialValues?.id);
                    }}
                    okText="Yes"
                  >
                    <Button
                      type="dashed"
                      shape={"circle"}
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                );
              }
          }
        />
      </div>
    );
  };
