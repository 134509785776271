import {
  filterLayersFromSceneHelper,
  getProcessedLayers,
} from "../../helpers/editView.helpers";
import { IProjectModel } from "../../stores/project/projectModel";
import "./EditView.scss";
import { observer } from "mobx-react-lite";
import LayersView from "./LayersView";
import {
  LayersAndModsPerScene,
  UpdateVideoParams,
  EditVideoTypes,
  EditVideoExposedToPlatformType,
  EditVideoInteractiveType,
  EditVideoTextType,
} from "../../types/EditVideoTypes";
import { useEffect, useState } from "react";
import staticText from "../../utils/staticText";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { useMst } from "../../stores/Root";
import React from "react";
import { Popover, Tabs } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { isExposedToPlatformMod, isInteractiveMod, isTextLayer } from "./utils";
type Props = {
  project: IProjectModel;
  selectedScene: string;
  handlePanelCollapse: (keys: string[] | string) => void;
  activePanels: string[];
  setAllLayersAndMods: React.Dispatch<
    React.SetStateAction<LayersAndModsPerScene[]>
  >;
  allLayersAndMods: LayersAndModsPerScene[];
};

const EditView: React.FC<Props> = observer((props) => {
  const {
    project,
    allLayersAndMods,
    selectedScene,
    handlePanelCollapse,
    activePanels,
    setAllLayersAndMods,
  } = props;
  const [activeTab, setActiveTab] = useState("1");
  const [hasInteractive, setHasInteractive] = useState(false);
  const [hasExposedToPlatform, setHasExposedToPlatform] = useState(false);
  const { searchView, currentSearch } = useMst((store) => {
    const { editVideoStore } = store;
    return {
      searchView: editVideoStore.searchView,
      currentSearch: editVideoStore.currentSearch,
      setSearchView: editVideoStore.setSearchView,
      setCurrentSearch: editVideoStore.setCurrentSearch,
    };
  });
  // Current input of the search box
  const currentScene = project.workspaceVideoParts?.find(
    (vp) => vp.name === selectedScene
  );

  const {
    allDynamicTextLayers: currentSceneDynamicTextLayers,
    allStaticTextLayers: currentSceneStaticTextLayers,
    allInteractiveMods: currentSceneInteractiveLayers,
    allExposedToPlatformConnectors: currentSceneExposedToPlatformConnectors,
  } = filterLayersFromSceneHelper(
    allLayersAndMods,
    currentScene?.name || "",
    currentSearch
  );

  // Saves the local state to db
  const save = async (sceneName: string, layerOrMod: EditVideoTypes) => {
    await project.saveMod(sceneName, layerOrMod);
    update();
    // after 3 second, reset panel save status on update
    setTimeout(() => {
      project.updateSaveModsStatus(AsyncOpState.Changed);
    }, 3000);
  };
  /**
   * This method will update the allLayers state to get the most up to date layers
   */
  const update = () => {
    let newState: LayersAndModsPerScene[] = [];
    project.workspaceVideoParts?.forEach((scene) => {
      const {
        allDynamicTextLayers,
        allStaticTextLayers,
        allInteractiveLayers,
        allExposedToPlatformConnectors,
      } = getProcessedLayers(scene, project);
      const currentSceneLayers: LayersAndModsPerScene = {
        scene: scene.name,
        allStaticTextLayers,
        allDynamicTextLayers,
        allInteractiveMods: allInteractiveLayers,
        allExposedToPlatformConnectors,
      };
      newState = [...newState, currentSceneLayers];
    });
    setAllLayersAndMods(newState);
  };

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, currentScene?.assetLayerPairs, currentScene]);

  useEffect(() => {
    if (searchView) {
    } else {
      if (!currentSceneInteractiveLayers?.length && activeTab === "2") {
        return setActiveTab("1");
      }
      if (
        !currentSceneExposedToPlatformConnectors?.length &&
        activeTab === "3"
      ) {
        return setActiveTab("1");
      }
    }
    if (activePanels[0]) {
      const parsedPanel = JSON.parse(activePanels[0]);
      if (parsedPanel?.type === "interactiveType") {
        setActiveTab("2");
      } else if (parsedPanel?.type === "exposedToPlatformType") {
        setActiveTab("3");
      } else {
        setActiveTab("1");
      }
    }
  }, [
    activePanels,
    currentSceneDynamicTextLayers,
    currentSceneInteractiveLayers?.length,
    currentSceneExposedToPlatformConnectors?.length,
    currentSceneStaticTextLayers,
  ]);

  useEffect(() => {
    //show single scene
    if (!searchView && currentScene?.name) {
      setHasInteractive(!!currentSceneInteractiveLayers?.length);
      setHasExposedToPlatform(
        !!currentSceneExposedToPlatformConnectors?.length
      );
    } else {
      setHasInteractive(
        project?.workspaceVideoParts?.some((vdp) => {
          const { allInteractiveMods: sceneInteractiveLayers } =
            filterLayersFromSceneHelper(
              allLayersAndMods,
              vdp.name || "",
              currentSearch
            );
          return sceneInteractiveLayers.length;
        })
      );
      setHasExposedToPlatform(
        project?.workspaceVideoParts?.some((vdp) => {
          const {
            allExposedToPlatformConnectors: sceneExposedToPlatformConnectors,
          } = filterLayersFromSceneHelper(
            allLayersAndMods,
            vdp.name || "",
            currentSearch
          );
          return sceneExposedToPlatformConnectors.length;
        })
      );
    }
  }, [
    allLayersAndMods,
    currentScene?.name,
    currentSceneInteractiveLayers?.length,
    currentSceneExposedToPlatformConnectors?.length,
    currentSearch,
    project?.workspaceVideoParts,
    searchView,
  ]);

  // Updates the state to have the latest changes
  const updateState = (params: UpdateVideoParams) => {
    project.updateRefreshModsStatus(AsyncOpState.Saving);
    const { input, layerOrMod } = params;
    if (isInteractiveMod(params.layerOrMod)) {
      if (
        "newCtaName" in input ||
        "newValue" in input ||
        "experimentId" in input
      ) {
        currentScene?.updateInteractiveMod(
          layerOrMod as EditVideoInteractiveType,
          input
        );
      }
    } else if (isExposedToPlatformMod(params.layerOrMod)) {
      if ("newValue" in input || "experimentId" in input) {
        currentScene?.updateExposedToPlatformMod(
          layerOrMod as EditVideoExposedToPlatformType,
          input
        );
      }
    } else if (isTextLayer(params.layerOrMod)) {
      currentScene?.updateTextMods(layerOrMod as EditVideoTextType, input);
    }
    // Get the latest textLayers
    update();
    project.updateRefreshModsStatus(AsyncOpState.Success);
  };
  const showSingleScene = !searchView && currentScene?.name;

  const content = [
    <div className="EditView">
      <div className={"EditViewHeader"}></div>
      {showSingleScene ? (
        !currentSceneDynamicTextLayers.length &&
        !currentSceneStaticTextLayers.length ? (
          <div>No text layers in this scene</div>
        ) : (
          <>
            {currentSceneStaticTextLayers.length ? (
              <LayersView
                sceneName={currentScene.name}
                activePanels={activePanels}
                editableTypes={currentSceneStaticTextLayers}
                editable={true}
                update={updateState}
                save={save}
                handlePanelCollapse={handlePanelCollapse}
              />
            ) : (
              ""
            )}
            {currentSceneDynamicTextLayers.length ? (
              <div className="DynamicText">
                <LayersView
                  editableTypes={currentSceneDynamicTextLayers}
                  editable={false}
                />
              </div>
            ) : (
              ""
            )}
          </>
        )
      ) : (
        <div>
          {project?.workspaceVideoParts?.map((vdp) => {
            const {
              allDynamicTextLayers: sceneDynamicTextLayers,
              allStaticTextLayers: sceneStaticTextLayers,
            } = filterLayersFromSceneHelper(
              allLayersAndMods,
              vdp?.name || "",
              currentSearch
            );
            return (
              <div key={vdp.name}>
                {sceneStaticTextLayers.length ||
                sceneDynamicTextLayers.length ? (
                  <div className="SceneName">
                    <span style={{ color: "#8B8D97" }}> Scene:</span> {vdp.name}
                  </div>
                ) : (
                  ""
                )}
                {sceneStaticTextLayers.length ? (
                  <LayersView
                    sceneName={vdp.name}
                    activePanels={activePanels}
                    editableTypes={sceneStaticTextLayers}
                    editable={true}
                    update={updateState}
                    save={save}
                    handlePanelCollapse={handlePanelCollapse}
                  />
                ) : (
                  ""
                )}
                {sceneDynamicTextLayers.length ? (
                  <div className="DynamicText">
                    <LayersView
                      editableTypes={sceneDynamicTextLayers}
                      editable={false}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>,
    <div className="EditView">
      <div className={"EditViewHeader"}></div>
      {showSingleScene ? (
        currentSceneInteractiveLayers?.length ? (
          <LayersView
            sceneName={currentScene.name}
            activePanels={activePanels}
            editableTypes={currentSceneInteractiveLayers}
            editable={true}
            update={updateState}
            save={save}
            handlePanelCollapse={handlePanelCollapse}
          />
        ) : (
          ""
        )
      ) : (
        <div>
          {project?.workspaceVideoParts?.map((vdp) => {
            const { allInteractiveMods: sceneInteractiveLayers } =
              filterLayersFromSceneHelper(
                allLayersAndMods,
                vdp.name || "",
                currentSearch
              );
            return (
              <div key={vdp.name}>
                {sceneInteractiveLayers.length ? (
                  <div className="SceneName">
                    <span style={{ color: "#8B8D97" }}> Scene:</span> {vdp.name}
                  </div>
                ) : (
                  ""
                )}
                {sceneInteractiveLayers?.length ? (
                  <>
                    <LayersView
                      sceneName={vdp.name}
                      activePanels={activePanels}
                      editableTypes={sceneInteractiveLayers}
                      editable={true}
                      update={updateState}
                      save={save}
                      handlePanelCollapse={handlePanelCollapse}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>,
    <div className="EditView">
      <div className={"EditViewHeader"}></div>
      {showSingleScene ? (
        currentSceneExposedToPlatformConnectors?.length ? (
          <LayersView
            sceneName={currentScene.name}
            activePanels={activePanels}
            editableTypes={currentSceneExposedToPlatformConnectors}
            editable={true}
            update={updateState}
            save={save}
            handlePanelCollapse={handlePanelCollapse}
          />
        ) : (
          ""
        )
      ) : (
        <div>
          {project?.workspaceVideoParts?.map((vdp) => {
            const {
              allExposedToPlatformConnectors: sceneExposedToPlatformConnectors,
            } = filterLayersFromSceneHelper(
              allLayersAndMods,
              vdp.name || "",
              currentSearch
            );
            return (
              <div key={vdp.name}>
                {sceneExposedToPlatformConnectors.length ? (
                  <div className="SceneName">
                    <span style={{ color: "#8B8D97" }}> Scene:</span> {vdp.name}
                  </div>
                ) : (
                  ""
                )}
                {sceneExposedToPlatformConnectors?.length ? (
                  <>
                    <LayersView
                      sceneName={vdp.name}
                      activePanels={activePanels}
                      editableTypes={sceneExposedToPlatformConnectors}
                      editable={true}
                      update={updateState}
                      save={save}
                      handlePanelCollapse={handlePanelCollapse}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>,
  ];
  const tabs = [
    {
      label: "Text",
      key: "1",
      children: content[0],
      disabled: false,
    },
    {
      label: !hasInteractive ? (
        <div>
          <Popover
            className="tooltip-1"
            placement="top"
            overlayInnerStyle={{ width: "300px" }}
            content={
              <div style={{ fontSize: "13px" }}>
                To activate 'Interactives', open interactive elements in the
                Studio
              </div>
            }
          >
            Interactives
          </Popover>
        </div>
      ) : (
        "Interactives"
      ),
      key: "2",
      children: content[1],
      disabled: !hasInteractive,
    },
    {
      label: (
        <div style={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
          Custom Edits
          <Popover
            className="tooltip-1"
            placement="top"
            overlayInnerStyle={{ width: "300px" }}
            content={
              <div style={{ fontSize: "13px" }}>
                {hasExposedToPlatform
                  ? "Manage and modify video elements that were exposed from the Studio"
                  : "To activate 'Custom Edits', open relevant values for editing in the Studio and expose them to the Platform"}
              </div>
            }
          >
            <div>
              <QuestionCircleOutlined
                style={{
                  color: "#A9B6C3",
                  cursor: "pointer",
                  marginRight: "0.1rem",
                }}
              />
            </div>
          </Popover>
        </div>
      ),
      key: "3",
      children: content[2],
      disabled: !hasExposedToPlatform,
    },
  ];
  return (
    <div className="LayersFromSceneView">
      <Tabs
        onChange={(a) => {
          handlePanelCollapse([]);
          setActiveTab(a);
        }}
        activeKey={activeTab}
        items={tabs}
        tabBarExtraContent={
          <div className="all-scenes">
            {!showSingleScene &&
              staticText.editView.SEARCH_RESULTS(currentSearch)}
          </div>
        }
        defaultActiveKey="1"
      />
    </div>
  );
});

export default EditView;
