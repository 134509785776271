import awsconfig from "./aws-exports";

export const config = {
  contactMail: "contact@blings.io",
  support: "support@blings.io",
};
export const getENV = () => {
  const env = awsconfig.aws_cloud_logic_custom[0].endpoint
    .split("/")
    .slice(-1)[0];
  if (env === "DEV" || env === "dev") {
    return "dev";
  }
  if (env === "staging") {
    return "staging";
  }
  return "master";
};

export const getRealENV = () => {
  const env = awsconfig.aws_cloud_logic_custom[0].endpoint
    .split("/")
    .slice(-1)[0];
  return env;
};

export const CDN = {
  dev: "https://dh2jw0564x0cd.cloudfront.net",
  staging: "https://d25az89v0z0rdg.cloudfront.net",
  master: "https://storage.blings.io",
};
