const staticText = {
  editView: {
    EDIT_SCENE: "Edit Scene",
    SEARCH_RESULTS: (searchInput: string) => {
      if (searchInput === "") return "";
      else return `Search results for ${searchInput}:`;
    },
  },
  header: {
    LIVE_VERSION: "Published Version",
    LIVE_VERSION_SHORT: "Published",
    VERSION: (published: boolean) =>
      `${published ? "Published" : "Unpublished"} Version.`,
    LAST_EDIT: "Last edit: ",
    BY_USER: (user: string) => `By ${user}`,
    PUBLISH: "Publish",
  },
  layersView: {
    DESCRIPTION: (editableText: boolean) =>
      `${editableText ? "Text Layers" : "Data Connected Text [Not Editable]"}`,
  },
  textLayer: {
    ORIGINAL: "Original",
    CHANGE_TEXT: "Change Text",
    CHANGE_FONT_SIZE: "Change Font Size",
    CANCEL: "Cancel",
    SAVE: "Save",
  },
  searchLayer: {
    PLACEHOLDER: "Search in all scenes",
    CONTENT_NOT_FOUND: "Not Found",
  },
};
export default staticText;
