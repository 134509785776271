import React, { useEffect, useMemo, useState } from "react";
import "./EnableOptimizationPanel.scss";
import { useMst } from "../../../../stores/Root";
import { StarsIcon } from "../../../../assets/Icons";
import { ExperimentOptimizationTechnique } from "../../../../API";
import BlingsRadio from "../../../BlingsRadio/BlingsRadio";
import { BlingsButton } from "../../../BlingsButton/BlingsButton";

type Props = {
  setShouldShowOptimizationSelectionPanel: (value: boolean) => void;
};

export default function EnableOptimizationPanel({
  setShouldShowOptimizationSelectionPanel,
}: Props) {
  // State declarations
  const {
    selectedExperimentOptmizationTechnique,
    setExperimentOptimizationTechnique,
    changeOptimizationTechnique,
  } = useMst((store) => {
    return {
      selectedExperimentOptmizationTechnique:
        store.experimentStore.selectedExperimentOptimizationTechnique,
      setExperimentOptimizationTechnique:
        store.experimentStore.setExperimentOptimizationTechnique,
      changeOptimizationTechnique:
        store.projectsStore.selectedProject?.changeExperimentOptimizationType,
    };
  });

  const [
    experimentOptimizationTechniqueIndex,
    _setExperimentOptimizationTechniqueIndex,
  ] = useState<number>(-1);

  // Function declarations
  function _changeOptimizationTechnique(index: number) {
    setExperimentOptimizationTechnique(optimizationOptions[index].value);
    changeOptimizationTechnique();
    setShouldShowOptimizationSelectionPanel(false);
  }

  // Extra logic
  const optimizationOptions = useMemo(
    () => [
      {
        title: "Conversion",
        description: "For Conversion, you need to have at least 1 CTA defined",
        value: ExperimentOptimizationTechnique.CONVERSION,
        key: ExperimentOptimizationTechnique.CONVERSION,
      },
      {
        title: "Watch time",
        description: "Still need to be written",
        value: ExperimentOptimizationTechnique.WATCH_TIME,
        key: ExperimentOptimizationTechnique.WATCH_TIME,
      },
      {
        title: "Engagement",
        description: "Still need to be written",
        value: ExperimentOptimizationTechnique.ENGAGEMENT,
        key: ExperimentOptimizationTechnique.ENGAGEMENT,
      },
      {
        title: "Blings.io Success Factor",
        description: "Still need to be written",
        value: ExperimentOptimizationTechnique.BLINGS_MAGIC,
        key: ExperimentOptimizationTechnique.BLINGS_MAGIC,
      },
      {
        title: "Disabled",
        description: "Still need to be written",
        value: ExperimentOptimizationTechnique.DISABLED,
        key: ExperimentOptimizationTechnique.DISABLED,
      },
    ],
    []
  );

  // Effect declarations
  useEffect(() => {
    _setExperimentOptimizationTechniqueIndex(
      selectedExperimentOptmizationTechnique
        ? optimizationOptions.findIndex(
            (option) => option.value === selectedExperimentOptmizationTechnique
          )
        : 4
    );
  }, [optimizationOptions, selectedExperimentOptmizationTechnique]);

  if (experimentOptimizationTechniqueIndex === -1) return <></>;

  return (
    <div className="ai-enable-optimization-panel-container">
      <div className="ai-enable-optimization-panel-component">
        <div className="ai-enable-optimization-panel-header">
          <StarsIcon width={36} height={36} />
          <h3>Optimize this video for:</h3>
        </div>
        <p className="ai-enable-optimization-panel-description">
          Ai optimization enables you to optimize your video Lorem ipsum dolor
          sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua.
        </p>
        <BlingsRadio
          options={optimizationOptions}
          dir="vertical"
          optionTitleStyle={{ fontSize: "12px", color: "#1E1E1E" }}
          descriptionStyle={{
            fontSize: "12px",
            color: "#A6A8B1",
          }}
          defaultOptionIndex={experimentOptimizationTechniqueIndex}
          onChange={(selectionIndex) => {
            _setExperimentOptimizationTechniqueIndex(selectionIndex);
          }}
        />
        <BlingsButton
          onClick={() =>
            _changeOptimizationTechnique(experimentOptimizationTechniqueIndex)
          }
        >
          Set AI Optimization
        </BlingsButton>
      </div>
    </div>
  );
}
