import { useState, useEffect, useCallback } from "react";
import { Variant, ExperimentOptimizationTechnique } from "../../../../API";
import { ExperimentCategories } from "../../../../types/enums/VariantCategories";
import HeatMeter from "../../../Others/Heatmeter";
import AssetExibition from "./AssetExibition";
import "./VariantCard.scss";

type Props = {
  category: ExperimentCategories;
  variant: Variant | undefined;
  selectedSuccessFactor: ExperimentOptimizationTechnique;
  totalSelectionCount: number;
};
type AssetType = "video" | "image" | "audio" | "color" | "";

export default function VariantCard({
  category,
  variant,
  selectedSuccessFactor,
  totalSelectionCount,
}: Props) {
  // State declarations
  const [type, setType] = useState<AssetType>("");
  const [exibitionName, setExibitionName] = useState<string>("");
  const [isUrl, setIsUrl] = useState<boolean>(false);
  const [isColor, setIsColor] = useState<boolean>(false);
  const [
    selectedOptimizationTechniqueNumber,
    setSelectedOptimizationTechniqueNumber,
  ] = useState<number>(0);
  const [optimizationEfficiency, setOptimizationEfficiency] =
    useState<number>(0);

  // Function declarations
  function isURL(str: string) {
    const regex = new RegExp(
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/g
    );
    if (regex.test(str)) return true;
    return false;
  }

  function isCOLOR(str: string) {
    if (!str) return false;
    if (str.startsWith("#")) return true;
    return false;
  }

  async function getType(value: string) {
    if (!value) return "";
    const result = await fetch("https://cors.blings.io/" + value, {
      method: "HEAD",
    });
    const contentType = result.headers.get("content-type");
    if (!contentType) return "";
    return contentType.split("/")[0];
  }

  const calculateOptimizationEfficiency = useCallback(() => {
    if (variant === undefined) return 0;
    if (variant.successFactors.selectionCount === 0) return 0;
    return (
      selectedOptimizationTechniqueNumber /
      variant.successFactors.selectionCount
    );
  }, [variant, selectedOptimizationTechniqueNumber]);

  // useEffect declarations
  useEffect(() => {
    if (variant === undefined) return;
    switch (selectedSuccessFactor) {
      case ExperimentOptimizationTechnique.CONVERSION:
        setSelectedOptimizationTechniqueNumber(
          variant.successFactors.conversionSuccessData
        );
        break;
      case ExperimentOptimizationTechnique.ENGAGEMENT:
        setSelectedOptimizationTechniqueNumber(
          variant.successFactors.engagementSuccessData
        );
        break;
      case ExperimentOptimizationTechnique.WATCH_TIME:
        setSelectedOptimizationTechniqueNumber(
          variant.successFactors.watchTimeSuccessData
        );
        break;
    }
  }, [selectedSuccessFactor, variant]);

  useEffect(() => {
    setIsUrl(isURL(variant?.value || ""));
    setIsColor(isCOLOR(variant?.value || ""));
    setExibitionName(variant?.value || "");
  }, [variant]);

  useEffect(() => {
    if (variant) {
      if (isUrl) {
        setExibitionName(
          variant.value.split("/")[variant.value.split("/").length - 1]
        );
        getType(variant.value).then((type) => {
          setType(type as AssetType);
        });
      } else if (isColor) {
        setType("color");
      }
      // Fallback to image
      else if (category === ExperimentCategories.ASSET) setType("image");
    }
  }, [category, isColor, isUrl, variant]);

  useEffect(() => {
    setOptimizationEfficiency(calculateOptimizationEfficiency());
  }, [
    variant,
    selectedOptimizationTechniqueNumber,
    calculateOptimizationEfficiency,
  ]);

  return exibitionName ? (
    <div className="variant-card-container">
      <div className="variant-card-asset-container">
        <AssetExibition
          value={variant?.value || ""}
          type={type}
          className="variant-card-asset-exibition"
        />
      </div>
      <p className="variant-card-exibition-name"> {exibitionName}</p>
      <HeatMeter
        style={{ height: "8px", width: "30%" }}
        value={optimizationEfficiency}
      />
      <p className="variant-card-selection-count">
        Used {variant?.successFactors.selectionCount} times out of{" "}
        {totalSelectionCount}
      </p>
    </div>
  ) : null;
}
