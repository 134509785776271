import { SessionsPerDayProps } from "../Analytics";
import { theme } from "../../../theme";
import { TooltipBars } from "./Tooltips";
import { ResponsiveBar } from "@nivo/bar";
const numberFormatter = new Intl.NumberFormat("en-US", {
  notation: "compact",
  compactDisplay: "short",
});
export const SessionsPerDay = ({ data }: { data?: SessionsPerDayProps }) => {
  if (!data) return null;
  const lineData = data.map((d) => ({
    x: d.key_as_string,
    y: d.doc_count,
  }));
  const totalViews = data.reduce((prev, curr) => prev + curr.doc_count, 0);
  return (
    <div className="analytics-view sessions" style={{ height: "100%" }}>
      <div className="view-title">
        <h2>Views</h2>
        <h2>
          Total <span>{totalViews.toLocaleString("en-US")}</span>
        </h2>
      </div>
      <div style={{ width: "100%", height: "350px" }}>
        <ResponsiveBar
          data={lineData}
          keys={["y"]}
          indexBy={"x"}
          colors={["#FF154D69"]}
          labelSkipHeight={1}
          axisBottom={{
            legend: "",
            legendOffset: 15,
            tickValues: Array.from(
              new Set(
                Array.from(
                  { length: 10 },
                  (_, i) =>
                    lineData[Math.floor((i * lineData.length) / 10)]?.x || 0
                )
              )
            ),
            tickRotation: -25,
            format: (e) => {
              const date = new Date(e);
              return `${date.getFullYear()}-${
                date.getMonth() + 1
              }-${date.getDate()}`;
            },
          }}
          axisLeft={{
            legend: "",
            legendOffset: 12,
            format: (e) => Math.floor(e) === e && numberFormatter.format(e),
          }}
          margin={{
            left: 60,
            right: 30,
            top: 30,
            bottom: 70,
          }}
          motionConfig="gentle"
          fill={[{ match: "*", id: "gradientA" }]}
          theme={{
            background: "#F7F8FA",
            axis: { domain: { line: { stroke: "gray", strokeWidth: 1 } } },
            textColor: theme.blings_teal,
            grid: { line: { stroke: theme.blings_teal_80, strokeWidth: 0.1 } },
          }}
          tooltip={TooltipBars}
          enableLabel={false}
        />
      </div>
    </div>
  );
};
