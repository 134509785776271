/**
 * Method that formats the date in readable form
 * if date is today, return the time
 * else return the date in the form dd-mm-yy at time
 * @param date
 */
export const dateFormatter = (date: Date | null): string => {
  if (!date) return "";
  const day: string | number = date.getDate();

  const month: string | number = date.toLocaleDateString("default", {
    month: "short",
  });

  const year: string | number = date.getFullYear();

  let minutes: number | string = date.getMinutes();
  if (minutes < 10) minutes = "0" + minutes;
  const hourAndMinute = `${date.getHours()}:${minutes}`;

  const today = new Date();
  const timeElapsed = today.getTime() - date.getTime();

  // If date is in the last 60 seconds
  if (timeElapsed / 1000 < 60) return "Seconds ago";
  // If date is in the last 60 minutes
  if (timeElapsed / 1000 / 60 < 60) {
    const minutes = Math.floor(timeElapsed / 1000 / 60);
    return `${minutes} ${pluralize("minute", minutes)} ago`;
  }
  // If date is in the last 24 hours
  if (timeElapsed / 1000 / 60 / 60 < 24) {
    const hours = Math.floor(timeElapsed / 1000 / 60 / 60);
    return `${hours} ${pluralize("hour", hours)} ago`;
  }
  // If date is in the last 7 days
  if (timeElapsed / 1000 / 60 / 60 / 24 < 7) {
    const days = Math.floor(timeElapsed / 1000 / 60 / 60 / 24);
    return `${days} ${pluralize("day", days)} ago`;
  }
  // If date is in the same year
  if (today.getFullYear() === year) return `${month} ${day}, ${hourAndMinute}`;
  // If date is in a different year
  else return `${month} ${day} ${year}, ${hourAndMinute}`;
};

// Method that returns the plural form (add s) of single if it occurs more than onces
export const pluralize = (single: string, occurrences = 0): string => {
  if (occurrences > 1) return `${single}s`;
  else return single;
};
