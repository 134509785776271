import { Form, Input, Select, Checkbox, Space, Button } from "antd";
import React from "react";
import { FormItemProps, FormProps } from "antd/es/form";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Rule } from "rc-field-form/lib/interface";
import { IsJsonString } from "../helpers/json.helpers";
import {
  BlingsBtn,
  BlingsBtnProps,
} from "./antd-extensions/blings-btn.component";

interface Item extends Partial<FormItemProps> {
  message?:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  label: string;
  explanation?: string;
  required?: boolean;
  icons?: {
    jsx: () => JSX.Element;
  }[];
  value?: any;

  /***
   * Item rendering: default - <Input/>
   */
  render?: () => React.ReactNode; // custom render

  isJson?: boolean;
  textarea?: boolean; // render as textarea
  boolean?: boolean; // render as checkbox
  options?: (string | { value: string; text?: string })[]; // render as select-options
}

interface Props extends FormProps {
  items: Array<Item | undefined | false>;
  action: string;
  onFinish: FormProps["onFinish"];
  extraBtnsRender?: () => React.ReactNode;
  formRef?: React.MutableRefObject<HTMLFormElement | undefined>;
  opState?: BlingsBtnProps["opState"];
  btnTexts?: BlingsBtnProps["btnTexts"];
  btnInForm?: boolean;
}

function inputRender(item: Item) {
  if (item.render) {
    return item.render();
  }
  if (item.options) {
    return (
      <Select
      // placeholder="Select a option and change input text above"
      // onChange={onGenderChange}
      // allowClear
      >
        {item.options.map((option) => {
          const value = typeof option === "string" ? option : option.value;
          const text =
            typeof option === "string" ? value : option.text || option.value;

          return (
            <Select.Option key={value} value={value}>
              {text}
            </Select.Option>
          );
        })}
      </Select>
    );
  }
  if (item.boolean) {
    return <Checkbox value={item.value} />;
  }
  if (item.textarea) {
    return <Input.TextArea value={item.value} />;
  }
  if (item.icons) {
    return (
      <div style={{ display: "flex", gap: "0.5rem" }}>
        <Form.Item name={item.name}>
          <Input value={item.value} title={item.value} />
        </Form.Item>
        {item.icons.map((icon) => icon.jsx())}
      </div>
    );
  } else {
    return (
      <Input
        value={item.value}
        title={item.value}
        disabled={typeof item.value !== "undefined"}
      />
    );
  }
}

export function InputForm({
  items,
  onFinish,
  action,
  extraBtnsRender,
  opState,
  btnTexts,
  onChange,
  formRef,
  btnInForm = true,
  ...formProps
}: Props) {
  return (
    // @ts-ignore
    <Form {...formProps} onFinish={onFinish} ref={formRef} onChange={onChange}>
      {items
        .filter((e) => e)
        .map((item) => {
          if (!item) {
            return null;
          }
          const { label, name = label, explanation } = item;
          const rules: Rule[] = [
            { required: item.required, message: item.message },
          ];
          if (item.isJson) {
            rules.push({
              validator(_, value) {
                if (!value || IsJsonString(value)) {
                  return Promise.resolve();
                }
                return Promise.reject("Must be a valid JSON!");
              },
            });
          }
          return (
            <Form.Item
              tooltip={
                explanation && {
                  title: explanation,
                  icon: <InfoCircleOutlined />,
                }
              }
              valuePropName={item.boolean ? "checked" : undefined}
              label={label}
              name={name}
              key={name.toString()}
              rules={rules}
            >
              {inputRender(item)}
            </Form.Item>
          );
        })}
      <Form.Item>
        <Space>
          {btnInForm ? (
            !opState ? (
              <Button type="primary" htmlType="submit">
                {action}
              </Button>
            ) : (
              <BlingsBtn
                opState={opState}
                htmlType={"submit"}
                btnTexts={btnTexts}
              />
            )
          ) : null}

          {extraBtnsRender && extraBtnsRender()}
        </Space>
      </Form.Item>
    </Form>
  );
}
