import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./index.css";
import App from "./view/App";
import * as serviceWorker from "./serviceWorker";
import { cssRootVars } from "./theme";

const themeStyle = document.createElement("style");
(window as any).themeS = themeStyle;
themeStyle.innerHTML = cssRootVars;
document.body.append(themeStyle);

Sentry.init({
  dsn: "https://87151d67b38d40ddb6a75b8dad703a4e@o1306614.ingest.sentry.io/4504038518292480",
  integrations: [new BrowserTracing() as any],
  environment: process.env.NODE_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
