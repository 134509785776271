import { ConfigV2 } from "../../view/IntegrationPage/SiteIntegration/DataIntegration/DynamicThumbnailForm/DynamicThumbnailForm";
import { DYNAMIC_THUMBNAIL_BASE_URL } from "../../consts";
import { rootStore } from "../Root";

const getAuthorizationHeaderValue = async () => {
  try {
    const authSession = rootStore.accountStore.AuthSession;
    const accessToken = authSession.tokens?.accessToken.toString() as string;
    return accessToken;
  } catch (error) {
    console.error("Error retrieving authorization header value:", error);
  }
};
export const getConfigAndImage = async (id: string) => {
  const accessToken = await getAuthorizationHeaderValue();
  if (!id || !accessToken) throw new Error("Invalid id or authorization");
  const response = await fetch(`${DYNAMIC_THUMBNAIL_BASE_URL}/data/${id}`, {
    method: "GET",
    headers: {
      Authorization: accessToken,
    },
  });
  if (!response.ok) throw new Error(await response.text());
  const data = await response.json();
  return data;
};

export const createThumbnail = async (data: {
  config: ConfigV2;
  file: File;
}) => {
  const formData = new FormData();
  formData.append("config", JSON.stringify(data.config));
  formData.append("bg", data.file);
  const accessToken = await getAuthorizationHeaderValue();
  try {
    if (!accessToken) throw new Error("Invalid id or authorization");
    const response = await fetch(`${DYNAMIC_THUMBNAIL_BASE_URL}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: accessToken,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // Handle errors
    console.error("Error fetching data:", error);
  }
};

export const updateThumbnail = async (
  id: string,
  data: {
    config: ConfigV2;
    file: File;
  }
) => {
  const accessToken = await getAuthorizationHeaderValue();
  const formData = new FormData();
  formData.append("config", JSON.stringify(data.config));
  formData.append("bg", data.file);
  try {
    if (!id || !accessToken) throw new Error("Invalid id or authorization");
    const response = await fetch(`${DYNAMIC_THUMBNAIL_BASE_URL}/data/${id}`, {
      method: "PUT",
      body: formData,
      headers: {
        Authorization: accessToken,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // Handle errors
    console.error("Error fetching data:", error);
  }
};

export const previewThumbnail = async (config: ConfigV2, file: File) => {
  try {
    const accessToken = await getAuthorizationHeaderValue();
    if (config.width === 0 || config.height === 0) {
      throw new Error("Width or Height is 0");
    }
    if (!accessToken) throw new Error("Not Authorized");
    let url = `${DYNAMIC_THUMBNAIL_BASE_URL}/preview`;
    config.previewParams.map((param: string) => {
      if (param !== "") url = url.concat(`/${param}`);
      return param;
    });
    const formData = new FormData();
    formData.append("config", JSON.stringify(config));
    formData.append("bg", file);

    const response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: accessToken,
      },
    });
    return response;
  } catch (e) {
    console.error("Error Previewing Thumbnail", e);
  }
};
export const postFont = async (file: File, account: string) => {
  try {
    const accessToken = await getAuthorizationHeaderValue();
    if (!accessToken) throw new Error("Not Authorized");
    const url = `${DYNAMIC_THUMBNAIL_BASE_URL}/font`;

    const formData = new FormData();
    formData.append("font", file);
    formData.append("account", account); // Adding the account to the form data

    const response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: accessToken,
      },
    });
    return response;
  } catch (e) {
    console.error("Error Previewing Thumbnail", e);
  }
};

export const updateOrCreateThumbnail = async (
  id: string | null,
  data: {
    config: ConfigV2;
    file: File;
  }
) => {
  if (id) return await updateThumbnail(id, data);
  return await createThumbnail(data);
};
export const getFontsFromServer = async () => {
  const res = await fetch(`${DYNAMIC_THUMBNAIL_BASE_URL}/fonts`, {
    method: "GET",
  });
  const resJson = await res.json();
  return resJson;
};
