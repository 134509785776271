import "../DynamicThumbnailForm.scss";
import { InputNumber, Space } from "antd";
import { ConfigV2 } from "../DynamicThumbnailForm";
import { ColorPicker } from "../../../../../../components/ColorPicker";
import { Switch } from "antd";
import { produce } from "immer";
import rgbHex from "rgb-hex";

interface PlayButtonProps {
  config: ConfigV2;
  setConfig: (config: ConfigV2) => void;
}
export const hexToRgba = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};
const ThumbnailPlayButton = ({ config, setConfig }: PlayButtonProps) => {
  const handleSwitchChange = () => {
    const nextState = produce(config, (draft) => {
      draft.playIcon.enabled = !config.playIcon?.enabled;
    });
    setConfig(nextState);
  };

  return (
    <div className="playbutton-settings">
      <Space>
        <label>
          <Switch
            onChange={handleSwitchChange}
            checked={config.playIcon.enabled}
          />
          Show
        </label>
        <div className="label-div">
          <label>Color</label>
          <ColorPicker
            disableAlpha={false}
            color={
              config.playIcon.opacity !== undefined
                ? hexToRgba(config.playIcon.color, config.playIcon.opacity)
                : config.playIcon.color
            } //ensure alpha reflected on slider
            onChange={(e: any) => {
              const newColor = "#" + rgbHex(e.rgb.r, e.rgb.g, e.rgb.b);
              const newOpacity = e.rgb.a;
              const nextState = produce(config, (draft) => {
                draft.playIcon.color = newColor;
                draft.playIcon.opacity = newOpacity;
              });
              setConfig(nextState);
            }}
          />
        </div>
        <div className="label-div">
          <label>Size</label>
          <InputNumber
            style={{ width: "85px" }}
            min={0}
            max={config.playIcon.maxSize ? config.playIcon.maxSize : 1000}
            value={config.playIcon.size}
            onChange={(a) => {
              const nextState = produce(config, (draft) => {
                draft.playIcon.size = a || 300;
              });
              setConfig(nextState);
            }}
          />
        </div>
      </Space>
    </div>
  );
};

export default ThumbnailPlayButton;
