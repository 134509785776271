import { observer } from "mobx-react-lite";
import { useMst } from "../../stores/Root";
import { INotification } from "../../stores/notification/notificationModel";
import { StoreStatus } from "../../types/enums/StoreState";
import { toJS } from "mobx";

export type NotificationListType = {
  notifications: INotification[];
  unreadNotifications: number;
  storeStatus: StoreStatus;
  readAllNotifications: () => void;

  /*
  setSelectedProject: (p: IProjectModel) => void;
  setSelectedProjectById: (id?: string) => void;
  projectsSubMenuIsOpen: boolean;
  */
};

export const notificationBellHOCWrapper = (Comp: any) =>
  observer(function Observed(props) {
    const state = useMst((store) => {
      return {
        notifications: toJS(store.notificationStore.notifications),
        unreadNotifications: toJS(
          store.notificationStore.unreadNotificationsNumber
        ),
        storeStatus: store.notificationStore.status,
        readAllNotifications: store.notificationStore.readAllNotifications,
        /*
        setSelectedProject: store.projectsStore.setSelectedProject,
        setSelectedProjectById: store.projectsStore.setSelectedProjectById,
        projectsSubMenuIsOpen: store.projectsStore.projectsSubMenuIsOpen,
        */
      };
    });

    return <Comp {...state} {...props} />;
  });
