import { UpdateProjectInput, UpdateProjectMutation } from "../../API";
import { generateClient } from "aws-amplify/api";
import { updateProject } from "../../graphql/mutations";
import { IProjectBaseModel } from "./projectModel";

const client = generateClient();

export const saveFileNameActions = (self: IProjectBaseModel) => ({
  updateFileName(fileName: string) {
    self.fileName = fileName;
  },

  async saveFileName(newFileName: string) {
    try {
      const input: UpdateProjectInput = {
        id: self.id,
        fileName: newFileName || "",
      };
      const resp = (await client.graphql({
        query: updateProject,
        variables: { input },
      })) as { data: UpdateProjectMutation };
      console.log({ resp });
      this.updateFileName(newFileName);
    } catch (e) {
      console.log("Failed to save fileName", newFileName);
    }
  },
});
