/**
 * This file will manage the player versions that need to be instantiated for different videos
 * It will download the latest version inside that major version, instantiate it and save it in the playerVersions object for future use if needed
 */

import { getENV } from "../config";
import { Player, BlingsPlayer } from "@blings/blings-player";
import { ISdkParams } from "@blings/blings-player/lib/src/SDK/sdk.api";

const LATEST_MAJOR_VERSION = -1;

type PlayerVersion = {
  /**
   * The major version of the player
   */
  majorVersion: number;
  /**
   * The specific version of the player. Only used when a specific version used in the playercreator config
   */
  specificVersion?: string;
  miniSDK?: MiniSDKData;
  player?: PlayerData;
  playerRef?: Player;
};

type MiniSDKData = {
  miniSDKDownloadedCode: string;
  instantiatedMiniSDKCreator: any;
};

type PlayerData = {
  playerDownloadedCode: string;
  instantiatedPlayerCreator: any;
};

type CreatePlayerConfig = {
  /**
   * Should be just the major version of the player. Ex: 5
   */
  playerMajorVersion?: number;

  /**
   * Should be the specific version of the player. Ex: 5-1-2
   */
  playerVersion?: string;

  /**
   * Should the playerManager create a new player or replace the current player
   */
  newPlayer?: boolean;

  /**
   * Should the playerManager create a new player from the package.json version or use the MSDK version
   */
  playerFromInstalledModule?: boolean;
};

declare global {
  interface Window {
    BlingsPlayer: any;
    define: any;
  }
}

export default class PlayerManager {
  private isCreatingPlayer = false;
  private static _singleton: PlayerManager;
  private playerVersions: PlayerVersion[] = [];
  private currentPlayerVersion: PlayerVersion | undefined;
  private miniSDKSource =
    getENV() === "dev"
      ? "https://assets.blings.io/scripts/msdkDev"
      : "https://assets.blings.io/scripts/msdk";
  // TODO: Get the correct dev link
  private playerSource =
    getENV() === "dev"
      ? "https://assets.blings.io/sdk/blingsSDK_v"
      : "https://assets.blings.io/sdk/blingsSDK_v";

  constructor() {
    if (PlayerManager._singleton) {
      return PlayerManager._singleton;
    }
    PlayerManager._singleton = this;
    if (window["BlingsPlayer"]) delete window["BlingsPlayer"];
  }

  public static get(): PlayerManager {
    if (!PlayerManager._singleton)
      PlayerManager._singleton = new PlayerManager();
    return PlayerManager._singleton;
  }

  /**
   * Get the player version that is being used currently by the player manager
   * @returns Returns the current player version that is being used by the player manager in the format "major-minor-patch"
   */
  public GetCurrentPlayerVersion(): string {
    if (this.currentPlayerVersion === undefined)
      return "WARN_NO_PLAYER_VERSION";
    // If the current version is set to the latest major version (-1) then don't add the version param
    //if (this.currentPlayerVersion.majorVersion === -1)
    //  return 'latest'
    // If the current version is set to a specific version then add the version param
    else if (
      this.currentPlayerVersion.specificVersion !== undefined &&
      this.currentPlayerVersion.specificVersion !== "latest"
    )
      return this.currentPlayerVersion.specificVersion;
    // Otherwise, just consider the version from the player reference
    else {
      console.log(
        "this.currentPlayerVersion.playerRef?.blingsVersion",
        this.currentPlayerVersion.playerRef?.blingsVersion
      );
      return (
        this.currentPlayerVersion.playerRef?.blingsVersion as string
      ).replaceAll(".", "-");
    }
  }

  /**
   * Get the major version of the player that is being used currently by the player manager
   * @returns Returns the current major version
   */
  public GetCurrentMajorVersion(): string {
    const version = this.GetCurrentPlayerVersion();
    if (version === "latest") return "latest";
    return version.split(/[.-]/)[0];
  }

  /**
   * Download the latest version of the miniSDK for a given major version
   * @param major The major version of the player that we want to download
   */
  private async downloadMiniSDK(major: number): Promise<string> {
    let url: string;
    if (major === LATEST_MAJOR_VERSION) url = this.miniSDKSource + ".js";
    else url = this.miniSDKSource + major + ".js";
    const response = await fetch(url);
    const code = await response.text();
    return code;
  }

  /**
   * Download a specific version of the player
   * @param version A specific version of the player that we want to download. The version should be in the format "major-minor-patch"
   * @returns Returns the code of the player
   */
  private async downloadPlayer(version: string): Promise<string> {
    const response = await fetch(this.playerSource + version + ".js");
    const code = await response.text();
    return code;
  }

  /**
   * Instantiate the minisdk and return the BlingsPlayer object that we can use to create a player
   * @param miniSDKCode The code of the minisdk that we want to instantiate
   * @returns Returns the BlingsPlayer object that we can use to create a player
   */
  private async instantiateMiniSDK(miniSDKCode: string): Promise<any> {
    try {
      // Hacky way to instantiate the minisdk when the page has the AMD define function defined
      const originalState = this.unsetAMD();
      new Function(miniSDKCode + "return BlingsPlayer;")();
      // Await until the BlingsPlayer window object is created and while the BlingsPlayer dont have the create function
      while (!window["BlingsPlayer"])
        await new Promise((resolve) => setTimeout(resolve, 10));
      while (!window["BlingsPlayer"].version)
        await new Promise((resolve) => setTimeout(resolve, 10));
      this.setAMD(originalState);
      return window["BlingsPlayer"];
    } catch (e) {
      console.log("ERROR ON MINISDK INSTANTIATION", e);
    }
  }

  /**
   * Function to unset the AMD define function. Needed to allow the player instantiated through the minisdk to work correctly because of Webpack load mode definitions
   * @returns Returns the original state of the AMD define function
   */
  private unsetAMD() {
    const originalState = window.define;
    window.define = undefined;
    return originalState;
  }

  /**
   * Function to set the AMD define function. Needed to allow the player instantiated through the minisdk to work correctly because of Webpack load mode definitions
   * @param originalState The original state of the AMD define function
   */
  private setAMD(originalState: any) {
    window.define = originalState;
  }

  private async instantiatePlayer(playerCode: string): Promise<any> {
    try {
      new Function(playerCode + "return BlingsPlayer;")();
      // Await until the BlingsPlayer window object is created and while the BlingsPlayer dont have the create function
      while (!window["BlingsPlayer"])
        await new Promise((resolve) => setTimeout(resolve, 10));
      while (!window["BlingsPlayer"].create)
        await new Promise((resolve) => setTimeout(resolve, 10));
      return window["BlingsPlayer"];
    } catch (e) {
      console.log("ERROR ON PLAYER INSTANTIATION", e);
    }
  }

  /**
   * Delete all the BlingsPlayer references from the page
   */
  private deleteBlingsPlayerReferences() {
    const cssRuleToRemove = [
      ".__Blings__player__container .__blings__ctrl-container.__blings__with-timeline, .__Blings__player__container .__blings__ctrl-container.__blings__stories-mode",
      ".__Blings__player__container",
      "__Blings__player",
      "BlingsPlayer",
      "__blings__ctrl-container",
      ".onLoadCover",
      ".__blings__animation-container",
      "__blings-fonts-style",
    ];
    const cssIdsToRemove = ["__blings__fullscreenspecificStyle"];

    // If the PlayerInstantiator has a player already instantiated, we will delete it
    if (
      window["BlingsPlayer"]?.players?.length &&
      window["BlingsPlayer"].players[0] !== undefined
    ) {
      window["BlingsPlayer"].players[0].destroy();
    }
    // Get the BlignsPlayer from the window object and delete it
    if (window["BlingsPlayer"]) delete window["BlingsPlayer"];

    // Delete the BlingsPlayer ids from the head
    for (let i = 0; i < cssIdsToRemove.length; i++) {
      const id = cssIdsToRemove[i];
      const element = document.getElementById(id);
      if (element) element.remove();
    }

    // We need to iterate backwards because when we delete a rule, the indexes of the other rules will change
    for (let j = 0; j < document.styleSheets.length; j++) {
      try {
        const cssRules = document.styleSheets[j].cssRules;
        for (let i = 0; i < cssRules.length; i++) {
          const cssRule = cssRules[i];
          if (cssRuleToRemove.includes(cssRule.cssText)) {
            document.styleSheets[0].deleteRule(i);
            i--;
          }
        }
      } catch (e) {
        //console.log("Error removing css rule", e);
        continue;
      }
    }
  }

  private Version3Workaround() {
    // On this element, there is a rule ".__Blings__player__container .__blings__ctrl-container" that has the opacity set to 0.00001
    // We need to disable this rule so that the timeline is shown correctly
    // To do this, we need to create a new style sheet and add the rule to it
    const newStyle = document.createElement("style");
    newStyle.innerHTML =
      ".__Blings__player__container .__blings__ctrl-container { opacity: 1 !important; }";
    newStyle.innerHTML += ".onLoadCover { z-index: 120 !important; }";
    newStyle.innerHTML +=
      ".__blings__animation-container { z-index: 100 !important; }";
    newStyle.innerHTML +=
      ".__blings__ctrl-container { z-index: 110 !important; }";

    document.head.appendChild(newStyle);
  }
  /**
   * Create a player for a given major version and return it
   * @param playerParams The parameters that will be used to create the player
   * @param config The configuration of the player that we want to create. It can be the major version of the player or the specific version of the player.
   * The specific version has a higher priority than the major version.
   * If the version is not specified, we will download the latest version of the player
   */
  public async createPlayer(
    playerParams: ISdkParams,
    config: CreatePlayerConfig = {
      playerMajorVersion: LATEST_MAJOR_VERSION,
      playerVersion: undefined,
      newPlayer: false,
      playerFromInstalledModule: false,
    }
  ): Promise<Player> {
    this.isCreatingPlayer = true;

    // First we will delete the current player creator from the window object
    if (!config.newPlayer) this.deleteBlingsPlayerReferences();

    // Only allow the creation of the module player if we're in a local development environment by checking the REACT_APP_LOCAL_DEV environment variable
    // AND if we explicitly set the playerFromInstalledModule to true
    // This will help us to test the player module in a local environment
    // And be able to not use the player module in a production environment if we forgot to remove the playerFromInstalledModule flag
    if (config.playerFromInstalledModule && process.env.REACT_APP_LOCAL_DEV) {
      this.isCreatingPlayer = false;
      return BlingsPlayer.create(playerParams);
    }

    // Check if the player version is already downloaded
    // If the specific player version is not specified, search for the major version. Otherwise, search for the specific version
    const playerVersionIndex =
      config.playerVersion === undefined
        ? this.playerVersions.findIndex(
            (downloadedVersions) =>
              downloadedVersions.majorVersion === config.playerMajorVersion
          )
        : this.playerVersions.findIndex(
            (downloadedVersions) =>
              downloadedVersions.specificVersion === config.playerVersion
          );

    // If the player version is already downloaded, we will instantiate it
    if (playerVersionIndex !== LATEST_MAJOR_VERSION) {
      //console.log("Player version already downloaded", this.playerVersions[playerVersionIndex]);
      // If the player version we want to instantiate is the same as the current player version, we utilize the already instantiated player creator
      if (config.playerVersion === undefined) {
        // If the version that we want to instantiate is the same one as the last one instantiated
        if (
          window["BlingsPlayer"] &&
          window["BlingsPlayer"].version.toString().split(".")[0] ===
            this.currentPlayerVersion?.majorVersion.toString()
        ) {
          if (this.currentPlayerVersion?.miniSDK?.instantiatedMiniSDKCreator) {
            const player =
              this.currentPlayerVersion?.miniSDK?.instantiatedMiniSDKCreator.create(
                playerParams
              );
            this.currentPlayerVersion.playerRef = player;
            this.isCreatingPlayer = false;
            return player;
          } else if (
            this.currentPlayerVersion?.player?.instantiatedPlayerCreator
          ) {
            const player =
              this.currentPlayerVersion?.player?.instantiatedPlayerCreator.create(
                playerParams
              );
            this.currentPlayerVersion.playerRef = player;
            this.isCreatingPlayer = false;
            return player;
          }
        }
      } else {
        // If the version that we want to instantiate is the same one as the last one instantiated
        if (
          window["BlingsPlayer"] &&
          window["BlingsPlayer"].version.toString() ===
            this.currentPlayerVersion?.specificVersion?.replace("-", ".")
        ) {
          if (this.currentPlayerVersion?.miniSDK?.instantiatedMiniSDKCreator) {
            const player =
              this.currentPlayerVersion?.miniSDK?.instantiatedMiniSDKCreator.create(
                playerParams
              );
            this.currentPlayerVersion.playerRef = player;
            this.isCreatingPlayer = false;
            return player;
          } else if (
            this.currentPlayerVersion?.player?.instantiatedPlayerCreator
          ) {
            const player =
              this.currentPlayerVersion?.player?.instantiatedPlayerCreator.create(
                playerParams
              );
            this.currentPlayerVersion.playerRef = player;
            this.isCreatingPlayer = false;
            return player;
          }
        }
      }

      // If the player version we want to instantiate is different from the current player version, we will instantiate a new player creator
      this.currentPlayerVersion = this.playerVersions[playerVersionIndex];

      // WORKAROUND FOR VERSION 3
      if (
        config.playerVersion === undefined
          ? config.playerMajorVersion === 3
          : config.playerVersion.split("-")[0] === "3"
      ) {
        this.Version3Workaround();
      }

      // The player version is already downloaded, so we will instantiate a player using the already instantiated player creator
      const playerCreator =
        config.playerVersion === undefined
          ? this.currentPlayerVersion.miniSDK?.instantiatedMiniSDKCreator
          : this.currentPlayerVersion.player?.instantiatedPlayerCreator;

      // Instantiate and return the player using the player creator
      const player = await playerCreator.create(playerParams);
      if (!config.newPlayer) this.currentPlayerVersion.playerRef = player;
      this.isCreatingPlayer = false;
      return player;
    } else {
      // The player version is not downloaded, so we will download it, save it's data to the playerVersions array and instantiate it
      const playerData: any = {};
      const newPlayerVersion: PlayerVersion = {
        majorVersion: 0,
      };
      // If the player version is not specified, we will download the latest version of the player
      // PREFERENCE TO THE MAJOR VERSION
      if (
        config.playerVersion === undefined &&
        config.playerMajorVersion !== undefined
      ) {
        playerData.miniSDKCode = await this.downloadMiniSDK(
          config.playerMajorVersion
        );
        playerData.miniSDKCreator = await this.instantiateMiniSDK(
          playerData.miniSDKCode
        );
        newPlayerVersion.majorVersion = config.playerMajorVersion;
        newPlayerVersion.specificVersion = `latest`;
        const downloadedMiniSDKData: MiniSDKData = {
          miniSDKDownloadedCode: playerData.miniSDKCode,
          instantiatedMiniSDKCreator: playerData.miniSDKCreator,
        };
        newPlayerVersion.miniSDK = downloadedMiniSDKData;
      }
      // If the player version is specified, we will download the specific version of the player
      else if (config.playerVersion !== undefined) {
        playerData.playerCode = await this.downloadPlayer(config.playerVersion);
        playerData.playerCreator = await this.instantiatePlayer(
          playerData.playerCode
        );
        newPlayerVersion.majorVersion = parseInt(
          config.playerVersion.split("-")[0]
        );
        newPlayerVersion.specificVersion = config.playerVersion;
        const downloadedPlayerData: PlayerData = {
          playerDownloadedCode: playerData.playerCode,
          instantiatedPlayerCreator: playerData.playerCreator,
        };
        newPlayerVersion.player = downloadedPlayerData;
      } // If the player version and the major player version is not specified, we will throw an error
      else {
        throw new Error("The player version is not specified");
      }
      // And then we instantiate the player using the player creator
      // if the msdk is used, we will use the msdk creator, otherwise we will use the player creator
      const player: Player = newPlayerVersion.miniSDK
        ?.instantiatedMiniSDKCreator
        ? await newPlayerVersion.miniSDK?.instantiatedMiniSDKCreator.create(
            playerParams
          )
        : await newPlayerVersion.player?.instantiatedPlayerCreator.create(
            playerParams
          );
      newPlayerVersion.playerRef = player;

      // If we set the version to latest, we get the version from the instantiated player
      if (newPlayerVersion.specificVersion === "latest" && player)
        newPlayerVersion.specificVersion = (
          player.blingsVersion as string
        ).replaceAll(".", "-");

      this.playerVersions.push(newPlayerVersion);

      // WORKAROUND FOR PLAYER VERSION 3
      // If we are creating a player version 3, we have to make a workaround because the timeline is not shown correctly
      if (
        config.playerVersion === undefined
          ? config.playerMajorVersion === 3
          : config.playerVersion.split("-")[0] === "3"
      ) {
        this.Version3Workaround();
      }

      // Set the current player version
      if (!config.newPlayer) this.currentPlayerVersion = newPlayerVersion;
      this.isCreatingPlayer = false;
      return player;
    }
  }

  public async removeCurrentPlayer() {
    // Wait for player creation to complete if it's in progress
    while (this.isCreatingPlayer) {
      await new Promise((resolve) => setTimeout(resolve, 130));
    }
    if ((window as any).p) (window as any).p?.destroy();
    (window as any).p = null;
  }
}
