import { useContext, useState, useRef, useEffect } from "react";
import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import { Space } from "antd";
import { MSTContext } from "../../stores/Root";
import { ProjectCrudForm } from "../AdminPage/ProjectCrudForm";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { SimpleCreateProjectInput } from "../../stores/project/projectsModel";
import "./ProjectCreatePage.scss";
import { BlingsBtn } from "../../components/antd-extensions/blings-btn.component";
import { PATHS } from "../../PATHS";
import { useNavigate } from "react-router-dom";

export const ProjectCreatePage = observer(() => {
  const history = useNavigate();
  const {
    accountStore: { cognitoGroupId },
    projectsStore: { createProjectNotAdmin, updateOrCreateProjectStatus },
  } = useContext(MSTContext);
  const [creating, setCreating] = useState(false);
  const [formData, setFormData] = useState<SimpleCreateProjectInput>();
  const submitLiveControl = useRef<() => void>(() => {
    return;
  });
  const submitData = useRef<() => void>(() => {
    return;
  });
  const formRef = useRef<HTMLFormElement>();
  const [saveState, setSaveState] = useState({
    form: false,
  });
  useEffect(() => {
    if (saveState.form && !creating) {
      setCreating(true);
      (async () => {
        const project = await createProjectNotAdmin(
          formData as SimpleCreateProjectInput
        );
        setCreating(false);
        if (project?.id) {
          history(`${PATHS.project}/${project.id}/${PATHS.settings}`);
        }
      })();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [saveState]);

  if (!cognitoGroupId) {
    return <span></span>;
  }
  const {
    liveControlStore: { getAssetsUploadStatus, uploadAssetToProject },
  } = useContext(MSTContext);
  return (
    <Space
      direction="vertical"
      style={{ width: "80%" }}
      className="project-tab-padding Project-Create-Page"
    >
      <ProjectCrudForm<SimpleCreateProjectInput>
        actionText="Create"
        isCreate={true}
        isAdmin={false}
        onFinish={(cpiSimple) => {
          setFormData(cpiSimple);
          setSaveState({ ...saveState, form: true });
          submitLiveControl.current();
          submitData.current();
        }}
        formRef={formRef}
        btnInForm={false}
      />
      <Divider />
      <Space>
        <BlingsBtn
          opState={updateOrCreateProjectStatus}
          htmlType={"submit"}
          btnTexts={{ [AsyncOpState.Changed]: "Create" }}
          onClick={() => {
            formRef.current?.submit();
          }}
        />
      </Space>
    </Space>
  );
});
