import { AutoComplete, Input } from "antd";
import { useEffect, useState } from "react";
import { SearchIcon, TextIcon } from "../../assets/Icons";
import { PATHS, PROJECT_PATHS, toPath } from "../../PATHS";
import {
  EditVideoInteractiveType,
  LayersAndModsPerScene,
  EditVideoTextType,
  EditVideoTypes,
  EditVideoExposedToPlatformType,
} from "../../types/EditVideoTypes";
import staticText from "../../utils/staticText";
import { useNavigate } from "react-router-dom";
import "./SearchLayers.scss";
import { panelKey, renderIcon, renderTypeName } from "./utils";
type Props = {
  scene: string;
  projectId: string;
  layersAndMods: LayersAndModsPerScene[];
  handlePanelCollapse: (keys: string[] | string) => void;
  filterLayers: (searchString: string) => void;
  setCurrentInput: React.Dispatch<React.SetStateAction<string>>;
  currentInput: string;
};

const { searchLayer: Text } = staticText;
const SearchLayers = ({
  scene,
  layersAndMods,
  handlePanelCollapse,
  projectId,
  filterLayers,
  currentInput,
  setCurrentInput,
}: Props) => {
  const history = useNavigate();
  const [allScenesOptions, setAllScenesOptions] = useState<any>([]);
  const handleSelect = (e: any) => {
    history(toPath(PATHS.project, projectId, PROJECT_PATHS.editVideo, e[4]));
    e[0] === "interactiveType" || e[0] === "exposedToPlatformType"
      ? handlePanelCollapse(panelKey({ type: e[0], id: e[1], sceneName: e[4] }))
      : handlePanelCollapse(
          panelKey({
            type: e[0],
            layerName: e[1],
            asset: e[2],
            sceneName: e[4],
            layerUid: e[5],
          })
        );
  };

  const renderItem = (layer: EditVideoTypes) => {
    let [title, icon] = ["", <></>];
    if ("layerData" in layer) {
      title = layer.currentText == null ? layer.text : layer.currentText;
      icon = <TextIcon backgroundColor="#8B8D97" />;
    } else if ("connectorName" in layer) {
      title = layer.connectorName || `${layer.type as string} Connector`;
      icon = renderIcon(undefined, layer) || <></>;
    } else {
      title = layer.ctaName || layer.text || `${renderTypeName(layer)} Button `;
      icon = renderIcon(undefined, layer) || <></>;
    }
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            marginLeft: "0.1rem",
            marginRight: "0.5rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          {icon}
        </div>
        {title}
      </div>
    );
  };
  const processLayersFromScene = (
    layersFromScene: LayersAndModsPerScene | undefined
  ) => {
    if (layersFromScene) {
      const allStaticTextLayers =
        layersFromScene["allStaticTextLayers"].map(
          (staticLayer: EditVideoTextType) => ({
            value: [
              "textType",
              staticLayer.layerData.nm,
              staticLayer.asset,
              staticLayer.currentText == null
                ? staticLayer.text
                : staticLayer.currentText,
              layersFromScene.scene,
              staticLayer.layerData?.uid,
            ],
            label: renderItem(staticLayer),
          })
        ) || [];
      const allDynamicLayers =
        layersFromScene["allDynamicTextLayers"].map(
          (dynamicLayer: EditVideoTextType) => ({
            value: [
              "textType",
              dynamicLayer.layerData.nm,
              dynamicLayer.asset,
              dynamicLayer.text,
              layersFromScene.scene,
              dynamicLayer.layerData?.uid,
            ],
            label: renderItem(dynamicLayer),
            disabled: true,
          })
        ) || [];
      const allInteractiveLayers = layersFromScene["allInteractiveMods"].map(
        (layer: EditVideoInteractiveType) => ({
          value: [
            "interactiveType",
            layer.id,
            layer.ctaName,
            layer.value,
            layersFromScene.scene,
          ],
          label: renderItem(layer),
        })
      );
      const allExposedToPlatformConnectors = layersFromScene[
        "allExposedToPlatformConnectors"
      ].map((layer: EditVideoExposedToPlatformType) => ({
        value: [
          "exposedToPlatformType",
          layer.id,
          layer.connectorName,
          layer.value,
          layersFromScene.scene,
        ],
        label: renderItem(layer),
      }));
      return [
        ...allExposedToPlatformConnectors,
        ...allInteractiveLayers,
        ...allStaticTextLayers,
        ...allDynamicLayers,
      ];
    }
    //If we are looking through all scenes
    else {
      return [];
    }
  };

  useEffect(() => {
    const allScenesOptions = layersAndMods
      .map((layer: LayersAndModsPerScene) => ({
        label: layer.scene,
        options: [...processLayersFromScene(layer)],
      }))
      .filter((layer) => layer.options.length); // Remove scenes with no layers
    setAllScenesOptions(allScenesOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layersAndMods, scene]);
  return (
    <>
      <AutoComplete
        value={currentInput}
        onSelect={handleSelect}
        className={"SearchLayers"}
        notFoundContent={Text.CONTENT_NOT_FOUND}
        options={[...allScenesOptions]}
        filterOption={(inputValue, option) => {
          console.log(option);
          return (
            option?.label &&
            option?.value &&
            option?.value
              ?.filter((currentOption: any) => !!currentOption) // Remove undefined options
              ?.some(
                (possibleValue: string) =>
                  (possibleValue + "")
                    ?.toLowerCase()
                    .trim()
                    ?.indexOf(currentInput.toLowerCase().trim()) > -1
              )
          );
        }}
      >
        <Input
          className={"SearchInput"}
          allowClear
          onChange={(e) => setCurrentInput(e.target.value)}
          onPressEnter={(e: any) =>
            filterLayers(e.target.value?.toLowerCase().trim())
          }
          variant="borderless"
          placeholder={Text.PLACEHOLDER}
          prefix={
            <div style={{ display: "flex" }}>
              <SearchIcon />
            </div>
          }
        />
      </AutoComplete>
    </>
  );
};

export default SearchLayers;
