import { ThemeConfig } from "antd";

const BlingsPlatformTheme = {
  token: {
    colorPrimary: "#ff154d",
    colorInfo: "#ff154d",
    colorError: "#ff000f",
    colorSuccess: "#98d7c2",
    colorWarning: "#ffd046",
    colorLink: "#363ef1",
    colorTextBase: "#000000d9",
    colorText: "#000000a6",
    fontFamily:
      "Inter, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
    colorTextHeading: "#000000d9",
  },
  components: {
    Typography: {
      lineHeight: 1.5715,
    },
    Input: {
      // colorTextPlaceholder: "rgba(255, 255, 255, 0.5)",
      activeBg: "white",
      hoverBg: "white",
    },
    Menu: {
      itemMarginBlock: 6,
    },
  },
  cssVar: true,
} as ThemeConfig;

export default BlingsPlatformTheme;
