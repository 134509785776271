import { types, Instance, getRoot } from "mobx-state-tree";
import { IconfigModel } from "./config";
import { IRootModel } from "./Root";
import S3 from "aws-sdk/clients/s3";
import { rootStore } from "./Root";

export interface IDataUploadDS {
  id: string;
  title: string;
  "upload-time": number;
  project?: { id: string; title: string };
}

export const uploadDataModel = types
  .model("uploadDataStore", {
    isLoadingDataUploads: types.optional(types.boolean, false),
    dataUploads: types.optional(types.array(types.frozen<IDataUploadDS>()), []),
  })
  .actions((self) => ({
    async downloadFileFromS3(fileName: string) {
      const currentSession = rootStore.accountStore.AuthSession;
      const credentials = currentSession.credentials;
      const config: IconfigModel = getRoot<IRootModel>(self).configStore;
      const s3 = new S3({
        credentials,
        region: config.amplify.aws_user_files_s3_bucket_region,
        apiVersion: "2013-04-01",
        params: {},
      });

      const { Body } = await s3
        .getObject({
          Key: `protected/${fileName}`,
          Bucket: config.amplify.aws_user_files_s3_bucket,
        })
        .promise();

      return Body?.toString("utf-8");
    },
    async uploadFileToS3(name: string, file: Blob) {
      const currentSession = rootStore.accountStore.AuthSession;
      const credentials = currentSession.credentials;

      const config: IconfigModel = getRoot<IRootModel>(self).configStore;
      const s3 = new S3({
        credentials,
        region: config.amplify.aws_user_files_s3_bucket_region,
        apiVersion: "2013-04-01",
        params: {},
      });
      return s3
        .putObject({
          Key: name,
          Body: file,
          Bucket: config.amplify.aws_user_files_s3_bucket,
          ContentEncoding: "base64",
        })
        .promise();
    },
  }));

// export default uploadDataModel.create({})
export type IuploadDataModel = Instance<typeof uploadDataModel>;
