type Props = {
  currentTab: number;
  content: Array<React.ReactNode>;
  className?: string;
  style?: React.CSSProperties;
};
export const BlingsTabs = (props: Props) => {
  const { currentTab, content, className, style } = props;
  return (
    <div className={`BlingsTabs ${className || ""}`} style={style}>
      {content[currentTab]}
    </div>
  );
};
