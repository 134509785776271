import * as React from "react";
import { useEffect, useState } from "react";
import { DatePicker, Spin } from "antd";
import { get } from "aws-amplify/api";
import "chart.js/auto";
import {
  NEW_REST_API,
  NEW_REST_API__V2__ANALYTICS_SESSIONS_PATH,
} from "../../consts";
import { SessionsPerDay } from "./Analytics/SessionsPerDay";
import { SessionsPerCountry } from "./Analytics/SessionsPerCountry";
import { Funnel } from "./Analytics/Funnel";
import "./Analytics/Analytics.scss";
import { UsersPerDay } from "./Analytics/UsersPerDay";
import { TopCtas } from "./Analytics/TopCtas";
import { WatchTime } from "./Analytics/WatchTime";
import { SessionsPerDeviceType } from "./Analytics/SessionsPerDeviceType";
import { SessionsPerOs } from "./Analytics/SessionsPerOs";
import { theme } from "../../theme";
import { DynamicThumbnail } from "./Analytics/DynamicThumbnail";
import { LoadingTime } from "./Analytics/LoadingTime";
import { ScenePaths } from "./Analytics/ScenePaths";
import dayjs from "dayjs";
/* import { FunnelByTime } from "./Analytics/FunnelByTime"; */
export type SessionsPerDayProps = Array<{
  key_as_string: string;
  key: number;
  doc_count: number;
}>;
export type UsersPerDayProps = Array<{
  key_as_string: string;
  key: number;
  doc_count: number;
  perUserId: {
    value: number;
  };
}>;
export type SessionsPerCountryProps = Array<{ key: string; doc_count: number }>;
export type SessionsPerOsProps = Array<{ key: string; doc_count: number }>;
export type SessionsPerDeviceTypeProps = Array<{
  key: string;
  doc_count: number;
}>;
export type PerEventsProps = Array<PlayerEvent>;
export type PlayerEvent = {
  key: "ready" | "scene-change" | "first-play" | "cta-onclick";
  doc_count: number;
  sceneChange: {
    buckets: Array<{
      key: string;
      doc_count: number;
      uniqueSessions: { value: number };
    }>;
  };
};
export type InteractivesPerScene = {
  key: string;
  doc_count: number;
  interactiveClicks: { buckets: Array<EventsPerInteractive> };
  sessionsClickedAnyInteractive: { value: number };
};
export type WatchTimePerScene = {
  key: string;
  doc_count: number;
  perFrame: { buckets: Array<WatchTimePerFrame> };
};
export type WatchTimePerFrame = {
  key: string;
  doc_count: number;
  sum: { value: number };
};
export type EventsPerInteractive = {
  key: string;
  doc_count: number;
  uniqueSessionClicks: { value: number };
};
export type ScenePath = { key: string; doc_count: number };
type AnalyticsResponse = {
  playerEvents: {
    perEvent: { buckets: PerEventsProps };
    perDay: {
      buckets: Array<{
        key: string;
        doc_count: number;
        perEvent: { buckets: PerEventsProps };
      }>;
    };
  } | null;
  sessions: {
    sessionsPerDay: { buckets: SessionsPerDayProps };
    sessionsPerCountry: { buckets: SessionsPerCountryProps };
    sessionsPerOs: { buckets: SessionsPerOsProps };
    sessionsPerDevice: { buckets: SessionsPerDeviceTypeProps };
    usersPerDay: { buckets: UsersPerDayProps };
    medianLoadingTime: { values: { "50.0": number } };
    watchTimeTotal: { buckets: Array<{ key: number; doc_count: number }> };
    uniqueUsers: { value: number };
  } | null;
  watchTimes: { perScene: { buckets: Array<WatchTimePerScene> } } | null;
  scenePaths: { paths: { buckets: Array<ScenePath> } } | null;
  interactives: {
    interactivePerScene: { buckets: Array<InteractivesPerScene> };
  } | null;
  ctas: {
    sessionsClickedAnyCta: {
      value: number;
    };
    ctaClicks: {
      buckets: Array<{
        key: string;
        doc_count: number;
        uniqueSessionClicks: { value: number };
      }>;
    };
  };
  dynamicThumbnails: {
    count: { value: number };
    getDynamicThumbnailsPerDay: {
      buckets: Array<{ key_as_string: string; key: number; doc_count: number }>;
    };
  };
};
const DEFAULT_RANGE = 30 * 24 * 60 * 60 * 1000;
const colors = [
  theme.primary,
  "#ff5683",
  "#ff82b1",
  "#ffaad6",
  "#ffcff0",
  "#03c2dd",
  "#a3d0fd",
  "#ece3ff",
];
export function AnalyticsNew({ projectId }: { projectId: string }) {
  const [fromDate, setFromDate] = useState(Date.now() - DEFAULT_RANGE);
  const [showScenePath, setShowScenePath] = useState(false);
  const [toDate, setToDate] = useState(Date.now());
  const [data, setData] = useState<AnalyticsResponse | null>(null);
  useEffect(() => {
    (async () => {
      const data = await get({
        apiName: NEW_REST_API,
        path: NEW_REST_API__V2__ANALYTICS_SESSIONS_PATH(projectId),
        options: {
          queryParams: {
            projectId,
            from: fromDate.toString(),
            to: toDate.toString(),
          },
        },
      }).response;
      setData((await data.body.json()) as AnalyticsResponse);
    })();
  }, [fromDate, toDate]);
  return (
    <div className="analytics project-tab-padding">
      <div className="title">
        <h1 className="page-title">Analytics</h1>
        <DatePicker.RangePicker
          className="date-picker"
          defaultValue={[dayjs(fromDate), dayjs(toDate)]}
          onChange={(values) => {
            if (!values) return;
            const to = (values[1] && values[1].unix() * 1000) || Date.now();
            const from =
              (values[0] && values[0].unix() * 1000) || to - DEFAULT_RANGE;
            setToDate(to);
            setFromDate(from);
          }}
          showTime={{ format: "HH:mm" }}
          format={(value) => value.format("MMM DD, YYYY HH:mm")}
        />
      </div>

      {data ? (
        <div className="views-container">
          <Funnel
            data={{
              events: data.playerEvents?.perEvent.buckets,
              interactives: data.interactives?.interactivePerScene.buckets,
            }}
          />
          {/* <FunnelByTime data={data.playerEvents?.perDay.buckets} /> */}
          <TopCtas
            data={data.ctas}
            events={data.playerEvents?.perEvent.buckets}
          />
          {data?.dynamicThumbnails.count.value ? (
            <DynamicThumbnail
              count={data.dynamicThumbnails.count.value}
              dynamicThumbnailsPerDay={
                data.dynamicThumbnails.getDynamicThumbnailsPerDay?.buckets
              }
            />
          ) : (
            ""
          )}
          <SessionsPerDay data={data.sessions?.sessionsPerDay.buckets} />
          <UsersPerDay
            data={data.sessions?.usersPerDay.buckets}
            uniqueUsers={data.sessions?.uniqueUsers?.value}
          />
          <WatchTime
            watchTimesPerScene={data.watchTimes?.perScene.buckets}
            watchTimesTotal={data.sessions?.watchTimeTotal.buckets}
          />
          <LoadingTime
            medianTime={data.sessions?.medianLoadingTime.values["50.0"] || 0}
          />
          <div className="bottom-row">
            <SessionsPerCountry
              data={data.sessions?.sessionsPerCountry.buckets}
            />
            <div className="per-device">
              <SessionsPerDeviceType
                data={data.sessions?.sessionsPerDevice.buckets}
                colors={colors}
              />

              <SessionsPerOs
                data={data.sessions?.sessionsPerOs.buckets}
                colors={colors}
              />
            </div>
          </div>

          {showScenePath ? <ScenePaths data={data.scenePaths} /> : null}
          <div
            className="easterEgg"
            style={{
              display: "none",
              width: "10px",
              height: "10px",
              backgroundColor: "red",
            }}
            onClick={() => {
              setShowScenePath(true);
            }}
          ></div>
        </div>
      ) : (
        <Spin style={{ margin: "10px" }} />
      )}
    </div>
  );
}
